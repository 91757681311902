<div *ngIf="config" class="date-range w-100 main-black p-4" [ngClass]="{'white-box border-radius-bottom': aloneSection}">
	<div class="input-group p-0 me-3 date-picker">
		<div class="input-group-prepend">
			<span class="input-group-text">{{'g.start_date' | translate}}</span>
		</div>
		<app-sct-date-picker [bsConfig]="{containerClass: 'theme-dark-blue'}" 
		[isInvalidDate]="invalidDateRange" [date]="dateRange.start" [minDate]="config.minDate || minDate"
		[placeholder]="'mm/dd/yyyy'" (ngModelChange)="datesChanged({end: dateRange.end, start: $event})"></app-sct-date-picker>
		
	</div>
	<div class="input-group p-0 me-3 date-picker">
		<div class="input-group-prepend">
			<span class="input-group-text">{{'g.end_date' | translate}}</span>
		</div>
		<app-sct-date-picker [bsConfig]="{containerClass: 'theme-dark-blue'}" 
		[ngClass]="{'text-danger': invalidDateRange}" [date]="dateRange.end" [minDate]="config.minDate || minDate"
		[placeholder]="'mm/dd/yyyy'" (ngModelChange)="datesChanged({start: dateRange.start, end: $event})"></app-sct-date-picker>
	</div>
</div>