<div *ngIf="selectedDevices && selectedDevices.length; else noRes">
	<div class="w-100 d-flex justify-content-end">
		<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}"
			(click)="exportCsv()"></button>
	</div>
	<table class="table">
		<thead>
			<tr>
				<th *ngFor="let item of columnNames; let i = index">
					{{item.name | translate}}
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let device of selectedDevices">
				<tr>
					<td *ngFor="let item of columnNames">
						<ng-container *ngIf="item.type == 'link'; else elseExp">
							<i *ngIf="!device.is_deleted && item.expandable"
								class="fa fa-lg margin-right-sm main-blue mx-2"
								[ngClass]="device.expanded ? 'fa-minus-square' : 'fa-plus-square'" aria-hidden="true"
								(click)="device.expanded = !device.expanded"></i>
							<a [routerLink]=" device[item.linkId] || ['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'analytics', 'performance']">{{device[item.key]}}</a>
						</ng-container>
						<ng-template #elseExp>
							{{getItem(item, device)}}
						</ng-template>
					</td>
				</tr>
				<tr *ngIf="device.expanded" class="alert alert-info" role="alert">
					<td class="padding-left-30" colspan="2">
						<div class="col-lg-10">
							<app-tag-input
								[items]="siteTagsValues"
								[deviceId]="device.mac_address"
								[tagInput]="device._device_tags || []"
								[siteId]="device.site_id"
								[isSCTuser]="isSCT"
								(onUpdateTags)="onTagsUpdated($event, device)"
							></app-tag-input>
						</div>
						<div class="margin-top" *ngIf="!device.isdeleted">
							<app-note
								modelType="device"
								[modelId]="device.mac_address"
								[siteId]="device.site_id"
								[limit]="3"
							></app-note>
						</div>
					</td>
					<td></td>
					<td></td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>
<ng-template #noRes>
	{{'g.no_data_to_show' | translate}}
</ng-template>