import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class SctToastService {
	constructor(
		private translateService: TranslateService,
		public messageService: MessageService
	) { }

	showMessage(msg: string, type: 'success' | 'info' | 'warn' | 'error' = 'error', life: number = 10, header?: string) {
		if (msg == 'globalSuccessMsg') {
			msg = this.translateService.instant('g.global_success_msg');
			type = 'success';
		} else if (msg == 'globalErrMsg') {
			msg = this.translateService.instant('g.global_err');
		} else if (msg == 'globalAuthMsg') {
			msg = this.translateService.instant('g.global_auth_err');
		} else if (msg == 'globalTimeoutMsg') {
			msg = this.translateService.instant('g.request_timed_out');
		} else if (msg == 'globalInvalidCaptcha') {
			msg = this.translateService.instant('g.invalid_captcha');
		} else if (msg) {
			let splitted = msg.split('|');
			if (splitted[0] === "translate")
				msg = this.translateService.instant(splitted[1]);
		}

		this.messageService.add({ severity: type, summary: header || this.translateService.instant(`g.${type}`), detail: msg, life: life * 1000 })
	}
}
