import { Injectable } from '@angular/core';
import { GatewayService } from '@app/core/services/gateway/gateway.service';

@Injectable({
	providedIn: 'root'
})
export class TagsService {

	route = '/tags';

	shareOptions = {
		public: 'public',
		private: 'private',
		group: 'group'
	};

	constants: any = {
		maxTermLength: 30,
		minTermLength: 3,
		maxLimitMessageTimeout: 5
	};

	constructor(
		private httpClient: GatewayService
	) { }

	getSiteTags(siteId: number, options?: any) {
		return this.httpClient.post(this.route + '/getSiteTags', { siteId, options });
	}

	addTags(siteId: number, tagObj: any, options?: any) {
		return this.httpClient.post(this.route + '/addTags', { siteId, tagObj, options });
	}

	removeTagFromSite(siteId: number, tagObj: any, options?: any) {
		return this.httpClient.post(this.route + '/removeTagFromSite', { siteId, tagObj, options });
	}

	updateTagShareOption(siteId: number, tagObj: any, newShareOption: any, options?: any) {
		return this.httpClient.post(this.route + '/updateTagShareOption', { siteId, tagObj, newShareOption, options });
	}

	addDeviceTag(siteId: number, deviceIds: number[], tagObj: any, options?: any) {
		return this.httpClient.post(this.route + '/addDeviceTag', { siteId, deviceIds, tagObj, options });
	}

	removeDeviceTag(siteId: number, deviceId: string | string[], tagObj: any, options?: any) {
		return this.httpClient.post(this.route + '/removeDeviceTag', { siteId, deviceId, tagObj, options });
	}
}
