import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UsersService } from '@app/business/users/users.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

	constructor(
		private router: Router,
		private navigation: NavigationService,
		private usersService: UsersService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return !!this.authHandler(route, route.data.noAuth);
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return !!this.authHandler(childRoute, childRoute.data.noAuth);

	}

	private authHandler(route: any/*ActivatedRouteSnapshot*/, noAuth: boolean) {
		const { logged_in, password_expired, terms_agreement } = this.usersService.getUserAuthData();

		if (noAuth) {
			if (logged_in)
				this.router.navigate(['/']);
			return !logged_in;
		}

		if (!logged_in) {
			this.navigation.setPreviousUrl(route['_routerState'].url);
			this.router.navigate(['/login']);
		} else if (password_expired) {
			this.router.navigate(['/change-expired-password']);
		}
		else if (terms_agreement) {
			this.router.navigate(['/terms-and-conditions']);
		}
		return logged_in && !password_expired;
	}

}
