import { UsersService } from '@app/business/users/users.service';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption, SCTMenuItem } from './dropdown-model';
import { PermissionType } from '@app/core/model/constants';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
})
export class DropdwonComponent implements OnChanges {
	@Input() list!: SCTMenuItem[];
	@Input() option?: DropdownOption | null;
	@Input() permissions: any;
	@Input() cssClassIcon: string = '';

	@Input() index: number = 0;
	disabled = false;
	countItems = 0;
	hiddenItem = 0;
	@Input() id: any;

	constructor(
		private translate: TranslateService,
		private usersService: UsersService,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.option) {
			this.option = {
				icon: 'cog',
				hasTranslation: true
			};
		}

		this.manageItems(this.list);

		this.disabled = this.hiddenItem === this.countItems;
	}

	manageItems(items: SCTMenuItem[]) {
		items.forEach((item) => {
			if (this.id)
				item.id = this.id;
			this.countItems++;

			if (item.items)
				this.manageItems(item.items)

			if (this.option?.hasTranslation) {
				item.label = this.translate.instant(item.label || '');
			}

			//manage show/hide for item
			if (item.visible !== false && (!item.showIf && !item.permission) || (item.showIf && item.showIf(this.index) || item.permission && this.usersService.hasAccessPermission(this.permissions, item.permission, item.permissionAccessLevel || PermissionType.READ))) {
				item.visible = true;
			} else {
				item.visible = false;
			}
			if (item.visible === true && typeof (item.showIf) === 'function')
				item.visible = item.showIf(this.index);

			if (!item.visible)
				this.hiddenItem += 1;
		});
	}
}
