import { Component, Input, OnInit } from '@angular/core';
import { SCTDialog, SCTDialogAction } from './sct-dialog.model';

@Component({
	selector: 'app-sct-dialog',
	templateUrl: './sct-dialog.component.html',
	styleUrls: ['./sct-dialog.component.css'],
})
export class SctDialogComponent implements OnInit {
	@Input() dialog!: SCTDialog;
	@Input() okText: string = 'g.save';
	@Input() cancelText: string = 'g.cancel';
	@Input() disableConfirm: boolean = false;
	@Input() hasOkButton: boolean = true;
	@Input() hasCloseButton: boolean = true;
	@Input() okBtnColor: string = 'p-button-info';

	SCTDialogAction = SCTDialogAction;

	breakpoints = { '1250px': '50vw', '960px': '75vw', '640px': '100vw' };
	width = '300px';

	constructor() { }

	ngOnInit(): void {
	}

}
