import { InputType, SCTFormBuilderField } from 'sct-framework/form/projects/form/src/lib/form-builder.model';


export const formFields: SCTFormBuilderField[] = [
	{
		name: 'ade90xx_settings',
		fields: [
			{
				name: 'v_a_gain',
				permission: 'device.ade90xx_settings.v_a_gain',
				label: 'device.v_a_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'v_b_gain',
				permission: 'device.ade90xx_settings.v_b_gain',
				label: 'device.v_b_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'v_c_gain',
				permission: 'device.ade90xx_settings.v_c_gain',
				label: 'device.v_c_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'i_a_gain',
				permission: 'device.ade90xx_settings.i_a_gain',
				label: 'device.i_a_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'i_b_gain',
				permission: 'device.ade90xx_settings.i_b_gain',
				label: 'device.i_b_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'i_c_gain',
				permission: 'device.ade90xx_settings.i_c_gain',
				label: 'device.i_c_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'i_n_gain',
				permission: 'device.ade90xx_settings.i_n_gain',
				label: 'device.i_n_gain',
				type: InputType.NUMBER,
				disabled: true,
				defaultValue: 0
			},
			{
				name: 'watt_acc',
				permission: 'device.ade90xx_settings.watt_acc',
				label: 'device.watt_acc',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'var_acc',
				permission: 'device.ade90xx_settings.var_acc',
				label: 'device.var_acc',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'vconsel',
				permission: 'device.ade90xx_settings.vconsel',
				label: 'device.vConsel',
				type: InputType.NUMBER,
				isRequired: true
			},
			{
				name: 'iconsel',
				permission: 'device.ade90xx_settings.iconsel',
				label: 'device.iConsel',
				type: InputType.NUMBER,
				isRequired: true
			},
			{
				name: 'aigain',
				label: 'device.aigain',
				permission: 'device.ade90xx_settings.aigain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'bigain',
				label: 'device.bigain',
				permission: 'device.ade90xx_settings.bigain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cigain',
				label: 'device.cigain',
				permission: 'device.ade90xx_settings.cigain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'nigain',
				label: 'device.nigain',
				permission: 'device.ade90xx_settings.nigain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'avgain',
				label: 'device.avgain',
				permission: 'device.ade90xx_settings.avgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'bvgain',
				label: 'device.bvgain',
				permission: 'device.ade90xx_settings.bvgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cvgain',
				label: 'device.cvgain',
				permission: 'device.ade90xx_settings.cvgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'airmsos',
				label: 'device.airmsos',
				permission: 'device.ade90xx_settings.airmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'birmsos',
				label: 'device.birmsos',
				permission: 'device.ade90xx_settings.birmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cirmsos',
				label: 'device.cirmsos',
				permission: 'device.ade90xx_settings.cirmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'nirmsos',
				label: 'device.nirmsos',
				permission: 'device.ade90xx_settings.nirmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'avrmsos',
				label: 'device.avrmsos',
				permission: 'device.ade90xx_settings.avrmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'bvrmsos',
				label: 'device.bvrmsos',
				permission: 'device.ade90xx_settings.bvrmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cvrmsos',
				label: 'device.cvrmsos',
				permission: 'device.ade90xx_settings.cvrmsos',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'aphcal0',
				label: 'device.aphcal0',
				permission: 'device.ade90xx_settings.aphcal0',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'bphcal0',
				label: 'device.bphcal0',
				permission: 'device.ade90xx_settings.bphcal0',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cphcal0',
				label: 'device.cphcal0',
				permission: 'device.ade90xx_settings.cphcal0',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'apgain',
				label: 'device.apgain',
				permission: 'device.ade90xx_settings.apgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'bpgain',
				label: 'device.bpgain',
				permission: 'device.ade90xx_settings.bpgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'cpgain',
				label: 'device.cpgain',
				permission: 'device.ade90xx_settings.cpgain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'burden_res',
				label: 'device.burden_res',
				permission: 'device.ade90xx_settings.burden_res',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'r1',
				permission: 'device.ade90xx_settings.r1',
				label: 'device.r1',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'r2',
				permission: 'device.ade90xx_settings.r2',
				label: 'device.r2',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'ade_90xx',
				label: 'device.ade_90xx',
				permission: 'device.ade90xx_settings.ade_90xx',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'power_a_gain',
				label: 'device.power_a_gain',
				permission: 'device.ade90xx_settings.power_a_gain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'power_b_gain',
				label: 'device.power_b_gain',
				permission: 'device.ade90xx_settings.power_b_gain',
				type: InputType.NUMBER,
				disabled: true
			},
			{
				name: 'power_c_gain',
				label: 'device.power_c_gain',
				permission: 'device.ade90xx_settings.power_c_gain',
				type: InputType.NUMBER,
				disabled: true
			}
		],
		isFormGroup: true
	},
];

export const formFields1: SCTFormBuilderField[] = [
	{
		name: 'vconsel',
		permission: "device.vconsel",
		type: InputType.NUMBER,
		isRequired: true,
		id: 'vConsel',
		label: 'device.vConsel',
	},
	{
		name: 'iconsel',
		permission: "device.iconsel",
		type: InputType.NUMBER,
		isRequired: true,
		id: 'iConsel',
		label: 'device.iConsel',
	}
]