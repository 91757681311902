import { Injectable } from '@angular/core';
import { UsersService } from '@app/business/users/users.service';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AttachmentsService {

	route = 'attachments/';
	public allowedUploadFilesExt: string[] = [
		'.docx', '.doc',
		'.xls', '.xlsx',
		'.ppt', '.pptx',
		'.txt', '.csv',
		'.jpeg', '.png',
		'.pdf',
	];

	readonly hasDeleteAccess = this.usersService.hasAccessFunction('remove_attachments');
	readonly maxFilesCount: number = 10;
	readonly mbBytes: number = 1048576; // 1 MB in bytes
	readonly maxFileSize: number = 20 * this.mbBytes;
	readonly maxNoteCharacters: number = 200;

	public successCounterSubject = new BehaviorSubject<number>(0);
	successCounter = this.successCounterSubject.asObservable();

	constructor(
		private gateway: GatewayService,
		private usersService: UsersService,
	) { }

	validate(data: { files: any[], note: string }) {
		let selectedFilesValidations = {};

		const { files, note } = data;

		if (files.length > this.maxFilesCount)
			selectedFilesValidations = { ...selectedFilesValidations, max_count: true };

		if (note.length > this.maxNoteCharacters)
			selectedFilesValidations = { ...selectedFilesValidations, note: true };

		for (const file of files) {
			const fileExtension = (file.name.split('.').pop()).toLowerCase();

			if (!this.allowedUploadFilesExt.includes('.' + fileExtension))
				selectedFilesValidations = { ...selectedFilesValidations, extension: true };

			if (file.size > this.maxFileSize)
				selectedFilesValidations = { ...selectedFilesValidations, size: true };
		}

		return selectedFilesValidations;
	}

	addAttachments(formData: FormData) {
		return this.gateway.post(this.route + 'add-attachments', formData);
	}

	getAttachments(source: string, sourceId: any) {
		return this.gateway.get(this.route + 'get-attachments', { source, sourceId });
	}

	editAttachmentNote(source: string, sourceId: any, attachment: any) {
		return this.gateway.put(this.route + 'edit-attachment-note', { source, sourceId, attachment });
	}

	deleteAttachment(source: string, sourceId: any, attachmentId: any) {
		return this.gateway.delete(this.route + 'delete-attachment', { source, sourceId, attachmentId, });
	}

	downloadAttachment(source: string, sourceId: any, attachmentName: string | string[]) {
		return this.gateway.post(this.route + 'download-attachment', { source, sourceId, attachmentName })
			.toPromise().then((res: any) => {
				if (!res)
					return;
				const arr = new Uint8Array(res.content.data);
				const blob = new Blob([arr]);
				if (blob)
					saveAs(blob, res.name);
			}).catch((err: any) => console.error("download error = ", err))
	}
}
