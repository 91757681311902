import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/business/users/users.service';
import { CommonService } from '@app/shared/services/common.service';
import { SctToastService } from '@app/shared/services/sct-toast.service';

@Component({
	selector: 'app-become-user',
	template: '',
})
export class BecomeUserComponent implements OnInit, OnDestroy {

	token!: string;
	userSubscription: any;
	becomeUserSubscription: any;
	routeSubscription: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toast: SctToastService,
		private usersService: UsersService,
		private commonService: CommonService
	) {
		this.routeSubscription = this.route.params.subscribe(params => {
			this.token = params.token;
		});
	}

	ngOnInit() {
		this.becomeUserSubscription = this.usersService.becomeUser(this.token).subscribe(
			(res: any) => {
				if (res.err && res.err == 'logout_noaccess') {
					// go to login + show unauth
					this.toast.showMessage('globalAuthMsg');
					return this.router.navigate(['/login']);
				}
				let user = res.res;
				user.access_functions = this.commonService.revertPermissionValue(user.access_functions);
				user.permissions.permission = this.commonService.revertPermissionValue(user.permissions.permission);
				return this.usersService.setCurrentUser(res.res);
			}
		);

		this.userSubscription = this.usersService.currentUser.subscribe((user: any) => {
			if (user && user.id) {
				this.router.navigate(['/']);
			}
		});
	}

	ngOnDestroy() {
		this.userSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
		this.becomeUserSubscription.unsubscribe();
	}
}
