
<div class="row m-0 h-100">
	<div class="col-md-5 px-5">
		<div class="h-100">
			<div class="h-100 d-flex justify-content-center align-items-center">
				<div>
					<div class="p-3">
						<div class="mb-5 pb-5"><img src="/sctportal/images/logo-v1.png" alt="IOTEMETER" width="300px"></div>
					</div>
					<div class="ms-3">
						<h4>{{'login_register.log_in' | translate}}</h4>
					</div>
					<sct-form *ngIf="form" [submitButtonText]="'login_register.login' | translate" [data]="user"
							[name]="'login'" [formBuilder]="form" (invlidateFieldsCallback)="sctToastService.showMessage('translate|g.invalid_input')"></sct-form>
						<div class="ms-4">
							<a routerLink="/register" class="underline-on-hover">
							{{'login_register.register_new_account' | translate}}
						</a>
						</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-7 d-none d-md-block p-0">
		<div class="login-bg">
			<img src="/sctportal/images/login-bg-3.png">
		</div>
	</div>
</div>