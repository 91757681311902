import { SCTDialog, SCTDialogAction } from './../sct-dialog/sct-dialog.model';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { Component, OnInit, OnChanges, Input, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NoteService } from './note.service';
import moment from 'moment';
import { CommonService } from '../services/common.service';
import { extend, map } from 'lodash';
import { SCTFormBuilderField, SCTFormBuilder, InputType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';

@Component({
	selector: 'app-note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit, OnChanges {

	@Input() siteId: number = 0;
	@Input() modelType: string | null = null;
	@Input() modelId: any;
	@Input() title!: string;
	@Input() limit: number = 999;
	@Input() tooltipKey!: string;

	shareTypeList: any = [];
	reminderFreqList: any = [];
	notificationUsers: any = [];
	allNotificationUsers: any[] = [];
	modelNotes: any = [];
	modelNotesAllData: any = [];
	showMore: boolean = false;
	isSCT: boolean = false;
	thisNote: any;
	formFields: SCTFormBuilderField[] = [];
	form?: SCTFormBuilder;
	invalidFields = {};
	addNoteDialog: SCTDialog = {
		header: this.translateService.instant('g.notes'),
		position: 'center',
		dismissableMask: false,
		showCloseIcon: true,
		width: '50vw',
		hideFooter: true,
		callback: () => {
			this.form = undefined;
			this.addNoteDialog.visible = false;
		}
	};

	deleteNoteDialog: SCTDialog = {
		header: this.translateService.instant('note.delete_note'),
		position: 'center',
		dismissableMask: false,
		showCloseIcon: true,
		width: '50vw',
		callback: (action: SCTDialogAction) => {
			if (action === SCTDialogAction.SAVE) {
				this.deleteNote();
			}
			this.deleteNoteDialog.visible = false;
		}
	};

	editReminderFreqDialog: SCTDialog = {
		header: this.translateService.instant('note.edit_self_reminder_frequency'),
		position: 'center',
		dismissableMask: false,
		showCloseIcon: true,
		width: '50vw',
		callback: (action: SCTDialogAction) => {
			if (action === SCTDialogAction.SAVE) {
				this.editReminderFreq();
			}
			this.editReminderFreqDialog.visible = false;
		}
	};

	editShareTypeDialog: SCTDialog = {
		header: this.translateService.instant('note.edit_sharing_type'),
		position: 'center',
		dismissableMask: false,
		showCloseIcon: true,
		width: '50vw',
		callback: (action: SCTDialogAction) => {
			if (action === SCTDialogAction.SAVE) {
				this.editShareType();
			}
			this.editShareTypeDialog.visible = false;
		}
	};

	constructor(
		public noteService: NoteService,
		private translateService: TranslateService,
		public notificationMessageService: SctToastService,
		public commonService: CommonService,
	) { }

	ngOnInit() { }

	ngOnChanges() {
		if (this.modelType == 'site') {
			this.siteId = this.modelId;
		}
		this.getModelNotes();
	}

	prepareShareOptions() {
		const shareOptions: any = this.noteService.shareOptions,
			shareTypeList = [];

		if (!this.isSCT) {
			delete shareOptions.sct;
		}

		for (const option in shareOptions) {
			shareTypeList.push({
				id: shareOptions[option],
				label: this.translateService.instant('note.share_type_' + shareOptions[option])
			});
		}
		this.shareTypeList = shareTypeList;
	}

	prepareReminderOptions() {
		let reminderOptions = [];
		for (let option in this.noteService.reminderFrequencyOptions) {
			reminderOptions.push({
				id: this.noteService.reminderFrequencyOptions[<'none' | 'daily' | 'weekly'>option],
				label: this.translateService.instant('note.reminder_freq_' + this.noteService.reminderFrequencyOptions[<'none' | 'daily' | 'weekly'>option])
			});
		}
		this.reminderFreqList = reminderOptions;
	}

	getModelNotes() {
		if (!this.modelId || !this.modelType) {
			return;
		}

		this.noteService.getModelNotes(
			this.modelId,
			this.modelType,
			{
				getReceivableUsers: true,
				getIsSCT: true,
				siteId: this.siteId
			}
		).subscribe((data: any) => {
			this.modelNotes = data.model_notes;
			this.modelNotesAllData = data.model_notes;

			this.isSCT = data.isSCT;
			this.allNotificationUsers = data.receivable_users;

			this.allNotificationUsers.forEach(item => {
				item.fullNname = item.first_name + ' ' + item.last_name;
			});
			this.notificationUsers = this.allNotificationUsers;

			for (var i = 0; i < this.modelNotes.length; i++) {
				this.modelNotes[i].originalNote = this.modelNotes[i].note;

				let noteText = this.modelNotes[i].note;
				if (this.modelNotes[i].type == this.noteService.noteTypes.system) {
					noteText = JSON.parse(noteText);
					this.modelNotes[i].originalNote = this.translateService.instant('system_notes.' + noteText.action);
				}

				if (noteText.info?.last_cm_date_tested)
					noteText.info.last_cm_date_tested = moment(noteText.info.last_cm_date_tested).format("DD/MM/YYYY hh:mm:ss a");

				this.modelNotes[i].note = noteText;
				this.modelNotes[i].formattedDate = moment(this.modelNotes[i].date).format("LLLL");
			}

			if (this.modelNotes.length > this.limit) {
				this.splitNotes();
			}

			this.prepareShareOptions();
			this.prepareReminderOptions();
		});
	}

	splitNotes() {
		this.modelNotes = this.modelNotesAllData.slice(0, this.limit);
	}

	onShowMore(show: boolean) {
		if (show) {
			this.modelNotes = this.modelNotesAllData;
		} else {
			this.splitNotes();
		}
		this.showMore = show;
	}

	showActionModal(action: string, note?: any) {
		switch (action) {
			case 'add':
				this.notificationUsers = this.allNotificationUsers;
				this.thisNote = {
					newSiteNote: "",
					shareType: this.noteService.shareOptions.public,
					notificationUsers: [],
					reminderFreq: this.noteService.reminderFrequencyOptions.none
				};
				this.prepareFormFields();
				this.addNoteDialog.visible = true;
				break;
			case 'delete':
				this.thisNote = note;
				this.deleteNoteDialog.visible = true;
				break;
			case 'reminder':
				this.thisNote = {};
				this.thisNote = extend(this.thisNote, note);
				this.thisNote.oldReminderFreq = this.thisNote.reminder_freq;
				this.editReminderFreqDialog.visible = true;
				break;
			case 'share':
				this.thisNote = {};
				this.thisNote = extend(this.thisNote, note);
				this.thisNote.oldShareType = this.thisNote.shareType;
				this.editShareTypeDialog.visible = true;
				break;
		}
	}

	prepareFormFields() {
		this.formFields = [
			{
				name: 'note_text',
				label: 'note.note',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				name: 'shareType',
				label: 'note.share_with',
				type: InputType.DROPDOWN,
				dropDownOptions: this.shareTypeList,
				bindLabel: 'label',
				bindValue: 'id',
				callback: (event: any) => this.onChangeShareType(event)
			},
			{
				name: 'notification_user',
				label: 'note.notification_users',
				type: InputType.MULTI_SELECT,
				dropDownOptions: this.notificationUsers,
				allowHideField: true,
				bindLabel: 'fullNname',
				placeholder: this.translateService.instant('note.select_users')
			},
			{
				name: 'reminderFreq',
				label: 'note.self_reminder_frequency',
				type: InputType.DROPDOWN,
				dropDownOptions: this.reminderFreqList,
				bindLabel: 'label',
				bindValue: 'id',
				placeholder: this.translateService.instant('note.select_reminder_frequency')
			},
		];

		this.form = {
			fields: this.formFields,
			hideNoteForRequiredField: true,
			callback: (data: any) => {
				this.thisNote = data;
				this.addNote()
			},
		};
	}


	onChangeShareType(shareType: any) {
		if (this.form) {
			const field = this.form.fields.find((field) => field.name === 'notification_user');
			if (shareType === this.noteService.shareOptions.private) {
				field?.hideField && field.hideField(true)
			} else {
				field?.hideField && field.hideField(false)
			}
		}
		if (shareType != 'sct') {
			this.notificationUsers = extend([], this.allNotificationUsers);
			return;
		}

		const notificationUsers: any[] = [];
		let systemUserId = this.noteService.getSystemNoteUserInfo().id;
		this.allNotificationUsers.forEach(user => {
			if (user.group_type == 'sct' || user.id == systemUserId)
				notificationUsers.push(user);
		});
		this.notificationUsers = notificationUsers;
	}

	addNote() {
		if (!this.thisNote.note_text.trim()) {
			return;
		}
		if (this.thisNote.shareType == this.noteService.shareOptions.private) {
			this.thisNote.notification_user = [];
		}

		this.noteService.addModelNotes(this.modelId, this.modelType, {
			noteText: this.thisNote.note_text,
			siteId: this.siteId,
			shareType: this.thisNote.shareType,
			userNotificationList: map(this.thisNote.notification_user, 'id'),
			reminderFreq: this.thisNote.reminderFreq
		}).subscribe((res: any) => {
			if (res.api_status == 3) {
				this.invalidFields = { note_text: true }
				return this.notificationMessageService.showMessage('translate|g.invalid_input');
			}
			if (res.api_status == 4) {
				this.invalidFields = { notification_user: true }
				return this.notificationMessageService.showMessage('translate|g.invalid_input');
			}

			if (res.noteId) {
				this.notificationMessageService.showMessage('translate|note.success_note_add', 'success');
				this.getModelNotes();
				this.addNoteDialog.visible = false;
			}
		});
	}

	deleteNote() {
		this.noteService.deleteModelNotes(this.thisNote.noteId, this.modelId, this.modelType, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if (res.deleted) {
				this.notificationMessageService.showMessage('translate|note.success_note_delete', 'success');
				this.getModelNotes();
			}
		});
	}

	editReminderFreq() {
		// No change
		if (this.thisNote.reminder_freq == this.thisNote.oldReminderFreq) {
			return;
		}

		this.noteService.updateReminderFreq(this.thisNote.noteId, this.modelId, this.modelType, this.thisNote.reminder_freq, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if (res.updated) {
				this.getModelNotes();
				this.notificationMessageService.showMessage('translate|note.success_note_update', 'success');
			}
		});
	}

	editShareType() {
		// No change
		if (this.thisNote.shareType == this.thisNote.oldShareType) {
			return;
		}

		this.noteService.updateShareType(this.thisNote.noteId, this.modelId, this.modelType, this.thisNote.shareType, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if (res.updated) {
				this.getModelNotes();
				this.notificationMessageService.showMessage('translate|note.success_note_update', 'success');
			}
		});
	}

	getShareTypeIcon(type: string) {
		const icons: any = {
			private: "fa fa-user fa-lg",
			public: "fa fa-globe fa-lg",
			sct: "fa fa-users fa-lg"
		};
		return icons[type];
	}

	camelToSnakeCase(inputString: string) {
		// Convert the first letter to lowercase
		const convertedString = inputString.charAt(0).toLowerCase() + inputString.slice(1);

		// Replace uppercase letters with underscores and lowercase letters
		return convertedString.replace(/([A-Z])/g, '_$1').toLowerCase();
	}
}
