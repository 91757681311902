import { Injectable } from '@angular/core';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { CommonService } from '@app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { UsersService } from '../users/users.service';
import * as _ from 'lodash';
import { CustomHttpParams } from '@app/shared/loader/custom-httpParam';
import { ValidationService } from '@app/shared/services/validation.service';
import { SettingsService } from './analytics-settings/settings/service/settings.service';
import { deviceTypes, SYSTEM_USER_ID } from '@app/core/model/constants';

@Injectable({
	providedIn: 'root'
})
export class DevicesService {

	route: string = 'devices/';

	waitTimeout = 45;

	deviceWarranty = 3; //In years

	glitchEventTypeMapper: any = {
		'1': 'oia',
		'2': 'oib',
		'3': 'oic',
		'4': 'oin',
		'5': 'dipa',
		'6': 'dipb',
		'7': 'dipc',
		'8': 'swella',
		'9': 'swellb',
		'10': 'swellc',

		// LL
		'133': 'dipa', // 0x85
		'134': 'dipb', // 0x86
		'135': 'dipc', // 0x87
		'136': 'swella', // 0x88
		'137': 'swellb', // 0x89
		'138': 'swellc', // 0x8A
	};
	oneDayDisconnect: number = 24 * 60 * 60;
	threeDaysDisconnect: number = 3 * (24 * 60 * 60);
	sevenDayDisconnect: number = 7 * (24 * 60 * 60);
	thirtyDayDisconnected: number = 30 * (24 * 60 * 60);
	oneMinuteDisconnected: number = 60;

	wiringModeTypes: any = {
		'3_phase_4_wire': 0,
		'3_phase_3_wire': 1,
		'single_phase_3_wire': 2,
		'single_phase_2_wire': 3,
	};

	enableWiresOptions: any = {
		'phase_a': 0x01,
		'phase_b': 0x02,
		'phase_c': 0x04
	};

	enableWiresValues: any = [];
	specificWarningValue = 0x8000;

	constructor(
		private gatewayService: GatewayService,
		private translate: TranslateService,
		private commonUtil: CommonService,
		private usersService: UsersService,
		private validationService: ValidationService,
		private settingsService: SettingsService
	) { }

	flushDeviceCache(macAddress: string) {
		return this.gatewayService.post(this.route + 'flushDeviceCache', { macAddress });
	}

	updateRTSync(macAddress: string, rtSync: boolean, option: any) {
		return this.gatewayService.post(this.route + 'updateRTSync', { macAddress, rtSync, option });
	}

	officialReporting(macAddress: string, data: any) {
		return this.gatewayService.post(this.route + 'official-reporting', { macAddress, data });
	}

	updateDeviceDataOnDB(mac_address: string, data: any) {
		return this.gatewayService.post(this.route + 'updateDeviceDataOnDB', { mac_address, data });
	}

	setForceReadDebugDate(macAddress: string, force_read_debug_time: number, option: {}) {
		return this.gatewayService.post(this.route + 'setForceReadDebugDate', { macAddress, force_read_debug_time, option });
	}

	getDefectiveDevice(macAddress: string) {
		return this.gatewayService.get('defective/getDefectiveDevice', { macAddress: macAddress });
	}

	getSiteDevices(siteId: number, getDevicesLastConnectTime: boolean = false) {
		return this.gatewayService.get(`devices/getSiteDevicesListing/${siteId}?fresh=${getDevicesLastConnectTime ? 1 : 0}`);
	}

	getDisconnectedDevicesReport() {
		return this.gatewayService.get(`devices/getDisconnectedDevicesReport`);
	}

	getSimDevicesData() {
		return this.gatewayService.get(`devices/getSimDevicesData`);
	}

	getSimsInfo() {
		return this.gatewayService.get(`devices/getSimsInfo`);
	}

	getAllSiteDevices() {
		return this.gatewayService.get(`devices/allSitesDevices`);
	}

	getWarnings(from: number | null, to: number | null, siteId: number | null) {
		return this.gatewayService.get(`devices/getWarnings?from=${from}&to=${to}&siteId=${siteId}`);
	}

	acknowledgeWarnings(warning_id: string, mac_address: string) {
		return this.gatewayService.get(`devices/acknowledgeWarnings?warning_id=${warning_id}&mac_address=${mac_address}`);
	}

	addConnectivityClasses(nowTime: number, devices: any) {
		for (var item of devices) {
			let lastConnectionTime = this.commonUtil.nowTime() - item.last_connect_time; // device last connect time
			item.tooltipMessages = item.tooltipMessages || [];
			// tooltip message when hovering over image
			item.tooltipMessages.push(this.getLastConnectionTimeMassage(item));
			if (item.is_metered_connection) {
				// if a device cellular enabled  = false → hide the icon
				if (item.enable_cellular === false)
					continue;
				// if the device doesn’t have ICCID and the user doesn’t have NOC permission → hide the icon
				if (!item.cellular_iccid) {
					if (!this.usersService.userHasNOCAccess())
						continue;
					// if the user has NOC permission and the device doesn’t have ICCID show the lte_bad
					item.connectivityStatus = '/sctportal/images/lte/lte_BAD.png';
					continue;
				}

				if (lastConnectionTime >= this.thirtyDayDisconnected) { // 30 days
					item.connectivityStatus = '/sctportal/images/lte/lte_disconnected_30d.png';
					continue;
				}
				if (lastConnectionTime >= this.sevenDayDisconnect) { // 7 days
					item.connectivityStatus = '/sctportal/images/lte/lte_disconnected_7d.png';
					continue;
				}
				if (lastConnectionTime >= this.threeDaysDisconnect) { // 3 days
					item.connectivityStatus = '/sctportal/images/lte/lte_disconnected_3d.png';
					continue;
				}
				if (lastConnectionTime >= this.oneDayDisconnect) { // 1 day

					// device connected for one day and less than 3 days we need to check SIM CARD DB
					const simCardTime = nowTime - item.last_sim_connect_time;
					if (simCardTime < this.oneDayDisconnect) {
						item.connectivityStatus = '/sctportal/images/lte/lte_connected.png';
						continue;
					}
					item.connectivityStatus = '/sctportal/images/lte/lte_disconnected_3d.png';
					continue;
				}

				item.connectivityStatus = '/sctportal/images/lte/lte_connected.png';
				continue;
			} else {
				if (!item.last_connect_time) {
					item.connectivityStatus = '/sctportal/images/wifi_not_connected_red.png';
					item.connectivityFlag = 4;
					continue;
				}
				if (typeof (item.last_connect_time) !== 'number') {
					item.last_connect_time = moment.utc(item.last_connect_time).unix();
				}
				let disconnectTime = nowTime - item.last_connect_time; //disconnectTime in seconds
				let connectFrequency = item.reconnect_time;
				item.connectivityFlag = 0;
				const reconnectTime = 2.5 * connectFrequency;
				if (disconnectTime <= (reconnectTime > 60 ? reconnectTime : 60)) {
					//Less than 2.5 times of normal connectFrequency
					item.connectivityStatus = '/sctportal/images/wifi_connected.png';
					item.connectivityFlag = 1;
				} else {
					if (disconnectTime <= 24 * 3600) {
						//one day
						item.connectivityStatus = '/sctportal/images/wifi_not_connected_1d.png';
						item.connectivityFlag = 2;
					} else if (disconnectTime > 24 * 3600 && disconnectTime <= 7 * 24 * 3600) {
						//More than 24 hrs and less than a week
						item.connectivityStatus = '/sctportal/images/wifi_not_connected_7d_orange.png';
						item.connectivityFlag = 3;
					} else {
						item.connectivityStatus = '/sctportal/images/wifi_not_connected_red.png';
						item.connectivityFlag = 4;
					}
				}
			}
		}
		return devices;
	}

	getConnectivityIconLink(connectivityStatus: string, enterprisePermissions: any, mac_address: string, customer_id: number, site_id: number) {
		const isWifiConnected = connectivityStatus.includes('wifi');
		const isCellularConnected = connectivityStatus.includes('lte');

		const hasWifiAdminPermission = this.usersService.hasAccessPermission(enterprisePermissions, 'wifi_admin');
		const hasBasicSetupPermission = this.usersService.hasAccessPermission(enterprisePermissions, 'basic_setup');
		const hasAdvancedSetupPermission = this.usersService.hasAccessPermission(enterprisePermissions, 'advanced_setup');

		const hasCellularRequiredPermissions = this.usersService.hasAccessPermission(enterprisePermissions, 'debug_data');
		const hasNocPermission = this.usersService.hasAccessPermission(enterprisePermissions, 'noc');
		const hasWifiRequiredPermissions = hasWifiAdminPermission || hasBasicSetupPermission || hasAdvancedSetupPermission;

		if (isWifiConnected && hasWifiRequiredPermissions)
			return ['/', customer_id, site_id, mac_address, 'settings', 'networking'];

		else if(isCellularConnected && hasCellularRequiredPermissions && hasNocPermission)
			return ['/', customer_id, site_id, mac_address, 'admin-tools', 'cellular-settings'];

		return null;
	}

	getLastConnectionTimeMassage(device: any) {
		if (!device.last_connect_time)
			return '';

		const nowTime = this.commonUtil.nowTime();

		if (typeof (device.last_connect_time) !== 'number')
			device.last_connect_time = moment.utc(device.last_connect_time).unix();

		const lastConnectTime = nowTime - device.last_connect_time;
		if (lastConnectTime < this.oneMinuteDisconnected)
			return this.translate.instant('connectivity_track.less_than_one_minute');

		return this.translate.instant('connectivity_track.last_connect_time') + ': ' + this.commonUtil.secondsToElapsedTime(lastConnectTime);
	}

	getWarningOptionsList() {
		return [
			{ id: 'adc_error', value: this.translate.instant('warnings.adc_error') },
			{ id: 'modbus', value: this.translate.instant('warnings.modbus') },
			{ id: 'rtc', value: this.translate.instant('warnings.rtc') },
			{ id: 'eth', value: this.translate.instant('warnings.eth') },
			{ id: 'flash_size', value: this.translate.instant('warnings.flash_size') },
			{ id: 'port_expander', value: this.translate.instant('warnings.port_expander') },
			{ id: 'lost_rtc', value: this.translate.instant('warnings.lost_rtc') },
			{ id: 'password', value: this.translate.instant('warnings.password') },
			{ id: 'disconnected_device', value: this.translate.instant('warnings.disconnected_device') },
			{ id: 'invalid_device_zone', value: this.translate.instant('warnings.invalid_device_zone') },
			{ id: 'setup_network_on', value: this.translate.instant('warnings.setup_network_on') },
			{ id: 'no_setup', value: this.translate.instant('warnings.no_setup') },
			{ id: 'phase_a_ct_reversed', value: this.translate.instant('warnings.phase_a_ct_reversed') },
			{ id: 'phase_b_ct_reversed', value: this.translate.instant('warnings.phase_b_ct_reversed') },
			{ id: 'phase_c_ct_reversed', value: this.translate.instant('warnings.phase_c_ct_reversed') },
			{ id: 'ade90xx_calibrated', value: this.translate.instant('warnings.ade90xx_calibrated') },
			{ id: 'rtc_time_drift_warning', value: this.translate.instant('warnings.rtc_time_drift_warning') },
			{ id: 'wiring_mode_not_set_warning', value: this.translate.instant('warnings.wiring_mode_not_set_warning') },
			{ id: 'voltage_a_missing_warning', value: this.translate.instant('warnings.voltage_a_missing_warning') },
			{ id: 'voltage_b_missing_warning', value: this.translate.instant('warnings.voltage_b_missing_warning') },
			{ id: 'voltage_c_missing_warning', value: this.translate.instant('warnings.voltage_c_missing_warning') },
			{ id: 'voltage_a_exist_warning', value: this.translate.instant('warnings.voltage_a_exist_warning') },
			{ id: 'voltage_b_exist_warning', value: this.translate.instant('warnings.voltage_b_exist_warning') },
			{ id: 'voltage_c_exist_warning', value: this.translate.instant('warnings.voltage_c_exist_warning') },
			{ id: 'voltage_a_wrong_warning', value: this.translate.instant('warnings.voltage_a_wrong_warning') },
			{ id: 'voltage_b_wrong_warning', value: this.translate.instant('warnings.voltage_b_wrong_warning') },
			{ id: 'voltage_c_wrong_warning', value: this.translate.instant('warnings.voltage_c_wrong_warning') },
			{ id: 'current_a_wrong_warning', value: this.translate.instant('warnings.current_a_wrong_warning') },
			{ id: 'current_b_wrong_warning', value: this.translate.instant('warnings.current_b_wrong_warning') },
			{ id: 'current_c_wrong_warning', value: this.translate.instant('warnings.current_c_wrong_warning') },
			{ id: 'has_missed_up_events', value: this.translate.instant('warnings.has_missed_up_events') },
			{ id: 'missing_event_ids', value: this.translate.instant('warnings.missing_event_ids') },
			{ id: 'modbus_fault_warning', value: this.translate.instant('warnings.modbus_fault_warning') },
			{ id: 'device_not_setup', value: this.translate.instant('warnings.device_not_setup') },
		];
	}

	formatDeviceWarnings(item: any, options?: any) {
		const deviceWarnings = item.device_warnings,
			warningsRes = [],
			warningsArr = [],
			commonWarnings = [],
			singlePhaseWarnings = [];
		options = options || {};

		this.setEnabledWiresValues(item);
		const singlePhaseWiringMode = item.wiring_mode == this.wiringModeTypes.single_phase_3_wire || item.wiring_mode == this.wiringModeTypes.single_phase_2_wire,
			phase_a_used = this.enableWiresValues.includes('phase_a'),
			phase_b_used = this.enableWiresValues.includes('phase_b'),
			phase_c_used = this.enableWiresValues.includes('phase_c'),
			isSpecialDeviceWarningValues = (deviceWarnings & this.specificWarningValue) != 0;


		if (deviceWarnings) {
			if (options.deviceType === deviceTypes.TYPE2) {
				if ((deviceWarnings & 0x00000010) != 0)
					warningsArr.push('phase_a_ct_reversed');

				if ((deviceWarnings & 0x00000020) != 0)
					warningsArr.push('phase_b_ct_reversed');

				if ((deviceWarnings & 0x00000040) != 0)
					warningsArr.push('phase_c_ct_reversed');

				if ((deviceWarnings & 0x00000001) != 0)
					warningsArr.push('ade90xx_calibrated');
			}

			if (options.deviceType === deviceTypes.TYPE1 && this.isFWSupportField(item.fw_version, 'type_1_device_warnings')) {
				if ((deviceWarnings & 0x0001) != 0)
					warningsArr.push('modbus_fault_warning');

				if ((deviceWarnings & 0x0002) != 0)
					warningsArr.push('ct_ratio_not_set_warning');

				if ((deviceWarnings & 0x0004) != 0)
					warningsArr.push('wiring_mode_not_set_warning');

				if (singlePhaseWiringMode) {
					if ((deviceWarnings & 0x0008) != 0) {
						if (isSpecialDeviceWarningValues) {
							if (phase_a_used)
								singlePhaseWarnings.push('voltage_a_missing_warning');
							else
								singlePhaseWarnings.push('voltage_a_exist_warning');
						} else {
							if (phase_a_used)
								singlePhaseWarnings.push('voltage_a_missing_warning');
						}
					}

					if ((deviceWarnings & 0x00010) != 0) {
						if (isSpecialDeviceWarningValues) {
							if (phase_b_used)
								singlePhaseWarnings.push('voltage_b_missing_warning');
							else
								singlePhaseWarnings.push('voltage_b_exist_warning');
						} else {
							if (phase_b_used)
								singlePhaseWarnings.push('voltage_b_missing_warning');
						}
					}

					if ((deviceWarnings & 0x0020) != 0) {
						if (isSpecialDeviceWarningValues) {
							if (phase_c_used)
								singlePhaseWarnings.push('voltage_c_missing_warning');
							else
								singlePhaseWarnings.push('voltage_c_exist_warning');
						} else {
							if (phase_c_used)
								singlePhaseWarnings.push('voltage_c_missing_warning');
						}

					}

					if (isSpecialDeviceWarningValues || phase_a_used) {
						if ((deviceWarnings & 0x0040) != 0)
							singlePhaseWarnings.push('voltage_a_wrong_warning');

						if ((deviceWarnings & 0x0200) != 0)
							singlePhaseWarnings.push('current_a_reversed_warning');

						if ((deviceWarnings & 0x1000) != 0)
							singlePhaseWarnings.push('current_a_wrong_warning');
					}

					if (isSpecialDeviceWarningValues || phase_b_used) {
						if ((deviceWarnings & 0x0080) != 0)
							singlePhaseWarnings.push('voltage_b_wrong_warning');

						if ((deviceWarnings & 0x0400) != 0)
							singlePhaseWarnings.push('current_b_reversed_warning');

						if ((deviceWarnings & 0x2000) != 0)
							singlePhaseWarnings.push('current_b_wrong_warning');
					}

					if (isSpecialDeviceWarningValues || phase_c_used) {
						if ((deviceWarnings & 0x0100) != 0)
							singlePhaseWarnings.push('voltage_c_wrong_warning');

						if ((deviceWarnings & 0x0800) != 0)
							singlePhaseWarnings.push('current_c_reversed_warning');

						if ((deviceWarnings & 0x4000) != 0)
							singlePhaseWarnings.push('current_c_wrong_warning');
					}
				} else {
					if ((deviceWarnings & 0x0008) != 0)
						singlePhaseWarnings.push('voltage_a_missing_warning');

					if ((deviceWarnings & 0x00010) != 0)
						singlePhaseWarnings.push('voltage_b_missing_warning');

					if ((deviceWarnings & 0x0020) != 0)
						singlePhaseWarnings.push('voltage_c_missing_warning');

					if ((deviceWarnings & 0x0040) != 0)
						singlePhaseWarnings.push('voltage_a_wrong_warning');

					if ((deviceWarnings & 0x0080) != 0)
						singlePhaseWarnings.push('voltage_b_wrong_warning');

					if ((deviceWarnings & 0x0100) != 0)
						singlePhaseWarnings.push('voltage_c_wrong_warning');

					if ((deviceWarnings & 0x0200) != 0)
						singlePhaseWarnings.push('current_a_reversed_warning');

					if ((deviceWarnings & 0x0400) != 0)
						singlePhaseWarnings.push('current_b_reversed_warning');

					if ((deviceWarnings & 0x0800) != 0)
						singlePhaseWarnings.push('current_c_reversed_warning');

					if ((deviceWarnings & 0x1000) != 0)
						singlePhaseWarnings.push('current_a_wrong_warning');

					if ((deviceWarnings & 0x2000) != 0)
						singlePhaseWarnings.push('current_b_wrong_warning');

					if ((deviceWarnings & 0x4000) != 0)
						singlePhaseWarnings.push('current_c_wrong_warning');
				}
			}
		}

		let qv = this.commonUtil.decompress(item.quick_view || {}, 'quick_view');
		if (qv.hw_status) {
			let qvWarnings: any = {
				0x01: 'modbus',
				0x02: 'rtc',
				0x04: 'eth',
				0x08: 'flash_size',
				0x10: 'port_expander',
			};
			if (options.deviceType === deviceTypes.TYPE2)
				qvWarnings[0x01] = 'adc_error';
			else
				qvWarnings[0x20] = 'cel_error';

			for (let id in qvWarnings) {
				if ((qv.hw_status & +id) != 0) {
					warningsArr.push(qvWarnings[id]);
				}
			}
		}

		if (qv.live_rt?.runtime_warnings)
			warningsArr.push(...(this.mapRunTimeWarning(qv.live_rt.runtime_warnings)));

		if (item.lost_rtc) {
			warningsArr.push('lost_rtc');
		}
		if (qv.setup_network_on) {
			warningsArr.push('setup_network_on');
		}
		if (options.includePassword && !item.device_password) {
			warningsArr.push('password');
		}

		if (options.includeOffline) {
			let disconnectTime = moment().utc().unix() - options.deviceInfo.last_connect_time;
			if (disconnectTime > 3600 * 24 * 30) {
				warningsArr.push('disconnected_device');
			}
		}

		if (options.siteZoneId !== undefined && options.siteZoneId != options.deviceInfo.zone_id) {
			warningsArr.push('invalid_device_zone');
		}

		if (item.setup_done !== undefined && !item.setup_done) {
			warningsArr.push('no_setup');
		}

		if (item.setup_done === false && item.sct_pre_configured === false) {
			commonWarnings.push('device_not_setup');
		}

		if (item.has_missed_up_events)
			warningsRes.push('has_missed_up_events');

		if (item.missing_event_ids && item.missing_event_ids.length)
			warningsRes.push('missing_event_ids');

		let ignorePermissionList = this.excludePermissionWarnings();
		for (let warning of warningsArr) {
			if ((options.canIgnorePermission && ignorePermissionList.includes(warning)) || this.usersService.hasAccessPermission({ permission: options.permission }, 'warning.' + warning))
				warningsRes.push(warning);
		}

		if (item.has_rtc_time_drift_warning === true && this.usersService.userHasNOCAccess())
			warningsRes.push('rtc_time_drift_warning');

		if (options.getSinglePhaseWarnings)
			warningsRes.push(...singlePhaseWarnings);

		warningsRes.push(...commonWarnings);
		return warningsRes;
	}

	mapRunTimeWarning(warning: number) {
		const warningsArr = []
		if ((warning & 0x00000001) != 0)
			warningsArr.push('negative_consumed_energy_check');

		if ((warning & 0x00000002) != 0)
			warningsArr.push('negative_generated_energy_check');

		return warningsArr;
	}

	setEnabledWiresValues(deviceInfo: any) {
		for (let option in this.enableWiresOptions) {
			if ((deviceInfo.enabled_wires & this.enableWiresOptions[option]) != 0)
				this.enableWiresValues.push(option);
		}
	}

	excludePermissionWarnings() {
		return [
			'lost_rtc',
			'password',
			'disconnected_device',
			'invalid_device_zone',
			'no_setup',
			'phase_a_ct_reversed',
			'phase_b_ct_reversed',
			'phase_c_ct_reversed',
			'current_a_reversed_warning',
			'current_b_reversed_warning',
			'current_c_reversed_warning',
		];
	}

	deviceInfoForGlobalRecords(macAddress: string) {
		return this.gatewayService.get(`${this.route}device-info-global-records/${macAddress}`);
	}

	sendAction(macAddress: string, actionType: string, options?: any) {
		options = options || {};
		options.wait = (actionType == 'quickView' ? 0 : this.waitTimeout)
		return this.gatewayService.post(`${this.route}send-action/${macAddress}/${actionType}`, options);
	}

	getDailyDetails(macAddress: string, option: { fromTime: number, toTime: number, checkConnectivityAfterShippingDate?: boolean }) {
		return this.gatewayService.get(`daily-details/${macAddress}`, option);
	}

	getGlitchesSummary(macAddress: string, fromTime: number, toTime: number) {
		return this.gatewayService.get(`glitch/getGlitchesSummary`, { macAddress, fromTime, toTime });
	}

	saveDeviceOwners(data: any) {
		return this.gatewayService.post(`${this.route}saveDeviceOwners`, data);
	}

	sendManageDeviceRequest(data: any) {
		return this.gatewayService.post(`${this.route}sendManageDeviceRequest`, data);
	}

	getTimeLostReport() {
		return this.gatewayService.post(`${this.route}getTimeLostReport`, {});
	}

	resetRTCLostDetection(macAddress: string) {
		return this.gatewayService.post(`${this.route}resetRTCLostDetection`, { macAddress });
	}

	getChangesReportDevices() {
		return this.gatewayService.get(`${this.route}get-changes-report-devices`, {});
	}

	getEvents(macAddress: string, fromTime: number, toTime: number) {
		return this.gatewayService.get('events', {
			macAddress: macAddress,
			fromTime,
			toTime
		});
	}

	getRt(macAddress: string, fromTime: number) {
		return this.gatewayService.get('rt', { macAddress, fromTime });
	}

	getGlitches(macAddress: string, fromTime: number, toTime: number) {
		return this.gatewayService.get('glitch', {
			macAddress: macAddress,
			fromTime,
			toTime
		});
	}

	getDebugs(macAddress: string, options: { lastId: number, firstId: number, limit: number, isBack: boolean }) {
		return this.gatewayService.get('debug-records', { macAddress, ...options });
	}

	getLiveQV(macAddress: string, noUiBlock: boolean) {
		return this.gatewayService.get(`devices/get-live-quick-view/${macAddress}`, new CustomHttpParams({ noUIBlock: noUiBlock }));
	}

	getQV(macAddress: string, noUiBlock: boolean) {
		return this.gatewayService.get(`devices/get-quick-view/${macAddress}`, new CustomHttpParams({ noUIBlock: noUiBlock }));
	}

	getADC(macAddress: string, noUiBlock: boolean) {
		return this.gatewayService.get(`devices/get-adc/${macAddress}`, new CustomHttpParams({ noUIBlock: noUiBlock }));
	}

	getConnectivityTrack(macAddress: string, fromDate: number, toDate: number) {
		return this.gatewayService.get(`connectivity-track/${macAddress}`, {
			fromDate,
			toDate
		});
	}

	getDeviceConfigTrack(macAddress: string, options: { lastId: number, firstId: number, limit: number, isBack: boolean }) {
		return this.gatewayService.post(`${this.route}getDeviceConfigTrack`, { id: macAddress, options });
	}

	getCellularConfigTrack(macAddress: string, options: { lastId: number, firstId: number, limit: number, isBack: boolean}) {
		return this.gatewayService.post(`${this.route}getCellularConfigTrack`, { id: macAddress, options });
	}

	getPerformanceAnalytics(macAddress: string) {
		return this.gatewayService.post(`device-analytics/getPerformanceAnalytics`, { mac_address: macAddress });
	}

	getBlockedFWReport() {
		return this.gatewayService.post(`${this.route}getBlockedFWReport`, {});
	}

	unBlockFWReport(id: any, version: any) {
		return this.gatewayService.post(`${this.route}unBlockFWReport`, { id, version });
	}

	getSimInfo(iccid: string, isLive?: boolean, options?: any) {
		return this.gatewayService.post(`cellular/getSimInfo`, { iccid, isLive, options });
	}

	updateSimConfig(iccids: string[], operation: 'enable' | 'disable' | 'lock' | 'unlock') {
		return this.gatewayService.post(`cellular/update-sim-config`, { iccids, operation });
	}

	enableDisableSim(iccid: string) {
		return this.gatewayService.post(`cellular/enable-disable-sim`, { iccid });
	}

	cmResetDeviceAfterDelete(mac_address: string) {
		return this.gatewayService.post(this.route + 'cmResetDeviceAfterDelete', { mac_address });
	}

	addDeviceToWakeUpList(mac_address: string, iccid: string, remoter_server_port: number) {
		return this.gatewayService.post(`cellular/addDeviceToWakeUpList`, { mac_address, iccid, remoter_server_port });
	}

	resetCellularOEM(mac_address: string) {
		return this.gatewayService.post(`cellular/reset-oem`, { mac_address });
	}

	regenerateDailyDetails(mac_address: string) {
		return this.gatewayService.post(`daily-details/genDailyDetails`, { mac_address });
	}

	formatTrackLogChanges(device: any, changesStackOrigin: any, siteZoneId: number) {
		if (Object.keys(changesStackOrigin).length == 0)
			return changesStackOrigin;

		let changesStack = _.cloneDeep(changesStackOrigin);

		const daysMask: string | string[] = []
		const dates = ['last_pm_date'];
		const temperatureFields: string | string[] = [];
		//const booleans: string | string[] = ['enable_wifi_sta', 'enable_wifi_ap_on_start', 'enable_serversocket'];
		const booleans: string | string[] = [];
		const noYesFields: string | string[] = [];
		const invertedBooleans: string | string[] = [];

		var daysOfTheWeek = this.commonUtil.getDropDownListData('week');
		var disableEnable = [this.translate.instant('g.disable'), this.translate.instant('g.enable')];
		var noYes = [this.translate.instant('g.no'), this.translate.instant('g.yes')];
		var invalidOptionText = this.translate.instant('g.invalid_option') + '!';

		for (var field in changesStack) {

			changesStack[field] = [[changesStack[field]]];
			if (temperatureFields.indexOf(field) > -1) {

				if (device.temperatureformat) {
					changesStack[field].forEach((change: number[]) => {
						change[0] = Math.round(change[0] * 1.8 + 32);
					});
				}
			} else if (dates.indexOf(field) > -1) {

				changesStack[field].forEach((change: any) => {
					let timeInSiteTimezone = this.commonUtil.getZoneTimestampFromUTC(siteZoneId, change[0]);
					change[0] = moment(timeInSiteTimezone * 1000).utc().format('MM/DD/YYYY');
				});
			} else if (invertedBooleans.indexOf(field) > -1) {

				changesStack[field].forEach((change: any) => {
					change[0] = +(!change[0]);
					change[0] = disableEnable[change[0]];
					if (!change[0])
						change[0] = invalidOptionText;
				});
			} else if (booleans.indexOf(field) > -1) {

				changesStack[field].forEach((change: any) => {
					change[0] = +(change[0]);
					change[0] = disableEnable[change[0]];
					if (!change[0])
						change[0] = invalidOptionText;
				});
			} else if (daysMask.indexOf(field) > -1) {

				changesStack[field].forEach((change: any) => {
					if (typeof change[0] == 'object' && change[0].length > 0) {
						const dayNames: string[] = [];
						change[0].forEach((day: any) => {
							if (daysOfTheWeek[day])
								dayNames.push(daysOfTheWeek[day].text);
							else
								dayNames.push(this.translate.instant('g.invalid_day') + '!');
						});
						change[0] = dayNames.join(', ');
					}
				});
			} else if (noYesFields.indexOf(field) > -1) {
				changesStack[field].forEach((change: any) => {
					change[0] = +change[0];
					change[0] = noYes[change[0]];
					if (!change[0])
						change[0] = invalidOptionText;
				});
			}
		}

		let toReturn: any = {};
		for (var field in changesStack) {
			toReturn[field] = changesStack[field][0][0];
			switch (field) {
				case "last_saved_user_id":
					if (toReturn[field] == SYSTEM_USER_ID)
						toReturn[field] = this.translate.instant('g.system');
					break;
				case "last_change_time":
					if (typeof (toReturn[field]) != 'number')
						break;
					let timeInSiteTimezone = this.commonUtil.getZoneTimestampFromUTC(siteZoneId, toReturn[field]);
					toReturn[field] = moment(timeInSiteTimezone * 1000).utc().format('MM/DD/YYYY hh:mm:ss A');
					break;
			}
		}
		return toReturn;
	}

	formatDeletedMACandSN(text: string) {
		let res = this.translate.instant('g.deleted') + ': ';
		let tmp1 = text.substring(3);
		let tmp2 = tmp1.split('-');
		res += tmp2[0] + ' (' + moment(+tmp2[1] * 1000).utc().format('MM/DD/YYYY hh:mm:ss A') + ')'
		return res;
	}

	isFWSupportField(fwVersion: number, field: string) {
		switch (field) {
			case 'volt_1_2':
			case 'volt_2_3':
			case 'volt_3_1':
			case 'volt_1_2_enable':
			case 'volt_2_3_enable':
			case 'volt_3_1_enable':
				return fwVersion >= 1.2203;
			case 'meter_sn':
			case 'meter_sn_changetime':
				return fwVersion > 1.22;
			case 'type_1_device_warnings':
				return fwVersion >= 1.24;
			case 'type_1_sct_pre_configured':
				return fwVersion >= 1.24;
		};
		return false;
	}

	searchBy(key: string, isAdmin?: boolean) {
		key = key.trim().replace(/:/g, "");
		let searchBy: 'serial_number' | 'mac_address' | 'remoter_server_ip' = 'serial_number';
		if (/^[a-fA-F0-9]{12}$/.test(key))
			searchBy = 'mac_address';
		else if (this.validationService.dataValidator('ipAddress', key))
			searchBy = 'remoter_server_ip';
		return searchBy;
	}

	validateRmaNo(value: string) {
		return /^RMA\d{10}$/.test(value);
	}

	switchDeviceConnection(macAddress: string, action: string) {
		return this.gatewayService.post(this.route + 'switch-device-connection', { macAddress, action });
	}

	getSwitchedDeviceConnection(macAddress: string) {
		return this.gatewayService.get(this.route + `get-switched-device-connection-info/${macAddress}`);
	}

	setForceDebugRecordNow(mac_address: string) {
		return this.gatewayService.post(this.route +'setForceReadDebugRecordsNow', { mac_address });
	}

	exportDevicesEvents(macAddressess: string[], siteId: number, dateRange: {from: number, to: number}, selectedColumnsFields: string[]) {
		return this.gatewayService.post(this.route + 'queueExportEventsRequest', {macAddressess, siteId, dateRange, selectedColumnsFields});
	}

	getMostUsageSims() {
		return this.gatewayService.post(`cellular/getMostUsageSims`, {});
	}

	reverseRTs(macAddress: string, startId: number, endId: number) {
		return this.gatewayService.post('rt/reverse-rts', { mac_address: macAddress, start_id: startId, end_id: endId });
	}

	reverseEvents(macAddress: string, eventsIds: { start_id: number, end_id: number }) {
		return this.gatewayService.post('events/reverseEvents', { macAddress, eventsIds });
	}
}
