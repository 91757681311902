import { UsersService } from '@app/business/users/users.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-non-logged-in-user-header',
	templateUrl: './non-logged-in-user-header.component.html',
	styleUrls: ['./non-logged-in-user-header.component.css']
})
export class NonLoggedInUserHeaderComponent implements OnInit {
	user: any;
	constructor(public usersService: UsersService) { }

	ngOnInit(): void {
		const user = this.usersService.getCurrentUser();
		if (user) {
			if (user && Object.keys(user).length > 0) {
				this.user = user;
			}
		}
	}


}
