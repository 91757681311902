import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonDataService } from '../services/common-data.service';
import { UsersService } from '@app/business/users/users.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
	// @Input() visibleSideMenu: boolean;

	user: any = {};
	showHeader = false;
	isProduction = false;
	isStaging = false;

	// searchString = '';
	// searchDevicesResult: any = {};
	// @ViewChild('searchInput') searchInput: ElementRef;

	constructor(
		private router: Router,
		private usersService: UsersService
	) // public header: HeaderService,
	{ }

	ngOnInit(): void {

		this.usersService.getCurrentUser();
		// this.header.toggleSearchBox(this.header.SearchBoxVisible);
	}

	logout() {
		//this.usersService.logout().subscribe((done: boolean) => {
		//    // this.navigation.setPreviousUrl(null, true);
		//    this.usersService.setCurrentUser({});
		//    this.router.navigate(['/login']);
		//});
	}

	// toggleHeader() {
	// 	this.showHeader = !this.showHeader;
	// }

	// closeMobileHeader() {
	// 	this.showHeader = false;
	// }
}
