<div class="footer-main">
	<div class="d-flex justify-content-center h-100 align-items-center text-white" *ngIf="showBanner">
		<span>
			By Using Our Site, You Acknowledge That You Have Read And Understand Our <a target="_blank"
				href="/cookie-policy">Cookie Policy</a>.
		</span>
		<button pButton type="button" class="p-button-outlined border-0 text-white" (click)="closeBanner()"
			icon="pi pi-times"></button>
	</div>
	<div class="d-flex justify-content-start ms-2 h-100 align-items-center text-white" *ngIf="!showBanner">
		<span>
			Copyright © {{currentYear}} Powered by SCT. All Rights Reserved.<button pButton type="button"
				[label]="'contact_us.title' | translate" routerLink="/contact-us"
				class="p-button-link p-0 ms-2"></button>
		</span>
	</div>
</div>