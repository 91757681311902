import {
	Directive,
	ElementRef,
	Input,
	HostListener,
	AfterViewInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
	selector: '[scroller-tab]',
	exportAs: 'scroller-tab'
})
export class ScrollerTabDirective implements AfterViewInit {
	private canScrollSubject = new BehaviorSubject<{ canScrollStart: boolean, canScrollEnd: boolean }>({ canScrollEnd: false, canScrollStart: false });
	public canScroll = this.canScrollSubject.asObservable();

	constructor(private elementRef: ElementRef) { }

	ngAfterViewInit(): void {
		this.canScrollSubject.next({ canScrollStart: this.canScrollStart, canScrollEnd: this.canScrollEnd });
	}

	@Input() scrollUnit = 1;

	private get element() {
		return this.elementRef.nativeElement;
	}

	get isOverflow() {
		return this.element.scrollWidth > this.element.clientWidth;
	}

	scroll(direction: number) {
		this.element.scrollLeft += this.scrollUnit * direction;
		this.canScrollSubject.next({ canScrollStart: this.canScrollStart, canScrollEnd: this.canScrollEnd });
	}

	get canScrollStart() {
		return this.element.scrollLeft > 0;
	}

	get canScrollEnd() {
		return this.element.scrollLeft + this.element.clientWidth < this.element.scrollWidth;
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.canScrollSubject.next({ canScrollStart: this.canScrollStart, canScrollEnd: this.canScrollEnd });
	}
}
