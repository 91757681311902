import { GatewayService } from './services/gateway/gateway.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SideMenuService } from './services/side-menu/side-menu.service';
import { LoaderInterceptorService } from './services/loader-interceptor/loader-interceptor.service';



@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
	],
	providers: [
		GatewayService,
		SideMenuService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptorService,
			multi: true
		},
	]
})
export class CoreModule { }
