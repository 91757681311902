import { NavigationService } from '@app/shared/services/navigation.service';
import { SideMenuService } from './../../../core/services/side-menu/side-menu.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@app/business/users/users.service';
import { SCTFormBuilderField, SCTFormBuilder, InputType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { SCTDialog } from '@app/shared/sct-dialog/sct-dialog.model';

@Component({
	selector: 'app-login',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

	formFields: SCTFormBuilderField[] = [];
	form!: SCTFormBuilder;
	pageName: string = 'change_password';
	isSetPass?: boolean;
	invalidFields = {};
	user: any = {};
	isResetPasswordPage: boolean = false;
	defaultCancelUrl: string = '/';

	constructor(
		private router: Router,
		private translateService: TranslateService,
		public toastService: SctToastService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
	) { }

	ngOnInit(): void {
		this.sideMenuService.show(false);
		this.pageName = this.route.snapshot.data['pageName'];

		switch (this.pageName) {
			case 'reset_password':
				this.isResetPasswordPage = true;
				this.user.id = this.route.snapshot.params.userId;
				this.route.queryParams.subscribe(params => {
					this.user.enc = params.enc || '';
					this.isSetPass = params.isSetPass || false;
				});
				break;
			default:
				// @Wael: check below
				//@todo when login check is ready
				//this.usersService.loginCheck().subscribe();
				break;
		}
		this.prepareFormFields();
	}

	saveData(data: { old_password: string, new_password: string, confirm_password: string }) {
		if (data.old_password === data.new_password) {
			this.invalidFields = { new_password: true };
			this.formFields.filter((item) => {
				item.labelUnderField = undefined;
				item.labelUnderFieldCssClass = "";
				if (item.name === 'new_password') {
					item.labelUnderField = this.translateService.instant('users.please_choose_a_new_password');
					item.labelUnderFieldCssClass = "text-danger";
				}
			});
			return;
		} else if (data.confirm_password !== data.new_password) {
			this.invalidFields = { new_password: true, confirm_password: true };
			this.formFields.filter((item) => {
				item.labelUnderField = undefined;
				item.labelUnderFieldCssClass = "";
				if (item.name === 'confirm_password') {
					item.labelUnderField = this.translateService.instant('users.passwords_dont_match');
					item.labelUnderFieldCssClass = "text-danger";
				}
			});
			return
		}

		switch (this.pageName) {
			case 'reset_password':
				this.user.new_password = data.new_password;
				this.user.confirm_password = data.confirm_password;
				this.usersService.resetPassword(this.user).subscribe(
					(data: any) => {
						this.handleChangePasswordResponse(data);
					}
				);
				break;
			case 'force_change':
				this.usersService.changeExpiredPassword(data).subscribe(
					(data: any) => {
						this.handleChangePasswordResponse(data);
					}
				);
				break;
			default:
				this.usersService.changeUserPassword(data).subscribe((res: any) => {
					// @Motasem: check below
					// @todo: use api_status like iotah
					this.handleChangePasswordResponse(res);
				});
				break;
		}
	}

	prepareFormFields() {
		this.formFields = [
			{
				name: 'new_password',
				label: 'login_register.new_password',
				type: InputType.PASSWORD,
				sctFormOptions: {
					passwordOptions: {
						toggleMask: true,
						feedback: true,
					}
				},
				labelUnderInput: this.translateService.instant('password_field.format')
			},
			{
				name: 'confirm_password',
				label: 'login_register.confirm_password',
				type: InputType.PASSWORD,
				sctFormOptions: {
					passwordOptions: {
						toggleMask: true
					}
				}
			},
		];

		if (this.pageName !== 'reset_password' && this.pageName !== 'force_change') {
			this.formFields.unshift({
				name: 'old_password',
				label: 'login_register.old_password',
				type: InputType.PASSWORD,
				sctFormOptions: {
					passwordOptions: {
						toggleMask: true
					}
				}
			});
		}

		this.form = {
			fields: this.formFields,
			callback: (data: any) => this.saveData(data),
		};
	}

	handleChangePasswordResponse(data: any) {
		if (data.api_status) {
			switch (data.api_status) {
				case 2:
					this.toastService.showMessage('translate|login_register.incorrect_old_password');
					break;
				case 3:
					this.toastService.showMessage('translate|login_register.choose_new_password');
					break;
				case 4:
					this.toastService.showMessage('translate|login_register.passwords_dont_match');
					break;
				case 5:
					this.toastService.showMessage('translate|login_register.already_loggedin');
					this.router.navigate(['/']);
					break;

				case 6:
					this.toastService.showMessage('translate|login_register.reset_expired');
					this.router.navigate(['/']);
					break;

				default:
					this.toastService.showMessage('globalErrMsg');
					break;
			}
		} else {
			if (this.isSetPass)
				this.toastService.showMessage('translate|g.password_set_successfully', 'success');
			else
				this.toastService.showMessage('translate|login_register.password_changed_successfully', 'success');

			this.usersService.logout();
		}
	}

	goBack() {
		return this.navigation.goBack(this.defaultCancelUrl);
	}
}
