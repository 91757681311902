<div>
	<h2>{{'left_menu.smart_rebates_sites' | translate}}</h2>
	<table class="table table-striped" *ngIf="data.length > 0; else noData">
		<thead>
			<tr>
                <th>{{'site.customer_name' | translate}}</th>
				<th>{{'site.site_name' | translate}}</th>
				<th>{{'site.content' | translate}}</th>
				<th>{{'site.consumptions_report' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of data">
                <td>
                    <a [routerLink]="['/customer', row.customerId]">{{row.customerName}}</a>
                </td>
                <td>
                    <a [routerLink]="['/', row.customerId, row.id]">{{row.name}}</a>
                </td>
				<td>{{row.count}}</td>
				<td>
					<i class="fa fa-download pointer mr-1" (click)="showConsumptionsReport(row)"></i>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-sct-dialog [dialog]="consumptionsReportDialog" [okText]="'g.export_to_xlsx'" [cancelText]="'g.cancel' | translate" class="dialog">
	<div dialog-content class="consumptions_report_dialog_content">
		<div class="py-4">
			<!-- select year -->
			<div class="mx-2 row">
				{{'g.select_year' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="years" bindLabel="label" bindValue="id"
					placeholder="{{'g.select_year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
				</ng-select>
			</div>
			<!-- select quarter -->
			<div class="mx-2 row">
				{{'g.select_quarter' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="quarters" bindLabel="value" bindValue="id"
					placeholder="{{'g.select_quarter' | translate}}" name="year" [(ngModel)]="selectedQuarter" [clearable]="false">
				</ng-select>
			</div>
			<div class="mx-2 row">
				{{'g.sr_enable_date' | translate}}
				<div class="input-group border-right rounded">
					<input readonly class="form-control" id="fromDate" placeholder="mm/dd/yyyy" name="dp"
						[(ngModel)]="selectedStartDate" bsDatepicker #fromDate="bsDatepicker"
						[bsConfig]="{ containerClass: 'theme-dark-blue' }" />
					<span class="input-group-btn">
						<button class="btn btn-default btn-date brder-right border-top border-bottom rounded-right"
							(click)="fromDate.toggle()" type="button">
							<i class="fa fa-calendar"></i>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</app-sct-dialog>
