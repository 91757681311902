<div class="h-100">
	<div class="row">
		<ng-template #noData>
			<div class="panel panel-default">
				<div class="panel-body">
					<strong>{{'g.no_warnings' | translate}}</strong>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="row mb-2">
		<div class="col-2 d-flex align-items-center">
			<ng-select
				[(ngModel)]="tableFilter"
				[items]="tableFilterList"
				name="tags_filter"
				bindValue="id"
				bindLabel="name"
				[clearable]="false"
				(ngModelChange)="onFilterChange()"
				[ngStyle]="{'width': '165px'}"
			></ng-select>
		</div>
	</div>
	<div class="row mb-2">
		<div class="col-6" [ngClass]="{ 'hidden': tableFilter != 'period'}">
			<ng-container>
				<sct-date-range [dateRange]="dateRange" [type]="'DEFAULT'" [config]="dateRangeConfig" #dateRangePicker (onDatesChanged)="onDatesChanged()"></sct-date-range>
			</ng-container>
		</div>
	</div>
	<div class="white-box p-4">
		<div>
			<app-grid [data]="dataTable" [columns]="columnDefs" [autoSizeColumns]="true" #agGrid [id]="siteName + 'warning'"
				*ngIf="dataTable.length !== 0" [excludeOnExport]="['triggered_qv_values', 'cleared_qv_values', 'acknowledge']"></app-grid>

			<div *ngIf="dataTable.length == 0">{{'g.no_data_available_for_the_selected_period' | translate}}</div>
		</div>
	</div>
</div>

<app-sct-dialog [dialog]="triggeredQVValuesDialog">
	<div dialog-content>
		<div class="list_container">
			<div class="header">
				<p><span>{{"warnings.customer_name" | translate}}:</span><span>{{" " + triggeredQVValues.customer_name}}</span></p>
				<p><span>{{"warnings.site_name" | translate}}:</span><span>{{" " + triggeredQVValues.site_name}}</span></p>
				<p><span>{{"warnings.device_sn" | translate}}:</span><span>{{" " + triggeredQVValues.serial_number}}</span></p>
				<p><span>{{"warnings.warning" | translate}}:</span><span>{{" " + triggeredQVValues.warning_text}}</span></p>
			</div>
			<table>
				<tr>
					<th>{{"warnings.field_name" | translate}}</th>
					<th>{{"warnings.trigger_value" | translate}}</th>
					<th>{{"warnings.clear_value" | translate}}</th>
				</tr>
				<tr *ngFor="let item of triggeredQVValues['main']">
					<td>{{("quick_view." + item.field_name) | translate}}</td>
					<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
					<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
				</tr>
				<tr>
					<td colspan="3" *ngIf="triggeredQVValues['live_event']?.length">{{"quick_view.live_event" | translate}}</td>
				</tr>
				<tr *ngFor="let item of triggeredQVValues['live_event']">
					<td>{{("warnings." + item.field_name) | translate}}</td>
					<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
					<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
				</tr>
					<tr>
					<td colspan="3" *ngIf="triggeredQVValues['live_rt']?.length">{{"quick_view.live_rt" | translate}}</td>
				</tr>
				<tr *ngFor="let item of triggeredQVValues['live_rt']">
					<td>{{("rt_warning_fields." + item.field_name) | translate}}</td>
					<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
					<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
				</tr>
				<tr>
					<td colspan="3" *ngIf="triggeredQVValues['glitches']?.length">{{"quick_view.glitches_name" | translate}}</td>
				</tr>
				<tr *ngFor="let item of triggeredQVValues['glitches']">
					<td>{{("glitches." + item.field_name) | translate}}</td>
					<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
					<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
				</tr>
			</table>
			<p class="text-left" *ngIf="noQVData">
				{{'g.no_data_to_show' | translate}}
			</p>
		</div>
	</div>
</app-sct-dialog>
