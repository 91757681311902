import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CommonDataService } from './common-data.service';
import { Location, DatePipe } from '@angular/common';
import objectMappers from '../../../../../shared/mapper.json';
import moment from 'moment';
import { difference } from 'lodash';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	alphabetCharacters = "abcdefghijklmnopqrstuvwxyz".split('');
	isMobileScreen: boolean = window.innerWidth < 767;
	providedFilterParams: any;

	readonly isDisconnectedWithWaitResponse = 5;

	constructor(
		private commonData: CommonDataService,
		private location: Location,
		private translateService: TranslateService,
		private datePipe: DatePipe
	) { }

	genRandomString(len: number) {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < len; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

	decompress(data: any, type: any) {
		const decompressedData: any = {};
		const mapped: any = this.swapObjectKeyAndValue((objectMappers as any)[type]);
		for (const field in data) {
			const mappedKey = mapped[field];
			if (mappedKey) {
				const special: any = { live_event: 'events', live_rt: 'rt' };
				const specialType = special[mappedKey];
				if (specialType) {
					decompressedData[mappedKey] = this.decompress(data[field], specialType);
				}
				else {
					decompressedData[mappedKey] = data[field];
				}
			} else {
				decompressedData[field] = data[field];
			}
		}
		return decompressedData;
	}

	swapObjectKeyAndValue(sourceObj: any) {
		return Object.keys(sourceObj).reduce((obj: any, key) => (obj[sourceObj[key]] = key, obj), {});
	}

	getDeepObject(path: (string | number)[], obj: any) {
		return path.reduce(function (prev, curr) {
			return prev ? (prev[curr] !== null ? prev[curr] : '') : '';
		}, obj || self);
	}

	lpad(str: string, length: number, padString = '0') {
		while (str.length < length)
			str = padString + str;
		return str;
	}

	getDateFormattedFromUnixTimeStamp(timeStamp: number, format = 'default') {
		if (timeStamp != +timeStamp) return "";
		let LEAPOCH = (946684800 + 86400 * (31 + 29));
		let DAYS_PER_400Y = (365 * 400 + 97);
		let DAYS_PER_100Y = (365 * 100 + 24);
		let DAYS_PER_4Y = (365 * 4 + 1);
		let remdays: any, remsecs, remyears;
		let qc_cycles, c_cycles, q_cycles;
		let years, months;
		let wday, yday, leap: any;
		let days_in_month = [31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31, 29];
		let secs = timeStamp - LEAPOCH;
		let days = Math.floor(secs / 86400);
		remsecs = secs % 86400;
		if (remsecs < 0) {
			remsecs += 86400;
			days--;
		}

		wday = (3 + days) % 7;
		if (wday < 0) wday += 7;

		qc_cycles = Math.floor(days / DAYS_PER_400Y);
		remdays = days % DAYS_PER_400Y;
		if (remdays < 0) {
			remdays += DAYS_PER_400Y;
			qc_cycles--;
		}

		c_cycles = Math.floor(remdays / DAYS_PER_100Y);
		if (c_cycles == 4) c_cycles--;
		remdays -= c_cycles * DAYS_PER_100Y;

		q_cycles = Math.floor(remdays / DAYS_PER_4Y);
		if (q_cycles == 25) q_cycles--;
		remdays -= q_cycles * DAYS_PER_4Y;

		remyears = Math.floor(remdays / 365);
		if (remyears == 4) remyears--;
		remdays -= remyears * 365;

		leap = !remyears && (q_cycles || !c_cycles);
		yday = remdays + 31 + 28 + leap;
		if (yday >= 365 + leap) yday -= 365 + leap;

		years = remyears + 4 * q_cycles + 100 * c_cycles + 400 * qc_cycles;

		for (months = 0; days_in_month[months] <= remdays; months++)
			remdays -= days_in_month[months];

		years += 2000;
		months += 3;//1 = January
		if (months > 12) {
			months -= 12;
			years++;
		}
		function zeroPad(num: number, places: number) {
			let zero = places - num.toString().length + 1;
			return Array(+(zero > 0 && zero)).join("0") + num;
		}

		function getHourFromSeconds(timeInSeconds: number) {
			let hours = Math.floor(timeInSeconds / 3600);
			let minutes = Math.floor((timeInSeconds % 3600) / 60);
			let period;
			if (hours >= 12 && hours != 24) {

				if (hours != 12)
					hours -= 12;
				period = 'PM';
			} else {

				if (hours == 0 || hours == 24)
					hours = 12;
				period = 'AM';
			}

			return {
				'hours': hours,
				'minutes': minutes,
				'period': period
			};
		}

		let daysText = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let monthsText = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		let str;
		switch (format) {
			case "date":
				str = zeroPad(months, 2) + "/" + zeroPad(remdays + 1, 2) + "/" + years.toString();
				break;
			case "LCDprojection":
				let time = getHourFromSeconds(remsecs);
				str = zeroPad(time.hours, 2) + ":" + zeroPad(time.minutes, 2) + ' ' + time.period + ' - ' + daysText[wday] + ' ' + monthsText[months - 1] + '/' + (remdays + 1);
				break;
			case "12h":
				let hours: any = Math.floor(remsecs / 3600);
				let amPM = 'PM';
				if (hours < 12) {
					amPM = 'AM';
					if (hours == 0) {
						hours = 12;
					}
				} else {
					hours -= 12;
				}
				hours = zeroPad(hours, 2);
				str = zeroPad(months, 2) + "/" + zeroPad(remdays + 1, 2) + "/" + years.toString() + " " + hours + ":" + zeroPad(Math.floor(remsecs / 60 % 60), 2) + ":" + zeroPad(Math.floor(remsecs % 60), 2) + " " + amPM;
				break;
			default:
				str = zeroPad(months, 2) + "/" + zeroPad(remdays + 1, 2) + "/" + years.toString() + " " + zeroPad(Math.floor(remsecs / 3600), 2) + ":" + zeroPad(Math.floor(remsecs / 60 % 60), 2) + ":" + zeroPad(Math.floor(remsecs % 60), 2);
				break;
		}
		return str;
	}
	getZoneTimestampFromUTC(zoneId: number, unixTimestamp: number) {

		const zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp + zone.base_utc);

		//DaylightSaving
		for (let i = 0; i < 50; i++) {

			if (unixTimestamp >= zone.changes_time[i])
				unixTimestamp = (unixTimestamp + ((i % 2 == 0) ? 1 : -1) * zone.changes_value);
		}

		return unixTimestamp;
	}

	getUTCTimestampFromZone(zoneId: number, unixTimestamp: number) {
		var zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp - zone.base_utc);

		for (let i = 0; i < 50; i++) {

			if (unixTimestamp >= zone.changes_time[i])
				unixTimestamp = (unixTimestamp + ((i % 2 == 0) ? -1 : 1) * zone.changes_value);
		}

		return unixTimestamp;
	}

	getUpdatedValues(newValues: any, originalData: any, temp: any = {}) {
		for (let [key, value] of Object.entries(originalData)) {
			if (!!value && typeof (value) === 'object') {
				if (moment.isDate(newValues[key])) {
					if (!moment(value).isSame(newValues[key])) {
						temp[key] = moment(newValues[key]).format('MM/DD/yyyy hh:mm:ss');
					}
				} else {
					temp[key] = {};
					this.getUpdatedValues(newValues[key], originalData[key], temp[key]);
					if (JSON.stringify(temp[key]) === JSON.stringify({})) {
						delete temp[key];
					}
				}
				continue;
			}
			if (value !== newValues[key]) {
				temp[key] = newValues[key];
			}
		}
		return temp;
	}

	getDomainName() {
		const angularRoute = '#' + this.location.path();
		const url = window.location.href;
		const domain = url.replace(angularRoute, '');
		return domain;
	}

	roundFloat(number: number, floatDigits = 0) {
		return Math.round(number * Math.pow(10, floatDigits)) / Math.pow(10, floatDigits);
	}

	extractKeysFromObjects(keysArr: string[], objectsArr: any[], unique?: boolean, integers?: boolean) {
		const items: any[] = [];
		const itemsValues: any[] = [];
		objectsArr.forEach(function (obj) {
			const tempObject: any = {};
			keysArr.forEach(function (key) {
				let val = obj[key];
				if (integers)
					val = +obj[key];

				if (val || val == 0) {
					if (unique) {
						if (itemsValues.indexOf(val) == -1)
							itemsValues.push(val);
					} else {
						itemsValues.push(val);
					}
					tempObject[key] = val;
				}
			});
			items.push(tempObject);
		});
		return items;

	}

	revertPermissionValue(permissionsObj: any) {
		let mapper: any = {
			0: "noAccess",
			1: "read",
			2: "write",
		};
		let res: any = {};
		for (let key in permissionsObj) {
			// if (['rt', 'events', 'quick_view', 'mobile_master', 'mobile_network', 'online_network', 'router_network'].includes(key))
			// 	res[key] = this.revertPermissionValue(permissionsObj[key]);
			// else
			res[key] = mapper[permissionsObj[key]];
		}
		return res;
	}

	getTooltipKey(area: string, userHasNOCaccess: boolean) {
		let key;
		if (userHasNOCaccess) {
			let tempKey = area;
			tempKey += '_noc';
			if (this.translateService.instant('tooltip.' + tempKey) != 'tooltip.' + tempKey)
				key = tempKey;
		}
		if (!key) {
			if (this.translateService.instant('tooltip.' + area) != 'tooltip.' + area)
				key = area;
		}
		return key;
	}

	getDropDownListData(type: any, options: any = {}) {
		let DayOfWeek: any = {
			'Sunday': 0,
			'Monday': 1,
			'Tuesday': 2,
			'Wednesday': 3,
			'Thursday': 4,
			'Friday': 5,
			'Saturday': 6
		};
		let dataList = [];
		let i, j;
		switch (type) {
			case "number":
				let num = options.start;
				for (i = 0; i <= (options.end - options.start) / options.step; i++) {
					dataList.push(num);
					num += options.step;
				}
				break;
			case "clock":
				let hour, minute: any = "";
				for (j = options.start; j <= options.end; j++) {
					hour = j;
					if (j < 10) {
						hour = "0" + j;
					}
					for (i = 0; i < 60 / options.step; i++) {

						if (j == options.start && options.ignoreFirst) {
							options.ignoreFirst--;
							continue;
						}

						minute = i * options.step;
						if (minute < 10) {
							minute = "0" + minute;
						}
						dataList.push(hour + ":" + minute);
						if (j == options.end) {
							break;
						}
					}
				}
				break;
			case "week":
				for (let day in DayOfWeek) {
					if (options && options.days && options.days.indexOf(day) == -1)
						continue;
					dataList.push({ id: DayOfWeek[day], text: day });
				}
				break;
		}
		return dataList;
	}

	arrayCompare(arr1: any[], arr2: any[]) {
		return arr1.length == arr2.length && difference(arr1, arr2).length == 0;
	}

	compare = (a: number | string, b: number | string, isAsc: boolean) => {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getRemainingPeriod(date: moment.MomentInput, span: moment.DurationInputArg1) {
		const expiry = moment(date).add(span, 'years');
		const years = expiry.diff(moment(), 'years');
		expiry.subtract(years, 'years');
		const months = expiry.diff(moment(), 'months');
		expiry.subtract(months, 'months');
		const days = expiry.diff(moment(), 'days');
		const vals = [];
		if (years > 0)
			vals.push(years + ' ' + this.translateService.instant('g.years_value'));
		if (months > 0)
			vals.push(months + ' ' + this.translateService.instant('g.months_value'));
		if (days > 0)
			vals.push(days + ' ' + this.translateService.instant('g.days_value'));
		return vals.join(', ');
	}

	secondsToElapsedTime(disconnectTime: any, options?: any) {
		options = options || {};
		const timespanObj: any = this.convertHourToSeconds(disconnectTime, 0, true, 0, options);

		const timespanTextArr = [];
		let timespanText = "";
		if (timespanObj['day'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['day'], "day"));
		if (timespanObj['hour'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['hour'], "hour"));

		if (timespanTextArr.length > 0)
			timespanText = timespanTextArr.join(" and ");
		else if (timespanObj['min'] > 0)
			timespanText = this.singularOrPlural(timespanObj['min'], "min");

		return timespanText;
	}

	convertHourToSeconds(hour: number, min: number, viseVersa = false, sec = 0, options?: any) {
		options = options || {};
		let seconds: number = 0;
		if (viseVersa) {
			seconds = +hour;
			let days = (options.hoursOnly) ? 0 : Math.floor(seconds / 86400);
			let hourTime = Math.floor((seconds - (days * 24 * 3600)) / 3600);
			let minTime = Math.floor((seconds - (hourTime * 3600) - (days * 24 * 3600)) / 60);
			return ({ day: days, hour: hourTime, min: minTime });
		} else {
			//Converts hour point in time to seconds passed
			//Ex: 01:55 -> 1 * 3600 + 55 * 60 = 6900 seconds
			let hourSeconds = hour * 3600;
			let minSeconds = min * 60;
			seconds = (+sec) || 0;
			return hourSeconds + minSeconds + seconds;
		}
	}

	singularOrPlural(val: any, text: string, concat: any = null) {
		concat = concat == null ? true : false;
		if (val == 1) {
			return concat ? val + ' ' + text : text;
		}
		return concat ? val + ' ' + text + 's' : text + 's';
	}

	getZoneName(zoneId: number, defaults = 0): string {
		let zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone) {
			if (!defaults)
				defaults = 14;
			zone = this.commonData.TimeZonesMenu[defaults];
		}
		return zone.display_name;
	}

	fahToCel(val: number, reverse = false) {

		if (reverse)
			return Math.round(val * 1.8 + 32);

		return parseFloat(((val - 32) / 1.8).toFixed(1));
	}

	timeFormat(input: any, options: {
		timeSystem?: number,
		limitToOneDay?: boolean,
		withNumberOfDays?: boolean,
		format?: string
	} = {
			timeSystem: 24,
			limitToOneDay: false,
			withNumberOfDays: false
		}) {
		let format: string = options.format || 'HH:mm';
		const timeSystem = options.timeSystem || 24;
		const limitToOneDay = options.limitToOneDay || false;
		if (limitToOneDay && input >= (1 * 24 * 60 * 60)) {
			if (format == 'HH:mm:ss')
				return '24:00:00';
			return '24:00';
		}

		if (timeSystem == 12) {
			format = `${format.toLowerCase()} A`;
		}

		if (options.withNumberOfDays) {
			format = 'DD '.concat(format);
		}

		return moment("2021-01-01").startOf('day').seconds(input).format(format);
	}

	exportTableToCsv(columnNames: any, elements: any, fileName: string) {
		let alphabits = this.alphabetCharacters;
		let CSVobj = [];

		let columnNamesObj: any = {};
		let headerObj: any = {};

		columnNames.forEach((column: any, colIdx: number) => {
			columnNamesObj[column.key] = column;
			let colId = alphabits[colIdx];
			columnNamesObj[column.key].colId = colId;

			if (column.key != 'options')
				headerObj[colId] = this.translateService.instant(column.name);
		});

		CSVobj.push(headerObj);

		elements.forEach((element: any) => {

			let currRow: any = {};

			for (let key in columnNamesObj) {

				if (key == 'options')
					continue;

				let column = columnNamesObj[key]
				let value = element[column.key];

				switch (column.type) {
					case "json":
						value = JSON.stringify(value);
						break;

					case "dateTime":
						value = this.datePipe.transform(value, 'MM/dd/yyyy hh:mm:ss a');
						break;
				}

				if (column.key == 'serial_number')
					value = '*' + value;

				currRow[column.colId] = value;
			}

			CSVobj.push(currRow);
		});

		new AngularCsv(CSVobj, fileName);
	}

	getDaysInQuarter(year: number, quarter: number) {
		return moment.utc(year, 'YYYY').quarter(quarter).endOf('quarter').diff(
			moment.utc(year, 'YYYY').quarter(quarter).startOf('quarter'), 'days'
		) + 1;
	}

	getDaysInMonth(year: number, month: number) {
		month -= 1;
		return moment.utc(year, 'YYYY').month(month).endOf('month').diff(
			moment.utc(year, 'YYYY').month(month).startOf('month'), 'days'
		) + 1;
	}

	getCleanFileName(name: string) {
		return name.replace(/[/\\?%*:|"<>]/g, '_');
	}

	ip2int(ip: string) {
		return (
			ip.split('.').reduce((ipInt, octet) => {
				return (ipInt << 8) + parseInt(octet, 10);
			}, 0) >>> 0 // to make it unsigned int
		);
	}

	int2ip(ipInt: number) {
		return (
			(ipInt >>> 24) +
			'.' +
			((ipInt >> 16) & 255) +
			'.' +
			((ipInt >> 8) & 255) +
			'.' +
			(ipInt & 255)
		);
	}

	durationFilter(filter: any, value: any, filterText: string) {
		if (!filterText)
			return true;

		const filterTextSplitted = /days/.test(filterText) ? filterText.split('days')[1].trim().split(':') : filterText.split(':');
		const days = /days/.test(filterText) ? +filterText.split('days')[0].trim() : 0;

		let filterValue = this.convertHourToSeconds((+filterTextSplitted[0]) + (days * 24), +filterTextSplitted[1] || 0, false, +filterTextSplitted[2] || 0);
		let returnVal = false;
		let rowValue = value - (value % 60); // exception of seconds from compare function.
		switch (filter) {
			case 'lessThan':
				returnVal = rowValue < filterValue;
				break;
			case 'greaterThan':
				returnVal = rowValue > filterValue || value > filterValue;
				break;
			default:
				returnVal = rowValue == filterValue || filterValue == value;
				break;
		}
		return returnVal;
	}

	numberAndStringFilter(filter: any, value: any, filterNumber: any) {
		let returnVal = false;

		switch (filter) {
			case 'lessThan':
				returnVal = +value < +filterNumber;
				break;
			case 'greaterThan':
				returnVal = +value > +filterNumber || +value > +filterNumber;
				break;
			default:
				returnVal = +value == +filterNumber || +filterNumber == +value;
				break;
		}

		return returnVal;
	}

	formatDuration(hours: number, withDays = false) {
		if (!hours && withDays) {
			return '0 days 00:00:00';
		} else if (!hours) {
			return '00:00:00';
		}
		let format = '';
		if (hours >= 24 && !withDays) hours = 24.00;
		if (withDays) {
			const days = Math.floor(hours / 24);
			hours -= (days * 24)
			format = days > 0 ? (days >= 10 ? days.toString() : '0' + days) + ' days ' : '0 days ';
		}
		const h = Math.floor(hours);
		const durationSec = hours * 3600;
		const m = Math.floor((durationSec % 3600) / 60);
		const s = Math.floor((durationSec % 3600) % 60);

		format += `${h > 10 ? h : `0${h}`}:${m > 10 ? m : `0${m}`}:${s > 10 ? s : `0${s}`}`
		return format;
	}

	exportToCsv(columnNames: string[], data: any, fileName: string) {
		let alphabits = this.alphabetCharacters;

		let headerObj: any = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];

		let colLength = Object.keys(headerObj).length;
		let dataObj = header.slice();

		data = data.slice();
		data.forEach((column: any) => {
			let vals: any = {};
			column.forEach((col: any, idx: any) => {
				if (idx >= colLength)
					return;
				let colId = alphabits[idx];
				vals[colId] = col;
			});
			dataObj.push(vals);
		});
		new AngularCsv(dataObj, fileName);
	}

	getFormattedDuration(durationSec: number, oneDay: boolean = false) {
		durationSec = Number(durationSec);
		const DD = Math.floor(durationSec / (24 * 60 * 60));
		durationSec -= DD * (24 * 60 * 60);
		const h = Math.floor(durationSec / 3600);
		const m = Math.floor((durationSec % 3600) / 60);
		const s = Math.floor((durationSec % 3600) % 60);
		if (oneDay)
			return h + 'H ' + m + 'M ' + s + 'S';
		return (DD > 0 ? DD + 'D ' : '') + h + 'H ' + m + 'M ' + s + 'S';
	}

	nowTime() {
		return moment().utc().unix();
	}
	getFormattedTimeByZone(time: number, zoneId: number, format = 'MM/dd/yyyy hh:mm:ss a') {
		const date = this.getDateFormattedFromUnixTimeStamp(this.getZoneTimestampFromUTC(zoneId, time));
		return this.datePipe.transform(date, format);
	}

	getUnixTimeByZone(zoneId: number, timestamp: number) {
		return moment(this.getFormattedTimeByZone(timestamp, zoneId)).unix();
	}

	getUnixTimezoneByLocaltime(timestamp: number) {
		return Math.floor(new Date().getTimezoneOffset() * 60 + timestamp);
	}

	getTimeZoneShortName(index: number) {
		const zone = this.getZoneName(index);
		const zoneName = zone.match(/\(UTC([-+]?\d{2}:\d{2})?\)/);
		if (!zoneName)
			return;
		return zoneName[0];
	}

	getTimeRegardlessTimeZone(timestamp: number) {
		return (moment(timestamp).startOf('day').unix()) - (new Date().getTimezoneOffset() * 60);
	}

	getDateInLocalTime(date: Date) {
		return new Date((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + (Math.abs(date.getTimezoneOffset()) / 60) + ':00:00');
	}

	convertSecondsToHoursMinsSecs(totalSeconds: number) {
		if (!totalSeconds)
			return `00:00:00`;

		const totalMinutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		return `${hours > 10 ? hours : `0${hours}`}:${minutes > 10 ? minutes : `0${minutes}`}:${seconds > 10 ? seconds : `0${seconds}`}`;
	}

	sortDataAlphabetically(data: any, fieldName: string) {
		return data.sort((item1: any, item2: any) => item1[fieldName].toLowerCase() > item2[fieldName].toLowerCase() ? 1 : -1);
	}
}
