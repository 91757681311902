import { SctToastService } from '../services/sct-toast.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../../business/sites/tags-management/tags.service';
import { Tag } from './tag.model';
import { SCTDialog, SCTDialogAction } from '@app/shared/sct-dialog/sct-dialog.model';
import { keyBy } from 'lodash';

@Component({
	selector: 'app-tag-input',
	templateUrl: './tag-input.component.html',
	styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {
	@Input() addTag: boolean = true;
	@Input() isMultiple: boolean = true;
	@Input() addToSite: boolean = false;
	@Input() hideSelected: boolean = false;
	@Input() isSCTuser: boolean = false;
	@Input() items: any[] | any = [];
	@Input() deviceId: any = 0;
	@Input() siteId: number = 0;
	@Input() tagInput: any;
	@Input() placeholder: string = this.translateService.instant('tags.add_a_tag');
	@Output() onUpdateTags = new EventEmitter<Object>(true);
	@Output() onAddTagFault = new EventEmitter<Object>(true);

	tag: Tag[] | null | undefined = null;
	tagText: string = '';
	tagSharingOptions!: { id: string, text: string }[];
	tempTag: Tag = { tag_name: null };
	deviceTagsInfo: any = {};
	title: string = '';
	showSharingOption: boolean = true;
	keepInReplacement: boolean = true;
	maxLimitMsgTimer: number = 0;
	constants: any = {
		maxTermLength: 30,
		maxLimitMessageTimeout: 5
	};
	newTagShareOption: any = this.tagsService.shareOptions.public;
	@Input() isFromDialog = false;

	addTagDialog: SCTDialog = {
		header: this.translateService.instant('tags.add_tags'),
		width: '25vw',
		callback: () => {
			this.dialog.visible = false;
		}
	};

	dialog: SCTDialog = {
		header: this.title,
		hideFooter: true,
		width: '25vw',
		callback: (action: SCTDialogAction) => {
			this.dialog.visible = false;
			if (action === SCTDialogAction.CANCEL)
				return;

			if (this.tempTag.sharing_option)
				return this.addTagFunction();

			if (this.isMultiple && this.tag)
				this.tag = this.tag.slice(0, this.tag.length - 1); // remove only last selected tag
			else
				this.tag = null;
		}
	};
	availableShareOptions: any[] = [];

	constructor(
		private notificationMessage: SctToastService,
		public tagsService: TagsService,
		private translateService: TranslateService
	) {
		this.title = translateService.instant('g.settings');
	}

	ngOnInit() {
		if (!this.isMultiple)
			this.tag = null;

		this.getTagSharingOptions();

		const availableShareOptions = [this.tagsService.shareOptions.public, this.tagsService.shareOptions.private];
		if (this.isSCTuser)
			availableShareOptions.push(this.tagsService.shareOptions.group);

		this.availableShareOptions = availableShareOptions;

		if (this.addToSite)
			this.title = this.translateService.instant('tags.sharing_options');
		this.dialog.header = this.title;

		//if (changes.tagInput && changes.tagInput.currentValue && Array.isArray(changes.tagInput.currentValue)) {
		this.deviceTagsInfo = keyBy(this.tagInput, 'tag_id');

		if (Object.keys(this.items).length)
			this.updateTagsObj(keyBy(this.items, 'id'));
		this.items = Object.values(this.items);
	}

	ngOnChanges(changes: any) {
		if (changes.tagInput && !changes.tagInput.previousValue) {
			this.tagInput = changes.tagInput.currentValue;
			if (Array.isArray(this.items))
				this.updateTagsObj(keyBy(this.items, 'id'));
			else {
				this.updateTagsObj(keyBy(this.items, 'id'));
			}
		}
		if (changes.items && changes.items.currentValue) {
			if (!Array.isArray(changes.items.currentValue))
				this.items = Object.values(changes.items.currentValue)
		}


	}

	private updateTagsObj(items: any) {
		const tags: any[] = [];
		this.tagInput.forEach((item: any) => {
			tags.push(Object.assign(items[item.tag_id] || {}, item));
		});
		this.tag = tags;
	}

	change(tags: any) {
		if (this.isMultiple)
			return;

		const tag = tags.value.find((tag: any) => tag.id === tags.itemValue.id);
		if (tag)
			this.add(tag);
		else
			this.removeTag(tags.itemValue.id)
	}

	add(tag: any) {
		if (typeof tag == 'string') {
			this.tempTag = { tag_name: tag }
			for (let item of this.items) {
				if (item.tag_name == tag) {
					this.tempTag = item;
					break;
				}
			}
		} else
			this.tempTag = tag;

		this.showSharingOption = this.addTag && (!this.tempTag || !this.tempTag.sharing_option);

		this.tempTag.tag_name = this.tempTag.tag_name?.trim();
		if (this.tempTag && this.tempTag.tag_name && this.tempTag.tag_name?.length < 3) {
			this.tag = this.tag?.slice(0, this.tag.length - 1);
			this.notificationMessage.showMessage('translate|tags.invalid_tag_name');
			this.onAddTagFault.emit({ tag: tag });
			return;
		}
		const showKeepInReplacement = this.isSCTuser && !this.addToSite;
		if (this.showSharingOption || showKeepInReplacement) {
			this.keepInReplacement = false;
			this.dialog.header = this.title;
			this.dialog.visible = true;
		} else {
			this.addTagFunction();
		}
	}

	addTagFunction() {
		this.tempTag.keep_in_replacement = this.keepInReplacement;
		if (this.addToSite) {
			this.tagsService.addTags(this.siteId, this.tempTag).subscribe((data: any) => {
				if (data.api_status) {
					let errMsg = 'globalErrMsg';
					switch (data.api_status) {
						case 2:
							errMsg = 'translate|tags.duplicate_tag';
							break;
					}
					return this.notificationMessage.showMessage(errMsg);
				}
				this.tag = null;
				this.onUpdateTags.emit({ type: 'add', tag: data });
				this.notificationMessage.showMessage('globalSuccessMsg');
			});
		} else {
			this.tagsService.addDeviceTag(this.siteId, this.deviceId, this.tempTag).subscribe(
				(res: any) => {
					if (res.httpStatus == 'error') {
						this.notificationMessage.showMessage(res.msg);
						this.tag = this.tag?.slice(0, this.tag.length - 1);
						this.items = this.items.slice(0, this.items.length - 1);
					} else {
						res.id = +res.id;
						let newTagIdx = this.tag ? this.tag.length - 1 : 0;
						this.tag = this.tag || [];

						this.tag[newTagIdx] = this.tempTag;
						this.tag[newTagIdx].id = res.id;
						if (res.isNewTag)
							this.items.push(this.tag[newTagIdx]);
						this.notificationMessage.showMessage('globalSuccessMsg');
						this.onUpdateTags.emit({ type: 'add', tag: res });
					}
				},
				() => {
					this.onAddTagFault.emit({ tag: this.tempTag });
				}
			);
		}
	}

	checkReplacementTag(tag: any) {
		if (!this.isSCTuser)
			return false;

		if (tag.keep_in_replacement || (this.deviceTagsInfo[tag.id] && this.deviceTagsInfo[tag.id].keep_in_replacement))
			return true;

		return false;
	}

	removeTag(event: any) {
		let removedTag = this.items.filter((tag: any) => tag.id == +event.value.id)
		this.tagsService.removeDeviceTag(this.siteId, this.deviceId, removedTag[0]).subscribe(
			() => {
				this.notificationMessage.showMessage('globalSuccessMsg');
				this.onUpdateTags.emit({ type: 'remove', tag: removedTag[0] });
			}
		);
	}

	checkAddTag = (term: any) => {
		return new Promise((resolve) => {
			if (!this.addTag)
				return resolve(null);

			if (term.length <= this.tagsService.constants.maxTermLength) {
				this.maxLimitMsgTimer = 0;
				return resolve({ tag_name: term });
			}
			this.maxLimitMsgTimer = this.tagsService.constants.maxLimitMessageTimeout;
			let interval = setInterval(() => {
				if (this.maxLimitMsgTimer > 0)
					this.maxLimitMsgTimer--;
				else
					clearInterval(interval);
			}, 1000);

			return resolve(null);
		});
	}
	private getTagSharingOptions() {
		this.tagSharingOptions = [
			{ id: this.tagsService.shareOptions.public, text: this.translateService.instant('tags.public') },
			{ id: this.tagsService.shareOptions.private, text: this.translateService.instant('tags.private') }
		];
		if (this.isSCTuser)
			this.tagSharingOptions.push({ id: this.tagsService.shareOptions.group, text: this.translateService.instant('tags.group') });
	};
}