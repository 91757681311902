<nav class="navbar navbar-expand-lg navbar-light business-header-content">
	<div class="container-fluid border-bottom">
		<div class="navbar-brand brand-logo">
			<a class="logo" routerLink="/"> </a>
		</div>

		<div class="navbar-nav ml-auto mr-3">
			<ng-container *ngIf="user && user.full_name">
				<li class="nav-item dropdown user-header-dropdwon">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						{{ (user.full_name?.length > 14) ? (user.full_name | slice:0:14)+'.' : (user.full_name) }}
					</a>
					<ul class="dropdown-menu " aria-labelledby="navbarDropdown">
						<li>
							<a class="dropdown-item nav-link" (click)="usersService.logout()">
								{{'g.logout' | translate}}</a>
						</li>
					</ul>
				</li>
			</ng-container>
			<a *ngIf="!user" class="nav-link pl-1" routerLink="/login">{{'login_register.log_in' | translate}}</a>
		</div>
	</div>
</nav>