<!-- Quarter Usage Data -->
<div class="white-box main-black mt-4 p-4 border-radius">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.'+(logicType ? 'monthly_data' : 'quarters_data') | translate}}
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}"
					(click)="logicType ? exportMonthlyUsageData() : exportQuarterlyUsageData()"
					*ngIf="chartDataExists.quarters"></button>
			</h4>
		</div>
	</div>
	<div class="mt-4 d-flex justify-content-start flex-wrap">
		<div>
			<label class="me-2">{{'g.select_year' | translate}}</label>
			<p-dropdown [(ngModel)]="selectedYearUsageReport"
				(onChange)="logicType ? updateMonthlyUsageData() : updateQuarterlyUsageData()"
				[options]="yearsOption"></p-dropdown>
		</div>
	</div>
	<div class="container" *ngIf="chartDataExists.quarters; else noData">
		<div class="row py-4 justify-content-md-evenly">
			<div class="col-{{logicType ? '12' : '6'}} mb-3 text-center">
				<h5>{{'device.billed_kwhrs' | translate}}</h5>
				<p class="unit">{{'device.units.kwh' | translate}}</p>
				<p-chart type="bar" height="400"
					[data]="logicType ? monthlyUsageDataChart?.billed_kwhrs : quarterlyUsageDataChart?.billed_kwhrs"
					[options]="optionsObject"></p-chart>
			</div>
			<div class="col-{{logicType ? '12' : '6'}} mb-3 text-center">
				<h5>{{'device.daily_billed_kwhrs' | translate}}</h5>
				<p class="unit">{{'device.units.kwh' | translate}}</p>
				<p-chart type="bar" height="400"
					[data]="logicType ? monthlyUsageDataChart?.daily_billed_kwhrs : quarterlyUsageDataChart?.daily_billed_kwhrs"
					[options]="optionsObject"></p-chart>
			</div>
		</div>
		<div class="row py-4 justify-content-md-evenly">
			<div class="col-{{logicType ? '12' : '6'}} mb-3 text-center">
				<h5>{{'device.total_usage_time' | translate}}</h5>
				<p class="unit">{{'device.units.min' | translate}}</p>
				<p-chart type="bar" height="400"
					[data]="logicType ? monthlyUsageDataChart?.total_usage_time : quarterlyUsageDataChart?.total_usage_time"
					[options]="usageTimeOptionsObject"></p-chart>
			</div>
			<div class="col-{{logicType ? '12' : '6'}} mb-3 text-center">
				<h5>{{'device.peak_power' | translate}}</h5>
				<p class="unit">{{'device.units.kw' | translate}}</p>
				<p-chart type="bar" height="400"
					[data]="logicType ? monthlyUsageDataChart?.peak_power : quarterlyUsageDataChart?.peak_power"
					[options]="optionsObject"></p-chart>
			</div>
		</div>
	</div>
</div>

<!-- Usage Data -->
<div class="white-box main-black mt-4 p-4 border-radius">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.usage_data' | translate}}
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportUsageData()"
					*ngIf="!usageDataIsEmpty"></button>
			</h4>
		</div>
	</div>
	<div class="w-100">
		<sct-date-range [minDate]="minDateSelections" [dateRange]="usageDataChartFilters"
			(onDatesChanged)="updateUsageData($event)"></sct-date-range>
	</div>
	<div class="container" *ngIf="!usageDataIsEmpty; else noData">
		<div class="row">
			<div class="col-6 mb-3" *ngFor="let item of usageData">
				<div class="card">
					<div class="card-body main-bg">
						<div *ngIf="item?.length">
							<div class="row">
								<div class="col-6">
									<h4 class="card-title text-center">
										{{ item[0].value }} {{ item[0].unit }}
									</h4>
									<p class="card-text text-center"> {{ item[0].label }} </p>
								</div>
								<div class="col-6">
									<h4 class="card-title text-center">
										{{ item[1].value }} {{ item[1].unit }}
									</h4>
									<p class="card-text text-center"> {{ item[1].label }} </p>
								</div>
							</div>
						</div>
						<div *ngIf="!item?.length">
							<h4 class="card-title text-center">
								{{ item.value }} {{ item.unit }}
							</h4>
							<p class="card-text text-center"> {{ item.label }} </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Peak Power -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="true">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.peak_power' | translate}}
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportPeakPower()"
					*ngIf="peakPowerExport.length"></button>
			</h4>
		</div>
	</div>
	<div class="w-100">
		<sct-date-range [minDate]="minDateSelections" [dateRange]="peakPowerChartFilters"
			(onDatesChanged)="updatePeakPower($event)"></sct-date-range>
	</div>
	<div class="row py-4" *ngIf="peakPowerExport.length; else noData">
		<p-chart type="bar" [data]="peakPowerChart" [options]="{ legend: { display: false } }"
			(onDataSelect)="onPeakPowerDataClick($event)"></p-chart>
	</div>
</div>

<!-- Energy Usage -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="true">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.energy_usage' | translate}}
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportEnergyUsage()"
					*ngIf="energyUsageIsNotEmpty"></button>
			</h4>
		</div>
	</div>
	<div class="w-100">
		<sct-date-range [minDate]="minDateSelections" [dateRange]="energyUsageChartFilters"
			(onDatesChanged)="updateEnergyUsage($event)"></sct-date-range>
	</div>
	<div class="row py-4" *ngIf="energyUsageIsNotEmpty; else noData">
		<p-chart type="line" [data]="energyUsageChart" [options]="{ legend: { display: false } }"></p-chart>
	</div>
</div>

<!-- Performance Summary -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="true">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.performance_summary' | translate}}
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}"
					(click)="exportPerformanceSummary()" *ngIf="performanceSummaryExport.length"></button>
			</h4>
		</div>
	</div>
	<div class="w-100">
		<sct-date-range [minDate]="minDateSelections" [dateRange]="performanceSummaryChartFilters"
			(onDatesChanged)="updatePerformanceSummary($event)"></sct-date-range>
	</div>
	<div class="row py-4" *ngIf="performanceSummaryExport.length; else noData">
		<p-chart type="bar" [data]="performanceSummaryChart" (onDataSelect)="onPerformanceSummaryClick($event)"
			[options]="{ scales: { yAxes: [{ ticks: { stepSize: 1, min: 0 } }] } }">
		</p-chart>
	</div>
</div>

<ng-template #noData>
	{{'g.no_data_available_for_the_selected_period' | translate}}
</ng-template>

<app-sct-dialog [dialog]="eventDialog">
	<div dialog-content>
		<p-table [value]="[eventDialog.data]">
			<ng-template pTemplate="header">
				<tr>
					<th>{{'events.sequence_id' | translate}}</th>
					<th>{{'g.time' | translate}}</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-event>
				<tr>
					<td>
						<a class="blue-hover pointer ml-1" [routerLink]="['../events']"
							target="_blank">{{event?.event_id}}</a>
					</td>
					<td>{{(event?.time * 1000) | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="glitchDialog">
	<div dialog-content>
		<p-table [value]="glitchDialog.data">
			<ng-template pTemplate="header">
				<tr>
					<th>{{'glitches.sequence_id' | translate}}</th>
					<th>{{'g.time' | translate}}</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-event>
				<tr>
					<td>
						<a class="blue-hover pointer ml-1" [routerLink]="['../glitches']"
							target="_blank">{{event?.event_id}}</a>
					</td>
					<td>{{(event?.time * 1000) | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</app-sct-dialog>