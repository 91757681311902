<div>
	<nav class="navbar navbar-expand-lg navbar-light business-header-content h-100" style="border-bottom: 1px solid #CDD9E6;">
		<div class="container-fluid h-100 p-0" >
			<div class="navbar-brand brand-logo">
				<a class="logo ms-3" routerLink="/"> </a>
			</div>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse align-items-center" id="navbarSupportedContent">
				<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
	
					<li class="nav-item dropdown user-header-dropdwon">
						<a class="btn" [routerLink]="['/login']">
							{{'login_register.log_in' | translate}}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
	<i class="fa fa-check col-md-12 mt-5 d-flex justify-content-around " style="color: #004999;font-size: 8em;" aria-hidden="true"></i>
	
	
	<div class="d-flex align-items-center justify-content-center">
		<div class="jumbotron text-center">
			<h1 class="display-4">Thank You!</h1>
			<strong class="text-left">{{'g.user_registered' | translate}}</strong>
		
			<hr>
			<p class="lead">
				<button type="button" class="col-12 col-md-5 ms-2 btn main-blue-bg" (click)="goLogin()">Go to login</button>
		
			</p>
		</div>
	</div>

</div>
