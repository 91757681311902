import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '@app/business/users/users.service';
import { InputType, SCTFormBuilder, SCTFormBuilderField } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { SctToastService } from '@app/shared/services/sct-toast.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {

	user: any = {};
	formFields: SCTFormBuilderField[] = [];
	form!: SCTFormBuilder;
	invalidFields: any = {};

	readonly formName: string = 'registerRequest';

	@ViewChild('registerRequestForm') registerRequestForm: NgForm | any;
	updateCaptcha: EventEmitter<void> = new EventEmitter();
	triesLimitReachedCaptcha = 0;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private translateService: TranslateService,
		public sctToastService: SctToastService
	) { }

	ngOnInit(): void {
		if (this.usersService.isLoggedIn()) {
			this.router.navigate(['/']);
		}

		this.prepareFormFields();
	}

	prepareFormFields() {
		this.formFields = [
			{
				name: 'first_name',
				label: 'login_register.first_name',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				name: 'last_name',
				label: 'login_register.last_name',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				name: 'email',
				label: 'login_register.email',
				type: InputType.TEXT,
				isRequired: true,
				sctFormOptions: {
					trimText: true
				}
			},
			{
				name: 'phone_number',
				label: 'login_register.phone_no',
				type: InputType.PHONE_NUMBER,
				isRequired: true,
			},
			{
				name: 'company_name',
				label: 'login_register.company_name',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				name: 'job_role',
				label: 'login_register.job_role',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				type: InputType.LINK,
				routerLink: ['/login'],
				name: 'link',
				subLabel: this.translateService.instant('login_register.have_an_account'),
				labelLink: this.translateService.instant('login_register.login'),
				hideLabel: true,
				values: 'link',
			},
		];

		this.form = {
			fields: this.formFields,
			callback: (data: any) => this.registerRequest(data),
			captchaEnabled: true,
			captchaExpired: () => {
				this.sctToastService.showMessage('translate|login_register.verification_is_expired');
			},
			cancelButtonCss: 'btn-secondary'
		};
	}

	registerRequest(data: any) {

		this.usersService.registerRequest(data).subscribe((data: any) => {
			const response = data || {};
			if (this.form.updateCaptcha && response === 6) {
				++this.triesLimitReachedCaptcha;
				if (this.triesLimitReachedCaptcha < 3) {
					this.errorMessage('translate|g.invalid_captcha');
				}
				this.form.updateCaptcha();
			} else {
				switch (response) {
					case 1:
						// this.goBack();
						this.router.navigate(['/landing'], { state: { example: true } });
						break;
					case 2:
						this.errorMessage('translate|g.invalid_input');
						this.form.updateCaptcha && this.form.updateCaptcha();
						break;
					case 3:
						this.errorMessage('translate|users.email_used');
						this.highlightInvalidFields(['email']);
						this.form.updateCaptcha && this.form.updateCaptcha();
						break;
					case 4:
						this.errorMessage('translate|users.phone_used');
						this.highlightInvalidFields(['phone_number']);
						this.form.updateCaptcha && this.form.updateCaptcha();
						break;
					case 5:
						this.highlightInvalidFields(['email']);
						this.errorMessage('translate|users.public_mails');
						this.highlightInvalidFields(['email']);
						this.form.updateCaptcha && this.form.updateCaptcha();
						break;
				}
			}
		});
	}

	highlightInvalidFields(invalidFields: any[]) {
		this.invalidFields = {};
		invalidFields.forEach((field) => {
			this.invalidFields[field] = true;
		})
	}

	goBack() {
		this.router.navigate(['/login']);
	}

	errorMessage(error: string) {
		this.sctToastService.showMessage(error);
	}
}
