import { Router } from '@angular/router';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { CustomHttpParams } from '../shared/loader/custom-httpParam';
import fieldPermissionMapper from '../../../../../iot-emeter-common/permissions/fieldPermissionMapper.json';
import { PermissionType } from '@app/core/model/constants';
import { CommonService } from '@app/shared/services/common.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	route = 'user/';
	public permissionType = PermissionType;
	private permissionsOfUserSbj = new BehaviorSubject<Object>({});
	permissionsOfUser = this.permissionsOfUserSbj.asObservable();

	constructor(
		private gateway: GatewayService,
		private router: Router,
		private commonService: CommonService,
		private navigationService: NavigationService,
		private cookieService: CookieService
	) { }

	private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject({});
	public currentUser = this.currentUserSubject.asObservable();

	registerRequest(user: any): Observable<any> {
		return this.gateway.post('/registration-requests/create', { user });
	}

	getUsersInfo(userId: any): Observable<any> {
		// url -> user/:userId
		return this.gateway.post(this.route + 'getUsersInfo', { userId });
	}


	updateUser(userId: number, user: any, options: { isAdminEdit: boolean }): Observable<any> {
		return this.gateway.post(this.route + 'editUser', { userId, user, options });
	}

	addUser(user: any, options?: any): Observable<any> {
		return this.gateway.post(this.route, { user, options });
	}

	listUsers(criteria: any): Observable<any> {
		let params = new HttpParams();
		for (const search in criteria) {
			params = params.append(search, encodeURIComponent(criteria[search]));
		}

		return this.gateway.get(this.route + 'list', params);
	}

	deleteUser(userId: any): Observable<any> {
		return this.gateway.delete(this.route + userId);
	}

	deleteUserAccount(verificationPassword: string): Observable<any> {
		return this.gateway.post(this.route + 'deleteUserAccount', { verificationPassword });
	}

	login(user: any): Observable<any> {
		return this.gateway.post(this.route + 'login', user);
	}

	forgotPassword(user: any): Observable<any> {
		return this.gateway.post(this.route + 'forgotPassword', user);
	}

	logout() {
		this.gateway.post(this.route + 'logout', {}).pipe(map(res => {
			return true;
		})).subscribe((res) => {
			this.clearUserSessionCookie();
			this.navigationService.setPreviousUrl(null, true);
			this.setCurrentUser({});
			this.router.navigate(['/login']);
		});
	}

	clearUserSessionCookie() {
		this.cookieService.delete('userSession');
	}

	getAdminUsers(): Observable<any> {
		return this.gateway.get(this.route + 'admins');
	}

	setCurrentUser(user: any) {
		if (Object.keys(user).length > 0) {
			user.full_name = user.first_name + ' ' + user.last_name;
		}
		localStorage.setItem('userInfo', JSON.stringify(user));
		this.currentUserSubject.next(user);
	}

	getCurrentUser() {
		let info = this.currentUserSubject?.value;
		if (!info || !info.id) {
			const userInfo = localStorage.getItem('userInfo') || '';
			if (!userInfo) { return false; }
			info = JSON.parse(userInfo);

			if (info && info.id) {
				this.currentUserSubject.next(info);
			}
		}
		return info;
	}

	isLoggedIn() {
		const userInfo = localStorage.getItem('userInfo');
		if (!userInfo) { return false; }
		const user = JSON.parse(userInfo) || {};
		return !!(user && user.id);
	}

	getRegisterRequests(id?: string) {
		return this.gateway.get(`registration-requests/get${id ? '/' + id : ''}`);
	}

	approveRegisterRequests(id: string, data: any) {
		return this.gateway.patch(`registration-requests/approve/${id}`, data);
	}

	hasAccessFunction(accessFunction: string, level = 'read') {
		if (!this.currentUserSubject.value || !this.currentUserSubject.value.access_functions) {
			return false;
		}

		if (this.currentUserSubject.value.access_functions['super_admin'] == 'write')
			return true;

		const accessValue = this.currentUserSubject.value.access_functions[accessFunction];

		if (!accessValue) {
			return false;
		}

		if (level == 'read') {
			return accessValue != 'noAccess';
		}
		return accessValue == level;
	}

	getUserAuthData() {
		const userInfo = localStorage.getItem('userInfo');
		let user: any = {};
		const session = document.cookie;
		if (userInfo) {
			user = JSON.parse(userInfo);
			if (!(session && session.includes('userSession'))) {
				this.setCurrentUser({});
				user = {};
			}
		}

		return {
			logged_in: !!(user && user.id),
			password_expired: (user && user.force_password_change),
			terms_agreement: (user && !user.terms_confirmed)
		};
	}

	getFieldPermission(fieldName: string) {
		const mapperObj: any = Object.assign({}, fieldPermissionMapper);
		let parentObject = mapperObj[fieldName];
		if (fieldName.indexOf('.') != -1) {
			const names = fieldName.split('.');
			parentObject = this.commonService.getDeepObject(names, mapperObj);
		}
		return parentObject?.key;
	}

	checkFieldPermissions(fieldName: string, permissions: any) {
		const mapperObj: any = Object.assign({}, fieldPermissionMapper);
		let parentObject = mapperObj[fieldName];
		if (fieldName.indexOf('.') != -1) {
			const names = fieldName.split('.');
			parentObject = this.commonService.getDeepObject(names, mapperObj);
		}
		if (!parentObject) {
			return PermissionType.NO_ACCESS;
		}

		//Check access function instead of permissions
		if (parentObject.access_function) {
			return this.hasAccessFunction(parentObject.access_function) ? PermissionType.WRITE : PermissionType.NO_ACCESS;
		}

		let accessType = permissions[parentObject.key];
		if (parentObject.key == 'read' || accessType == 'read') {
			accessType = PermissionType.READ;
		} else if (parentObject.key == 'write' || accessType == 'write') {
			accessType = PermissionType.WRITE;
		} else if (parentObject.key == 'noAccess' || accessType == 'noAccess') {
			accessType = PermissionType.NO_ACCESS;
		}

		return accessType;
	}

	hasAccessPermission(enterprisePermissions: any, accessName: string, level = PermissionType.READ, userPermissions = null) {
		const checkUserPermissions = (permissionObj: any) => {
			if (!permissionObj || !permissionObj.permission)
				return false;

			return this.checkFieldPermissions(accessName, permissionObj.permission);
		};

		if (!enterprisePermissions || Object.keys(enterprisePermissions).length === 0) {
			if (this.currentUserSubject.value && this.currentUserSubject.value.permissions)
				enterprisePermissions = this.currentUserSubject.value.permissions;
		}
		let userAccessLevel = checkUserPermissions(enterprisePermissions);
		if (!userAccessLevel)
			return false;

		if (userPermissions) {
			let otherUserAccessLevel = checkUserPermissions(userPermissions);

			if (otherUserAccessLevel === PermissionType.WRITE && userAccessLevel !== PermissionType.WRITE)
				return false;
		}

		if (level == PermissionType.READ)
			return userAccessLevel !== PermissionType.NO_ACCESS;

		return userAccessLevel == level;
	}


	getUserPermission(siteID?: number, getMyPermission?: boolean) {
		return this.gateway.post(this.route + 'getUserPermission', { siteID, getMyPermission });
	}

	changeExpiredPassword(data: any): Observable<any> {
		return this.gateway.post(this.route + 'changeExpiredPassword', data);
	}

	userHasNOCAccess(enterprisePermissions: any = null) {
		return this.hasAccessPermission(enterprisePermissions, 'noc');
	}

	resetPassword(data: any): Observable<any> {
		return this.gateway.post(this.route + 'resetPassword', data);
	}

	confirmUserEmail(userId: number, enc: any) {
		return this.gateway.post(this.route + 'confirmUserEmail', { userId, enc });
	}

	confirmUserAgreement() {
		return this.gateway.post(this.route + 'confirmUserAgreement', {},);
	}

	getBecomeUserToken(userId: number) {
		return this.gateway.post(this.route + 'getBecomeUserToken', { userId });
	}

	becomeUser(token: string) {
		return this.gateway.post(this.route + 'becomeUser', { token });
	}

	logContactUs(form: any) {
		return this.gateway.post(this.route + 'logContactUs', form);
	}

	sendConfirmationEmail(userId: number) {
		return this.gateway.post(this.route + 'sendConfirmationEmail', { userId });
	}

	changeUserPassword(data: any): Observable<any> {
		return this.gateway.put(this.route + 'changeUserPassword', data);
	}

	enableFullAccessMode() {
		return this.gateway.post('/user/enableFullAccessMode', {});
	}

	updateCurrentUser(user: any) {
		if (Object.keys(user).length > 0) {
			let prevUserObj = JSON.parse(localStorage.getItem('userInfo') || '') || {};
			user = Object.assign(prevUserObj, user);
			this.setCurrentUser(user);
		}
	}

	setPermissionsOfUser(data: Object) {
		this.permissionsOfUserSbj.next(data);
	}
}
