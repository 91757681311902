import { SctToastService } from './../services/sct-toast.service';
import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { DropDownFilter } from './drop-down-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'lodash';
import { SCTGrid } from './grid.model';
import { ButtonRendererComponent } from './button_renderer.component';

@Component({
	selector: 'app-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit, OnChanges {

	@Input() id: string = '';
	@Input() fileName: string = '';
	@Input() data!: Array<Object>;
	@Input() columns: SCTGrid[] | null | undefined = [];
	@Input() settings: any = {};
	@Input() modelId: string = '';
	@Input() sizeColumnsToFit: boolean = false;
	@Input() autoSizeColumns: boolean = false;
	@Input() hasCheckboxColumn: boolean = false;
	@Input() idColumnName?: string;
	@Input() excludeOnExport: string[] = [];
	@Input() forceOnExport: string[] = [];
	@Input() colId: boolean = false;

	_settings = {
		hasExport: true,
		hasSelectColumn: true,
		hasReset: true,
		showSummary: true,
		height: "400",
		enableFloatingFilter: true,
		pagination: false,
		paginationPageSize: 10,
		domLayout: 'normal',
		keepDefaultColumnsInExport: false
	};
	key!: string;

	@Output() emitEventToParent = new EventEmitter<object>();

	gridApi: any;
	gridColumnApi: any;
	customizedColumnTypes: any = {};
	frameworkComponents: any = {};
	component: any = {};
	totalItemsCount: number = 0;

	defaultColDef: any = {
		sortable: true,
		filter: true
	};

	filterParams: any; //temp to fix error
	providedFilterParams: any; //temp to fix error
	helpTemplate = `
	<div class="ag-cell-label-container" role="presentation">
		<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
		<div ref="eLabel" class="ag-header-cell-label" role="presentation">
		<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
		<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
		<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
		<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
		<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
		<span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
		</div>
		&nbsp;
		<i class="fa fa-question-circle"></i>
	</div>`;

	constructor(
		private notificationMessage: SctToastService,
		private translateService: TranslateService,
		private cookieService: CookieService
	) {
		this.customizedColumnTypes = {
			number: { filter: "agNumberColumnFilter", valueFormatter: numberFormatter, comparator: numberComparator },
			string: { filter: "agNumberColumnFilter", valueFormatter: stringFormatter, comparator: stringComparator },
			date: {
				filter: "agDateColumnFilter", valueFormatter: dateFormatter, filterParams: {
					comparator: dateComparator
				}
			},
			time: {
				filter: "agTextColumnFilter", valueFormatter: timeFormatter, filterParams: {
					textCustomComparator: timeComparator, defaultOption: "startsWith"
				}
			},
			dateTime: {
				filter: "agDateColumnFilter",
				valueFormatter: timeFormatter,
				filterParams: {
					comparator: dateComparator
				}
			},
			boolean: {
				floatingFilterComponent: "DropDownFilter",
				floatingFilterComponentParams: {
					dropDownOptions: [
						{ label: '', value: null },
						{ value: true, label: this.translateService.instant('g.yes') },
						{ value: false, label: this.translateService.instant('g.no') }
					]
				},
				__values: { no: this.translateService.instant('g.no'), yes: this.translateService.instant('g.yes') },
				valueFormatter: booleanFormatter
			},
			clickable: {
				cellRenderer: "buttonRenderer",
				cellRendererParams: {
					onClick: 'onClick',
				},
			},
		};
		this.frameworkComponents = { DropDownFilter: DropDownFilter, buttonRenderer: ButtonRendererComponent};

		this.component = {
			linkCellRenderer: linkCellRenderer,
			coloredCell: coloredCell,
			nullFilter: nullFilter
		};
	}

	ngOnInit() {
		this.key = this.id + "_grid";

		this.setSettings();
		this.initDefaultColDef();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['settings'] && changes['settings'].previousValue != changes['settings'].currentValue) {
			this.setSettings();
		}
		if (changes['data'] && changes['data'].previousValue != changes['data'].currentValue) {
			this.data = changes['data'].currentValue;
			this.totalItemsCount = this.data.length;

			// convert all "number" type columns data to real numbers. to fix filtration issue
			if (this.totalItemsCount) {
				let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
				this.columns?.forEach((col) => {
					if (col.type == 'number')
						this.data.forEach((row: any) => {
							if (col.filterParams && col.filterParams.format && col.filterParams.format != 'noFormat' && !isNaN(row[col.field!]))
								row[col.field!] = +((decimalPipe.transform(row[col.field!], col.filterParams.format) || '').replace(/,/g, ''));
							else
								row[col.field!] = (+row[col.field!] ? +row[col.field!] : row[col.field!]);
						});
				});
			}
		}
		if (this.gridApi) {
			if (this.sizeColumnsToFit) {
				setTimeout(() => {
					this.gridApi.sizeColumnsToFit();
				});
			}
			if (this.autoSizeColumns) {
				setTimeout(() => {
					this.autoSizeAll(false);
				});
			}
		}
		if (this.columns && changes.columns) {
			this.columns.forEach((col) => {
				col.headerName = this.translateService.instant(col.headerName!, col.headerNameParams);
				if (col.headerTooltip)
					col.headerComponentParams = {
						template: this.helpTemplate
					};
			});
		}
		if (this.gridColumnApi)
			this.getGridFromLocalStorage();
	}

	initDefaultColDef() {
		this.defaultColDef = Object.assign(this.defaultColDef, {
			headerCheckboxSelection: (params: any) => {
				return this.gridApi && this.hasCheckboxColumn && params.columnApi.getAllDisplayedColumns()[0] === params.column;
			},
			checkboxSelection: (params: any) => {
				return this.gridApi && this.hasCheckboxColumn && params.columnApi.getAllDisplayedColumns()[0] === params.column;
			},
			floatingFilter: this.settings.enableFloatingFilter
		});
	}

	setSettings() {
		this.settings = { ...this._settings, ...this.settings };
	}

	toggleColumn(col: any) {
		col['hide'] = col['hide'] == 1 ? 0 : 1;
		let colKey = col.colId || col.field;
		this.gridColumnApi.setColumnVisible(colKey, (col.hide ? false : true));
		if (!col.hide) {
			this.gridColumnApi.autoSizeColumn(colKey, false)
		}
		this.onColVisibiltyChange();
	}

	getGridFromLocalStorage() {
		const storageValue = JSON.parse(localStorage.getItem(this.key) || '{}') || {};
		let localStorageGrid = storageValue.grid;

		if (localStorageGrid) {

			let sessionCookie = this.cookieService.get('userSession');

			if (sessionCookie != storageValue.session) {

				localStorage.removeItem(this.key);
				return;
			}

			if (localStorageGrid['columns']) {

				if (localStorageGrid['columns'].length != this.columns?.length) {
					localStorage.removeItem(this.key);
					return;
				}

				this.gridColumnApi.applyColumnState({
					state: (localStorageGrid['columns']),
					applyOrder: true,
				});
				this.updateColumns(localStorageGrid['columns']);
			}
			if (localStorageGrid['filter']) {
				this.gridApi.setFilterModel(localStorageGrid['filter']);
			}
		}
	}

	updateColumns(localColumns: any) {
		this.columns?.forEach((col) => {
			let foundCol = localColumns.find((localCol: any) => {
				return ((col.colId && col.colId == localCol.colId) || col.field == localCol.colId);
			});
			if (foundCol)
				col.hide = foundCol.hide;
		});
	}

	onColVisibiltyChange() {
		this.setGridLocalSotorage();
	}

	onSort() {
		this.setGridLocalSotorage();
	}

	onColReorder() {
		this.setGridLocalSotorage();
	}

	onFilter() {
		this.setGridLocalSotorage();
	}

	selectionChanged() {
		let selection = this.gridApi.getSelectedRows();
		if (this.idColumnName) {
			selection = map(selection, this.idColumnName)
		}
		this.emitEventToParent.emit({ selection });
	}

	setGridLocalSotorage() {
		if (this.id) {
			let storageValue = JSON.parse(localStorage.getItem(this.key) || '{}') || {};
			let grid = storageValue.grid;
			if (!grid) {
				grid = {};
			}

			grid.columns = this.gridColumnApi.getColumnState();
			grid.filter = this.gridApi.getFilterModel();
			let toStore = {
				grid: grid,
				session: this.cookieService.get('userSession')
			};
			localStorage.setItem(this.key, JSON.stringify(toStore));
		}
	}

	onReset() {
		this.gridColumnApi.resetColumnState();

		// clear filters
		this.columns?.forEach((col) => {
			let field = this.colId ? col.colId : col.field;
			this.gridApi.destroyFilter(field);
		});

		// to clear any highlighted rows
		this.gridApi.deselectAll();

		this.setGridLocalSotorage();

		let i = 0;
		this.gridColumnApi.getColumnState().forEach((col: any) => {
			this.columns && (this.columns[i].hide = !!col.hide);
			i++;
		});
		if (this.sizeColumnsToFit) {
			this.gridApi.sizeColumnsToFit();
		}
		if (this.autoSizeColumns) {
			this.autoSizeAll(false);
		}
	}

	onGridReady(params: any) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.getGridFromLocalStorage();
		if (this.sizeColumnsToFit) {
			this.gridApi.sizeColumnsToFit();
		}
		if (this.autoSizeColumns) {
			this.autoSizeAll(false);
		}
	}

	onBtExport() {
		const columnKeys: any = [];

		this.columns?.forEach((column) => {
			let field = this.colId ? column.colId : column.field;
			if (this.forceOnExport.includes(field!) || !this.excludeOnExport.includes(field!) && !column.hide)
				columnKeys.push(field);
		});

		if (columnKeys.length === 0) {
			this.notificationMessage.showMessage(this.translateService.instant('g.no_columns_to_export'));
			return;
		}

		var fileName = this.fileName || this.id;

		if (this.modelId)
			fileName += '-' + this.modelId

		var params: any = {
			skipHeader: false,
			columnGroups: true,
			skipFooters: true,
			skipGroups: true,
			skipPinnedTop: true,
			skipPinnedBottom: true,
			allColumns: false,
			onlySelected: false,
			suppressQuotes: false,
			fileName: fileName,
			columnSeparator: ','
		};

		if (!this.settings.keepDefaultColumnsInExport) {
			const _columnKeys: any[] = [];
			this.columns?.forEach((column) => {
				let field = this.colId ? column.colId : column.field;
				if (this.forceOnExport.includes(field!) || !this.excludeOnExport.includes(field!) && !column.hide)
					_columnKeys.push(field);
			});
			params.columnKeys = _columnKeys;
		}

		params.processCellCallback = (item: any) => {
			if (item.value) {
				switch (item.column.colDef.type) {
					case 'date':
					case 'time':
					case 'dateTime':
						let format = 'MM/dd/yyyy';
						if (item.value && (item.column.colDef.type == 'time' || item.column.colDef.type == 'dateTime'))
							format += ' HH:mm:ss';
						let datePipe: DatePipe = new DatePipe("en-US");
						return datePipe.transform(item.value, format);

					case 'number':
						return numberFormatter(item, true);
				}

			}
			return item.value;
		};

		this.gridApi.exportDataAsCsv(params);
	}

	redrawRows() {
		this.gridApi.redrawRows();
	}

	durationFilter(filter: string, value: any, filterText: string) {
		if (!filterText) {
			return true;
		}
		let filterTextSplitted = filterText.split(':');
		let filterValue = this.providedFilterParams.colDef.filterParams.thisObject.commonService.convertHourToSeconds(filterTextSplitted[0], filterTextSplitted[1] || 0, false, filterTextSplitted[2] || 0);
		let returnVal = false;
		let rowValue = value - (value % 60);
		switch (filter) {
			case 'lessThan':
				returnVal = rowValue < filterValue;
				break;
			case 'greaterThan':
				returnVal = rowValue > filterValue;
				break;
			default:
				returnVal = rowValue == filterValue;
				break;
		}
		return returnVal;
	}

	autoSizeAll(skipHeader: boolean) {
		const allColumnIds: any[] = [];
		this.gridColumnApi.getAllColumns().forEach((column: any) => {
			allColumnIds.push(column.colId);
		});
		this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
	}
}
const booleanFormatter = (params: any) => {
	if (params.colDef.type == 'boolean')
		return (+params.value ? params.colDef.__values.yes : params.colDef.__values.no);
	return '';
}
const numberFormatter = (params: any, isExport = false) => {
	if (isNaN(+(params.value))) {
		return params.value;
	}

	let format = '1.0-0';

	let filterParams;
	if (isExport)
		filterParams = params.column.colDef.filterParams;
	else
		filterParams = params.colDef.filterParams

	if (typeof filterParams == 'string')
		format = filterParams;
	else if (filterParams)
		format = filterParams.format;

	if (format == 'noFormat')
		return params.value;

	let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
	return decimalPipe.transform(params.value, format);
}

const stringFormatter = (params: any, isExport = false) => {
	return params.value || "-";
}

const dateFormatter = (params: any) => {
	// lang
	if (!params.value)
		return '--/--/--';

	let datePipe: DatePipe = new DatePipe("en-US");
	return datePipe.transform(params.value, 'MM/dd/yyyy');
}

const timeFormatter = (params: any) => {
	if (!params.value)
		return '--/--/-- --:--:--';

	if (params.value == 'Invalid date')
		return 'N/A';
	// lang
	let datePipe: DatePipe = new DatePipe("en-US");
	return datePipe.transform(params.value, 'MM/dd/yyyy HH:mm:ss');
}

function linkCellRenderer() {
}

function coloredCell() {
}

linkCellRenderer.prototype.init = function (params: any) {
	this.eGui = document.createElement('div');
	if (params.value !== "" || params.value !== undefined || params.value !== null) {
		let aTag = '<a>';
		if (params.link) {
			aTag = '<a href="' + params['link'] + '" class="no-print">';
		}
		this.eGui.innerHTML = aTag + params['label'] + '</a>' + '<span class="showOnPrint">' + params['label'] + '</span>';
		if(params.callback){
			const elem = this.eGui.children[0];
			elem?.addEventListener('click', params.callback);
		}
	}
};

coloredCell.prototype.init = function (params: any) {
	this.eGui = document.createElement('div');
	this.eGui.innerHTML = `<p>${params['text']}</p>`;
	this.eGui.style.backgroundColor = params['color'];
};

linkCellRenderer.prototype.getGui = function () {
	return this.eGui;
};

coloredCell.prototype.getGui = function () {
	return this.eGui;
};

function nullFilter() {
}

nullFilter.prototype.init = function (params: any) {
	this.valueGetter = params.valueGetter;
	this.filterText = null;
	this.eFilterText = {};
	this.params = params;
	this.gui = document.createElement('div');
};

nullFilter.prototype.getGui = function () {
	return this.gui;
};

nullFilter.prototype.doesFilterPass = function (params: any) {
	// make sure each word passes separately, ie search for firstname, lastname
	var passed = true;
	var valueGetter = this.valueGetter;
	var filterWord = this.filterText;
	if (filterWord.model) {
		filterWord = filterWord.model;
	}
	var value = valueGetter(params);
	switch (filterWord) {
		case 'none':
			if (value != '') {
				passed = false;
			}
			break;
		case 'not_null':
			let columnName2 = this.params.colDef.floatingFilterComponentParams.notNull2Field;
			if ((params.data[columnName2] && params.data[columnName2] != '') || value == '') {
				passed = false;
			}
			break;
		case 'not_null_empty_text':
			let columnName = this.params.colDef.floatingFilterComponentParams.notNull2Field;
			if (params.data[columnName] == '' || !params.data[columnName] || value == '') {
				passed = false;
			}
			break;
	}
	return passed;
};

nullFilter.prototype.isFilterActive = function () {
	return this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
};

nullFilter.prototype.getModel = function () {
	return this.filterText;
};

nullFilter.prototype.setModel = function (model: any) {
	this.filterText = model;
};

// used for sort
function numberComparator(var1: any, var2: any) {
	var var1Number = +(var1);
	var var2Number = +(var2);
	if (var1Number === null && var2Number === null) {
		return 0;
	}
	if (var1Number === null || isNaN(var1Number)) {
		return -1;
	}
	if (var2Number === null || isNaN(var2Number)) {
		return 1;
	}
	return var1Number - var2Number;
}

function stringComparator(var1: string, var2: string) {
	const var1LowerCase = var1 == null ? '' : `${var1}`.trim().toLowerCase();
	const var2LowerCase = var2 == null ? '' : `${var2}`.trim().toLowerCase();

	if (var1LowerCase == var2LowerCase)
		return 0;

	return var1LowerCase > var2LowerCase ? 1 : -1;
}

function dateComparator(filter: any, cellValue: any) {
	cellValue = new Date(cellValue);
	var day = cellValue.getDate();
	var month = cellValue.getMonth();
	var year = cellValue.getFullYear();

	var cellDate = new Date(year, month, day);
	if (cellDate < filter) {
		return -1;
	}
	if (cellDate > filter) {
		return 1;
	}
	return 0;
}
function timeComparator(cellValue: any, filterText: string) {
	let datePipe: DatePipe = new DatePipe("en-US");
	cellValue = datePipe.transform(cellValue, 'MM/dd/yyyy HH:mm:ss');
	return cellValue.indexOf(filterText) === 0;
}
