<app-non-logged-in-user-header *ngIf="isResetPasswordPage || pageName == 'force_change'">
</app-non-logged-in-user-header>

<div [ngClass]="{'force_change': user}" class="d-flex justify-content-center overflow-auto p-4">
	<div class="col-md-8 bg-white mt-2" style="height: fit-content;">
		<div class="border-bottom p-3">
			<h2 *ngIf="!isResetPasswordPage">{{'login_register.change_password' | translate}}</h2>
			<h2 *ngIf="isResetPasswordPage">{{'action_log.reset_password' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<sct-form *ngIf="form" [data]="{}" [submitButtonText]="'g.submit'" [invalidFields]="invalidFields" [name]="'editUserPassword'"
				[showCancelButton]="pageName != 'force_change'"
				(formCancelled)="goBack()"
				[cancelButtonText]="isResetPasswordPage ? 'g.cancel' : 'g.back'" [formBuilder]="form"
				(invlidateFieldsCallback)="toastService.showMessage('translate|g.invalid_input')">
			</sct-form>
		</div>
	</div>
</div>