<div class="w-100 h-100 position-relative">
	<app-loader></app-loader>
	<div class="app-main">
		<router-outlet></router-outlet>
	</div>
	<div class="app-footer">
		<app-footer></app-footer>
	</div>
</div>

<p-toast position="center"></p-toast>