import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class NavigationService {

	noRoutesSave = ['/$', '/login$', '/register$', '/forgot-password$', '/user/change-password$', '/change-expired-password$', '/reset-password*', '/confirm-user-email*', '/non-confirmed-user-email*', '/terms-and-conditions$'];

	private currentUrl: any = undefined;

	//Holds the last valid previous URL (not 404/unauthorized)
	private latestPreviousUrl: string = '';

	//Ignore URLs as previous if they redirect to these
	private ignorePrevious = ['/404', '/unauthorized'];

	constructor(
		private router: Router
	) {
		this.currentUrl = router.url;
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.ignorePrevious.includes(event.urlAfterRedirects)) {
					this.latestPreviousUrl = event.url;
				}
				this.setPreviousUrl(this.currentUrl);
				this.currentUrl = event.url;
			};
		});
	}

	setPreviousUrl(url: string|null, remove = false) {

		if (remove) {
			sessionStorage.removeItem('previousUrl');
			return;
		}

		if (url && url != this.latestPreviousUrl) {

			let save = true;

			for (let route of this.noRoutesSave) {

				if (new RegExp("^" + route, "g").test(url)) {
					save = false;
					break;
				}
			}

			if (save)
				sessionStorage.setItem('previousUrl', url);
		}
	}

	getPreviousUrl() {
		return sessionStorage.getItem('previousUrl');
	}

	goBack(defaultUrl: any) {
		let backUrl = this.getPreviousUrl();
		if (backUrl && backUrl != '/' && this.currentUrl != backUrl)
			return this.navigate(backUrl);
		return this.navigate(defaultUrl);
	}

	navigate(url: string) {
		if (url.indexOf('?') == -1)
			return this.router.navigate([url]);

		let urlParsed = url.split('?');
		let urlString = urlParsed.shift();
		let queryParamsSplitted = urlParsed.join('?').split('&');
		let queryParams: any = {};
		for (let item of queryParamsSplitted) {
			let temp = item.split('=');
			queryParams[temp[0]] = temp[1];
		}

		return this.router.navigate([urlString], { queryParams });
	}
}