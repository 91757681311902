<div class="white-box p-4">
	<h2>{{'device_management.upload_fw_file' | translate}}</h2>
	<div [formGroup]="form">
		<div class="d-flex mb-3">
			<span class="me-2 mt-2 fw-bolder">
				{{'g.select_file' | translate}}
			</span>
			<p-fileUpload [chooseLabel]="'g.select_file' | translate" [multiple]="false" accept=".bin" [mode]="'basic'" [files]="uploadedFile" [customUpload]="true" (onSelect)="onUpload($event)" [showUploadButton]="false" [showCancelButton]="false">
			</p-fileUpload>
		</div>
		<div class=" mt-4">
			<div class="alert alert-light">
			  <p class="mb-0"><strong>{{'device_management.type1_text' | translate}}</strong> {{'device_management.filename_start_with' | translate}} <strong>{{'device_management.iotemeterInterface_enc' | translate}}</strong>.</p>
			  <p class="mb-0"><strong>{{'device_management.type2_text' | translate}}</strong> {{'device_management.filename_start_with' | translate}} <strong>{{'device_management.powermeter_enc' | translate}}</strong>.</p>
			  <p class="mb-0">{{'device_management.other_file_names_fw_text' | translate}}</p>
			</div>
		</div>

		<div class="w-100 d-flex flex-column align-items-end">
			<button pButton pRipple type="submit" [disabled]="form.invalid || updatingFW"
			(click)="onSubmit()"
			label="{{'g.submit' | translate}}" class="col-12 col-md-5 ms-2 form-btn main-blue-bg"></button>
			<span class="text-danger" *ngIf="updatingFW">
				{{'device.updating_fw' | translate}}
			</span>
		</div>
	</div>
</div>
<div *ngIf="fwList.length">
	<div *ngIf="fwList[deviceTypes.TYPE1].fwVersion.length">
		<table class="table table-striped mt-4">
			<thead>
				<tr>
					<th>{{'device.fw_files_list_type_1' |translate}}</th>
					<th>{{'g.action' |translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let file of fwList[deviceTypes.TYPE1].fwVersion">
					<td>
						{{(file).toFixed(4)}}
					</td>
					<td>
						<button class="btn btn-primary"  type="button" (click)="deleteFWFile(file, 0)">{{'g.delete' | translate}}</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="fwList[deviceTypes.TYPE2].fwVersion.length">
		<table class="table table-striped mt-4">
			<thead>
				<tr>
					<th>{{'device.fw_files_list_type_2' |translate}}</th>
					<th>{{'g.action' |translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let file of fwList[deviceTypes.TYPE2].fwVersion">
					<td>
						{{(file).toFixed(4)}}
					</td>
					<td>
						<button class="btn btn-primary"  type="button" (click)="deleteFWFile(file, 1)">{{'g.delete' | translate}}</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>