<div class="d-flex justify-content-between p-1" *ngIf="title; else noTitle">
	<div class="">
		<h4>
			{{title}}
			<app-help-tooltip *ngIf="tooltipKey" [text]="tooltipKey" [iconSize]="'sm'"></app-help-tooltip>
		</h4>
	</div>
	<div class=" ml-auto d-inline-flex">
		<button pButton class="p-button-info" (click)="showActionModal('add')">
			{{'note.add_note' | translate}}
		</button>
	</div>
</div>
<ng-template #noTitle>
	<table class="table">
		<tr class="margin-bottom-sm">
			<td class="text-nowrap">
				<button class="p-button-info" pButton (click)="showActionModal('add')">
					{{'note.add_note' | translate}}
				</button>
			</td>
		</tr>
	</table>
</ng-template>
<div *ngIf="modelNotes.length; else noNotes">
	<div class="table-responsive">
		<table class="table table-bordered">
			<tr *ngFor="let note of modelNotes" class="margin-bottom-sm">
				<td *ngIf="note.canDelete">
					<button pButton class="p-button-danger me-1" (click)="showActionModal('delete', note)">
						<fa-icon icon="trash"></fa-icon>
					</button>
					<button pButton type="button" class="me-1 p-button-outlined p-button-secondary" (click)="showActionModal('reminder', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_self_reminder_frequency' | translate}}" *ngIf="note.canEditReminder" [ngClass]="{'mt-2': commonService.isMobileScreen}">
						<fa-icon icon="bell"></fa-icon>
					</button>
					<button pButton type="button" class="p-button-outlined p-button-secondary" [disabled]="!note.canUpdateShareType" (click)="showActionModal('share', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_sharing_type' | translate}}" [ngClass]="{'mt-2': commonService.isMobileScreen}">
						<i [ngClass]="getShareTypeIcon(note.shareType)" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'note.share_type_'+note.shareType | translate}}">
						</i>
					</button>
				</td>
				<td *ngIf="!note.canDelete">&nbsp;</td>
				<td>{{note.formattedDate}}</td>
				<td class="w-50" *ngIf="note.type == 'user_note'">
					{{note.fname ? (note.fname + ' ' + note.lname) : 'DELETED USER ('+note.noteOwnerId+')'}}: <span [innerHTML]="note.note | safe: 'html'"></span>
				</td>
				<td class="w-50" *ngIf="note.type != 'user_note'">
					{{'system_notes.'+ camelToSnakeCase(note.note.action) | translate}} <span *ngIf="note.noteOwnerId">
						{{'g.by' | translate}} {{note.fname ? (note.fname + ' ' + note.lname) : 'DELETED USER ('+note.noteOwnerId+')'}}
					</span><br>
					<ng-container *ngIf="note.note.info?.length; else noteinfo">
						<span *ngFor="let item of note.note.info">
							{{item}}
						</span>
					</ng-container>
					<ng-template #noteinfo>
						<span *ngFor="let key of note.note.info | keys">
							{{("note_keys." + camelToSnakeCase(key)) | translate}}: {{note.note.info[key]}}<br>
						</span>
					</ng-template>
				</td>
				<td>
					<p *ngFor="let notifiedUser of note.notifiedList">{{notifiedUser.fname}} {{notifiedUser.lname}}</p>
				</td>
			</tr>
		</table>
	</div>
	<div class="text-center" *ngIf="modelNotesAllData.length > 3 && !showMore">
		<button type="button" class="btn btn-xs margin-bottom" (click)="onShowMore(true)">
			<fa-icon icon="caret-square-down"></fa-icon>
		</button>
	</div>
	<div class="text-center" *ngIf="modelNotesAllData.length > 3 && showMore">
		<button type="button" class="btn btn-xs margin-bottom" (click)="onShowMore(false)">
			<fa-icon icon="caret-square-up"></fa-icon>
		</button>
	</div>
</div>
<ng-template #noNotes>
	<div class="text-center margin-bottom-lg text-muted">
		<h5>{{'note.no_notes' | translate}}</h5>
	</div>
</ng-template>

<app-sct-dialog [dialog]="addNoteDialog">
	<div dialog-content class="w-100" *ngIf="thisNote">
		<sct-form *ngIf="form" [data]="thisNote"
			[invalidFields]="invalidFields" [name]="'model_note'"
			[formBuilder]="form"
			[showCancelButton]="true" [cancelButtonText]="'g.close'"
			(formCancelled)="addNoteDialog.visible = false"
			(invlidateFieldsCallback)="notificationMessageService.showMessage('translate|g.invalid_input')">
		</sct-form>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="deleteNoteDialog" [okBtnColor]="'p-button-danger'" [okText]="'g.delete' | translate">
	<div class="container" *ngIf="thisNote" dialog-content>
		<div class="row mb-4">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="my-2 text-center">
			<strong>{{'note.delete_note_msg' | translate}}</strong>
		</div>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="editReminderFreqDialog" [okText]="'g.save' | translate">
	<div  dialog-content class="container" *ngIf="thisNote">
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.self_reminder_frequency' | translate}}
			</label>
			<div class="col-md-6">
				<p-dropdown appendTo="body" [options]="reminderFreqList" [optionValue]="'id'" [optionLabel]="'label'"  [(ngModel)]="thisNote.reminder_freq" placeholder="{{'note.select_reminder_frequency' | translate}}"></p-dropdown>
			</div>
		</div>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="editShareTypeDialog" [okText]="'g.save' | translate">
	<div  dialog-content class="container" *ngIf="thisNote">
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.share_with' | translate}}
			</label>
			<div class="col-md-6">
				<p-dropdown appendTo="body" [options]="shareTypeList" [optionValue]="'id'" [optionLabel]="'label'"  [(ngModel)]="thisNote.shareType"></p-dropdown>
			</div>
		</div>
	</div>
</app-sct-dialog>