import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SideMenuService } from '@app/core/services/side-menu/side-menu.service';
import { SitesService } from '@app/business/sites/sites.service';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { environment } from '../../../../../../environment';

@Component({
	selector: 'app-map-box',
	templateUrl: './map-box.component.html',
	styleUrls: ['./map-box.component.css'],
})
export class MapBoxComponent {
	@Input() latitude: number = 0;
	@Input() longitude: number = 0;
	@Input() zoom: [number] = [3];
	@Input() zoomIn: boolean = false;
	@Input() showPopup = true;
	@Input() dots?: any;
	@Input() dotsType?: any;
	@Input() clickEnabled: boolean = false;
	@Output() onAddressChanged = new EventEmitter<Object>();

	private mapBoxUrl = 'https://api.mapbox.com/search/geocode/v6/reverse';

	center: [number, number];
	map: any = {};
	defaultMapLocation: any;
	constructor(
		public sideMenuService: SideMenuService,
		private mapsAPILoader: MapsAPILoader,
		private sitesService: SitesService,
		private gateway: GatewayService
	) {
		this.defaultMapLocation = {...this.sitesService.defaultMapLocation};
		this.latitude = this.sitesService.defaultMapLocation.latitude;
		this.longitude = this.sitesService.defaultMapLocation.longitude;
		this.center = [this.longitude, this.latitude];
		this.mapsAPILoader.load().then(() => {
			this.getAddressName({ lat: this.latitude, lng: this.longitude }).subscribe(
				(data: any) => {
					if(!data['features'].length) {
						this.map['address'] = '';
						this.map['country'] = '';
						this.map['state'] = '';
						this.map.lng = this.longitude;
						this.map.lat = this.latitude;
						this.onAddressChanged.emit(this.map);
						return;
					}
					data = data['features'][0]['properties'];
					this.map['address'] = data['full_address'];
					this.map['country'] = data['context']['country']['name'];
					this.map['state'] = data['context']['region']['name'];
					this.map.lng = this.longitude;
					this.map.lat = this.latitude;
					this.onAddressChanged.emit(this.map);
				}
			);
		});
	}

	ngOnChanges(changes: any) {
		if (changes.longitude && changes.latitude) {
			this.center = [changes.longitude.currentValue || this.defaultMapLocation.longitude, changes.latitude.currentValue || this.defaultMapLocation.latitude];
		}
		if (this.dots && this.dots.length) {
			for (let dot of this.dots) {
				dot.opened = false;
			}
		}
		// if(changes.dots)
		// 	this.sideMenuService.reDrawMap();
	}

	setCurrentPosition(event: any, updateDots = true) {
		if (!this.clickEnabled) {
			return;
		}
		const pinLocation = event.lngLat || null;

		if (pinLocation) {
			const lat = pinLocation.lat,
				lng = pinLocation.lng;
			this.map.lat = lat;
			this.map.lng = lng;
			if (updateDots && this.dots) {
				this.dots[0].latitude = lat;
				this.dots[0].longitude = lng;
			}
			this.center = [lng, lat];
			if (this.zoomIn) {
				this.zoom = [50];
			}

			this.getAddressName({ lat, lng }).subscribe(
				(data: any) => {
					if(!data['features'].length) {
						this.map['address'] = '';
						this.map['country'] = '';
						this.map['state'] = '';
						this.onAddressChanged.emit(this.map);
						return;
					}
					data = data['features'][0]['properties'];
					this.map['address'] = data['full_address'];
					this.map['country'] = data['context']['country']['name'];
					this.map['state'] = data['context']['region']['name'];
					this.onAddressChanged.emit(this.map);
				}
			);
		}
	}

	onGeocoderResult(event: any) {
		this.setCurrentPosition({
			lngLat: {
				lng: event.result.geometry.coordinates[0],
				lat: event.result.geometry.coordinates[1]
			}
		})
	}

	getAddressName(coords: marker) {
		return this.gateway.get(this.mapBoxUrl, {
			longitude: coords.lng,
			latitude: coords.lat,
			access_token: environment.mapbox.accessToken
		});
	}

	parseAddressComponentObject(addressComponentArr: any[]) {
		const addressObj: any = {};
		addressComponentArr.forEach(function (address_component) {
			switch (address_component.types[0]) {
				case 'country':
					addressObj['country'] = address_component['long_name'];
					break;
				case 'administrative_area_level_1':
					addressObj['state'] = address_component['long_name'];
					break;
			}
		});
		return addressObj;
	}
}

interface marker {
	lat: number;
	lng: number;
}
