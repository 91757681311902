import { SctToastService } from '@app/shared/services/sct-toast.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@app/business/users/users.service';
import { SCTFormBuilderField, SCTFormBuilder, InputType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { NavigationService } from '@app/shared/services/navigation.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

	user: { email: string | null, password: string | null } = { email: null, password: null };
	formFields: SCTFormBuilderField[] = [];
	form!: SCTFormBuilder;
	invalidFields: any = {};
	triesLimitReachedCaptcha = 0;

	constructor(
		private usersService: UsersService,
		public sctToastService: SctToastService,
		private navigation: NavigationService,
		private router: Router
	) { }

	ngOnInit(): void {
		if (this.usersService.isLoggedIn()) {
			this.router.navigate(['/']);
		}
		this.prepareFormFields();
	}

	saveData(data: { email: string, password: string, link?: string }) {
		if (!data.email) {
			return;
		}

		this.usersService.forgotPassword(data).subscribe((res: any) => {
			if (this.form.updateCaptcha && res === 6) {
				++this.triesLimitReachedCaptcha;
				if (this.triesLimitReachedCaptcha < 3) {
					this.sctToastService.showMessage('globalInvalidCaptcha');
				}
				this.form.updateCaptcha();
			} else {
				if (res.api_status) {
					switch (res.api_status) {
						case 2:
							this.invalidFields = { email: true };
							this.sctToastService.showMessage('translate|users.invalid_email');
							this.form.updateCaptcha && this.form.updateCaptcha();
							break;
						case 3:
							this.invalidFields = { email: true };
							this.sctToastService.showMessage('translate|users.email_not_confirmed');
							this.form.updateCaptcha && this.form.updateCaptcha();
							break;
						case 1:
							// @Wael: check below with Motasem
							// @todo: remove case when loader interceptor is ready
							this.onSuccessLogin();
							break;
					}
				} else {
					this.onSuccessLogin();
				}
			}
		});

	}

	prepareFormFields() {
		this.formFields = [
			{
				name: 'email',
				label: 'login_register.email',
				type: InputType.TEXT,
				sctFormOptions: {
					trimText: true
				}
			}
		];

		this.form = {
			fields: this.formFields,
			captchaEnabled: true,
			captchaExpired: () => {
				this.sctToastService.showMessage('translate|login_register.verification_is_expired');
			},
			disableSubmitButton: true,
			callback: (data: any) => this.saveData(data),
			cancelButtonCss: 'btn-secondary'
		};
	}

	onSuccessLogin() {
		this.sctToastService.showMessage('translate|g.reset_password_email_sent', 'success');
		this.goBack();
	}


	goBack() {
		this.navigation.goBack('/');
	}
}
