import { ValidationService } from '@app/shared/services/validation.service';
import { UsersService } from '@app/business/users/users.service';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { NgModule, OnDestroy } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule as tool } from 'ngx-bootstrap/tooltip';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { HelpTooltipComponent } from './help-tooltip/help-tooltip.component';
import {
	FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderComponent } from './header/header.component';
import { DropdwonComponent } from './dropdown/dropdown.component';
import { warningsHistoryTableComponent } from './warnings-history-table/warnings-history-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { SCTTableComponent } from './sct-table/sct-table.component';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SCTTabMenuComponent } from './sct-tab-menu/sct-tab-menu.component';
import { ScrollerTabDirective } from './directives/scrolling/tab-scroller.directive';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MapBoxComponent } from './map-box/map-box.component';
import { environment } from '../../../../../environment';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MessageModule } from 'primeng/message';
import { KeysPipe } from './pipes/keys.pipe';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessagesModule } from 'primeng/messages';
import { SctDialogComponent } from './sct-dialog/sct-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AgmCoreModule } from '@agm/core';
import { SafePipe } from './pipes/safe-html.pipe';
import { LoaderComponent } from './loader/loader.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { ServerPaginationComponent } from './server-pagination/server-pagination.component';
import { NonLoggedInUserHeaderComponent } from './non-logged-in-user-header/non-logged-in-user-header.component';
import { CheckPermissionDirective } from './directives/check-permission.directive';
import { PerformanceAnalyticsComponent } from './performance-analytics/performance-analytics.component';
import { CommonSitesAnalyticsChartsComponent } from './common-sites-analytics-charts/common-sites-analytics-charts.component';
import { CardModule } from 'primeng/card';
import { NoteComponent } from './note/note.component';
import { NOCNoteComponent } from './noc-note/noc-note.component';
import { NoteService } from './note/note.service';
import { AttachmentsComponent } from './attachments/attachments.component';
import { AttachmentsService } from './attachments/attachments.service';
import { GridComponent } from './grid/grid.component';
import { DropDownFilter } from './grid/drop-down-filter.component';
import { ButtonRendererComponent } from './grid/button_renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { DropdownDirective } from './directives/dropdown.directive';
import { GaTrackerDirective } from './directives/ga-tracker.directive';
import { TagInputComponent } from './tag-input/tag-input.component';
import { CalenderDirective } from './directives/calender.directive';
import { SctDatePickerComponent } from './sct-date-picker/sct-date-picker.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableComponent } from './table/table.component';
import { SCTCustomTable } from './custom-table/custom-table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { SCTFormsModule } from 'sct-framework/form/projects/form/src/lib/form.module';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import fieldPermissionMapper from '../../../../iot-emeter-common/permissions/fieldPermissionMapper.json';
import { FormService } from 'sct-framework/form/projects/form/src/lib/form.service';
import { Subscription } from 'rxjs';
import { RenderType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { WarningsListComponent } from '@app/shared/warnings-list/warnings-list.component';
import { LoadingWithMessageComponent } from './loading-with-message/loading-with-message.component';

@NgModule({
	declarations: [
		HelpTooltipComponent,
		HeaderComponent,
		DropdwonComponent,
		SCTTableComponent,
		SCTTabMenuComponent,
		ScrollerTabDirective,
		MapBoxComponent,
		KeysPipe,
		SctDialogComponent,
		LoaderComponent,
		SafePipe,
		DateRangeComponent,
		ServerPaginationComponent,
		NonLoggedInUserHeaderComponent,
		CheckPermissionDirective,
		PerformanceAnalyticsComponent,
		CommonSitesAnalyticsChartsComponent,
		NoteComponent,
		NOCNoteComponent,
		AttachmentsComponent,
		GridComponent,
		DropDownFilter,
		ButtonRendererComponent,
		DropdownDirective,
		GaTrackerDirective,
		TagInputComponent,
		CalenderDirective,
		SctDatePickerComponent,
		WarningsListComponent,
		TableComponent,
		SCTCustomTable,
		LoadingWithMessageComponent,
		warningsHistoryTableComponent
	],
	imports: [
		RouterModule,
		CommonModule,
		TranslateModule,
		BsDatepickerModule.forRoot(),
		tool.forRoot(),
		TooltipModule,
		ModalModule.forRoot(),
		NgSelectModule,
		FontAwesomeModule,
		DropdownModule,
		TableModule,
		ReactiveFormsModule,
		TabMenuModule,
		ToastModule,
		NgxMapboxGLModule.withConfig({
			accessToken: environment.mapbox.accessToken,
			geocoderAccessToken: environment.mapbox.accessToken,
		}),
		MessageModule,
		TieredMenuModule,
		MessagesModule,
		DialogModule,
		DividerModule,
		BlockUIModule,
		ProgressSpinnerModule,
		PanelModule,
		AgmCoreModule.forRoot({
			apiKey: environment.amg.apiKey,
			libraries: environment.amg.libraries
		}),
		ChartModule,
		CardModule,
		AgGridModule,
		ButtonModule,
		MatCheckboxModule,
		MatMenuModule,
		MatIconModule,
		MatPaginatorModule,
		MultiSelectModule,
		FormsModule,
		MatTableModule,
		SCTFormsModule,
		CheckboxModule,
		RadioButtonModule,
		MatSortModule
	],
	exports: [
		HelpTooltipComponent,
		HeaderComponent,
		DropdwonComponent,
		CommonModule,
		SCTTableComponent,
		SCTTabMenuComponent,
		ScrollerTabDirective,
		MapBoxComponent,
		KeysPipe,
		SctDialogComponent,
		LoaderComponent,
		SafePipe,
		DateRangeComponent,
		ServerPaginationComponent,
		NonLoggedInUserHeaderComponent,
		CheckPermissionDirective,
		PerformanceAnalyticsComponent,
		CommonSitesAnalyticsChartsComponent,
		NoteComponent,
		NOCNoteComponent,
		AttachmentsComponent,
		GridComponent,
		DropdownDirective,
		GaTrackerDirective,
		TagInputComponent,
		SctDatePickerComponent,
		TableComponent,
		SCTCustomTable,
		WarningsListComponent,
		SCTFormsModule,
		LoadingWithMessageComponent,
		warningsHistoryTableComponent,
		TooltipModule
	],
	providers: [MessageService, DatePipe, NoteService, BsDatepickerConfig, TooltipModule, AttachmentsService],
})
export class SharedModule implements OnDestroy {
	usersSubscription!: Subscription;
	constructor(formService: FormService, usersService: UsersService, validationService: ValidationService) {
		formService.fieldPermissionMapper = fieldPermissionMapper;
		formService.validationService = validationService;
		this.usersSubscription = usersService.currentUser.subscribe((res) => {
			formService.currnetUser = res;
			formService.renderType = RenderType.PRIME_NG;
		});

	}

	ngOnDestroy(): void {
		if (this.usersSubscription)
			this.usersSubscription.unsubscribe();
	}


}
