<div [ngStyle]="{ 'height': maxHeight}">
	<div class="position-relative w-100 h-100 sct-table-container">
		<ng-container  *ngIf="table && (table.toggleShowColumn || table.exportCSV || table.allowResetTable)">
			<div class="w-100 d-flex sct-table-caption justify-content-end mb-2">
				<p-multiSelect [options]="columnList" dataKey="key" *ngIf="table.toggleShowColumn" optionLabel="value" appendTo="body"
					[(ngModel)]="selectedColumns" selectedItemsLabel="{0} columns selected"
					[style]="{minWidth: '200px'}" placeholder="Choose Columns"
					(onChange)="updateToggleShowColumn()"></p-multiSelect>
				<div *ngIf="table.exportCSV" class="mx-2 mt-2">
					<button type="button" pButton pRipple icon="pi pi-file-o"
						(click)="dataTable.exportCSV({exportColumns: tableColumns})" class="p-mr-2" tooltipPosition="bottom"></button>
				</div>
				<div *ngIf="table.allowResetTable" class="mx-2 mt-2">
					<button type="button" pButton pRipple icon="pi pi-replay"
						(click)="resetTable();" class="p-mr-2"tooltipPosition="bottom"></button>
				</div>
			</div>
		</ng-container>
		<div class="position-absolute  bg-white w-100">
			<p-table *ngIf="table && data" [value]="data" #dataTable [columns]="tableColumns"
				(selectionChange)="selectionChange($event)" [(selection)]="SelectionRows"
				[selectionMode]="table.selection?.mode || 'single'"
				styleClass="{{table.striped ? 'p-datatable-striped' : ''}} p-datatable-gridlines sct-table"
				[scrollable]="true" [scrollHeight]="'flex'" scrollDirection="both" responsiveLayout="scroll"
				[resizableColumns]="true" [rowHover]="table.rowHover || false" [paginator]="!!table.paginator || false"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
				[rowsPerPageOptions]="table.paginator?.rowsPerPageOptions || [20,50,75]"
				[showCurrentPageReport]="!!table.paginator" [rows]="table.paginator?.rows || 20"
				[exportFilename]="table.exportFilename || table.id">
				<!--stateStorage="local" stateKey="table-{{table.id}}"-->
				<ng-template pTemplate="colgroup">
					<colgroup>
						<col style="width:3rem"
							*ngIf="table.selection && table.selection.type != 'rowSelection' && table.selection.mode != 'none'">
						<col *ngFor="let col of table.headers.list; let index = index;"
							[ngStyle]="{'width': col?.width || '200px'}">
					</colgroup>
				</ng-template>
				<ng-template pTemplate="header">
					<tr class="sct-table-header">
						<th class="sct-table-header-cell" *ngIf="table.selection?.type === 'checkbox'"
							style="width: 3rem; text-align: center;">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<th class="sct-table-header-cell" *ngIf="table.selection?.type === 'radio'" style="width: 3rem; text-align: center;">
							<p-tableRadioButton></p-tableRadioButton>
						</th>
						<ng-container *ngFor="let col of table.headers.list; let index = index;">
							<ng-container *ngIf="!col.sortable && !table.sortAllColumn">
								<th [ngStyle]="{'width': col?.width || '200px'} " pResizableColumn
									class="{{index === 0 ? 'px-1': ''}} sct-table-header-cell" *ngIf="!col.hideColumn">
									<ng-container *ngIf="false else headerColumnContent"></ng-container>
								</th>
							</ng-container>
							<ng-container *ngIf="col.sortable || table.sortAllColumn">
								<th [ngStyle]="{'width': col?.width || '200px'}" pResizableColumn
									pSortableColumn="{{table.body.structure.columns[index].key}}"
									class="{{index === 0 ? 'px-1': ''}} sct-table-header-cell" *ngIf="!col.hideColumn">
									<ng-container *ngIf="false else headerColumnContent"></ng-container>
								</th>
							</ng-container>
							<ng-template #headerColumnContent>
								<div class="px-3">
									<span class="sct-table-header-text" *ngIf="col.key">
										{{ col.key | translate }}
									</span>
									<span class="sct-table-header-text" *ngIf="col.text">
										{{ col.text }}
									</span>
									<p-sortIcon *ngIf="col.sortable || table.sortAllColumn"
										field="{{table.body.structure.columns[index].key}}">
									</p-sortIcon>
								</div>
							</ng-template>
						</ng-container>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
					<tr *ngIf="table.selection?.type === 'rowSelection'" [pSelectableRow]="row"
						[pSelectableRowIndex]="rowIndex">
						<ng-container *ngIf="false else rowContent"></ng-container>
					</tr>
					<tr *ngIf="!table.selection || table.selection?.type !== 'rowSelection'">
						<td *ngIf="table.selection?.type === 'checkbox'" style="width: 3rem">
							<p-tableCheckbox [value]="row"></p-tableCheckbox>
						</td>
						<td *ngIf="table.selection?.type === 'radio'" style="width: 3rem">
							<p-tableRadioButton [value]="row"></p-tableRadioButton>
						</td>
						<ng-container *ngIf="false else rowContent"></ng-container>
					</tr>
					<ng-template #rowContent>
						<ng-container *ngFor="let column of rowStructure.columns; let index = index">
							<td *ngIf="!column.hideColumn" style="word-break: break-all;"
								[ngStyle]="{'width': table.headers.list[index].width || '200px'}"
								class="{{column.classes}}">

								<button *ngIf="column.routerLink"
									[routerLink]="column && column.key && row[column.key + '_link']" pButton
									type="button" class="p-button-link" label="{{(row[column.key]) | translate}}">
								</button>

								<button *ngIf="column.type === contentTypes.ACTION" class="{{column.classes}}"
									(click)="column.callback(row.rowId)" label="{{(column.actionName || column && column.key && row[column.key]) | translate}}" pButton type="button">
								</button>

								<img *ngIf="column.src" [src]="row[column.key]" [alt]="altImage" width="35" height="35"
									class="p-shadow-4" />

								<fa-icon *ngIf="column.fontAwesome" [icon]="column.fontAwesome"></fa-icon>

								<span *ngIf="column.type === contentTypes.TEXT || column.type === contentTypes.BOOLEAN"
									style="white-space: pre-line;">
									{{ column && column.key && row[column.key] }}
								</span>

								<span *ngIf="column.type === contentTypes.NUMBER" style="white-space: pre-line;">
									{{getGroupNumber(column && column.key && row[column.key], column.roundFloat )}}
								</span>

								<span *ngIf="column.type === contentTypes.TIME" style="white-space: pre-line;">
									<ng-container *ngIf="column && column.key && row[column.key] else defaultTime">
										{{ common.timeFormat(column && column.key && row[column.key], {limitToOneDay: column.limitToOneDay}) }} 
									</ng-container>
									<ng-template #defaultTime>
										<ng-container *ngIf="column.defaultValueToView">
											{{column.defaultValueToView}}
										</ng-container>
									</ng-template>
								</span>

								<span *ngIf="column.type === contentTypes.DATE" style="white-space: pre-line;">
									<ng-container *ngIf="isDate(column && column.key && row[column.key]) else noDate">
										{{ column &&
											column.key && row[column.key] | date:(column.format || 'MM/dd/yyyy hh:mm:ss a') : (
											column.showTimeOnUTC ? 'UTC' : '')}}
									</ng-container>
									<ng-template #noDate>
										-
									</ng-template>
								</span>

								<app-dropdown *ngIf="column.dropdown" [index]="rowIndex"
									[option]="column.dropdown.option || null" [list]="row.dropdownItems">
								</app-dropdown>

								<ng-container *ngIf="column.type === contentTypes.CUSTOM">
									<div [innerHTML]="row[column.key] | safe:'html'"></div>
								</ng-container>

								<ng-container *ngIf="column.type === contentTypes.CUSTOM_LINK">
									<ng-container *ngIf="row[column.key + '_labeled'] else link">
										<span class="ms-3">
											{{row[column.key + '_labeled']}}
										</span>
									</ng-container>
									<ng-template #link>
										<button [routerLink]="row[column.key + '_link']" pButton type="button"
											class="p-button-link" label="{{(row[column.key]) | translate}}">
										</button>
									</ng-template>
								</ng-container>
							</td>
						</ng-container>
					</ng-template>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="table.headers.list.length" class="p-text-center border-0">
							<div class="w-100 h-100 d-flex justify-content-center align-items-center">
								<div>
									{{'g.no_data_found' | translate}}
								</div>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>