import { sum } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CustomersService } from '@app/business/customers/customers.service';
import { SitesService } from '@app/business/sites/sites.service';
import moment from 'moment';
import { getBeforeXPeriodDate, getLast3MonthsDate, getTodayDate } from '../services/utilities';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-common-sites-analytics-charts',
	templateUrl: './common-sites-analytics-charts.component.html',
	styleUrls: ['./common-sites-analytics-charts.component.css']
})
export class CommonSitesAnalyticsChartsComponent implements OnInit, OnChanges {
	@Input() service!: SitesService | CustomersService;
	@Input() id!: number;
	@Input() minDate!: Date;
	usageDataChartFilters = {
		start: getBeforeXPeriodDate(1, 'month'),
		end: getTodayDate()
	};
	usageDataChart: any;
	usageDataExport: any = [];
	maxDate = getTodayDate();

	performanceSummaryChart: any;
	performanceSummaryExport: any = [];
	performanceSummaryChartData: any[] = [
		// 0: macAddress
		// 1: dip
		// 2: swell
		// 3: overcurrent
	];
	performanceSummaryChartFilters = {
		start: getLast3MonthsDate(),
		end: getTodayDate()
	};
	usageData: any;
	usageDataNoDataFound = false;
	performanceSummaryNoDataFound = false;
	@Output('onPerformanceSummaryClickEvent') onPerformanceSummaryClickEvent = new EventEmitter<{ targetEvent: any, index: number, datasetIndex: number }>()

	constructor(
		private translateService: TranslateService,
		private commonUtil: CommonService
	) { }

	updateData() {
		this.updateUsageData([moment.utc(this.usageDataChartFilters.start).startOf('day').unix(), moment.utc(this.usageDataChartFilters.end).endOf('day').unix()]);
		if (this.minDate > this.performanceSummaryChartFilters.start)
			this.performanceSummaryChartFilters.start = this.minDate;
		this.updatePerformanceSummary([moment.utc(this.performanceSummaryChartFilters.start).startOf('day').unix(), moment.utc(this.performanceSummaryChartFilters.end).endOf('day').unix()]);
	}

	ngOnInit(): void {
		this.updateData();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.id) {
			this.id = changes.id.currentValue;
			this.updateData();
		}
	}

	updateUsageData(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;
		this.service.getUsageData(this.id, dates[0], dates[1]).subscribe((data: any) => {
			const consumedDurationFormatted = this.commonUtil.convertSecondsToHoursMinsSecs(+data.consumedUsageTimeVal);
			const generatedDurationFormatted = this.commonUtil.convertSecondsToHoursMinsSecs(+data.generatedUsageTimeVal);
			const dataTemp = [+data.billedVal.toFixed(2), consumedDurationFormatted, generatedDurationFormatted, +data.billedAvg.toFixed(2), +data.generated_energy.toFixed(2)]
			this.usageDataNoDataFound = sum(dataTemp) === 0;
			if (this.usageDataNoDataFound) return;
			this.usageDataExport = data.dailyData || [];
			this.usageDataChart = [
				{ label: this.translateService.instant('perf_analytic.total_billed_energy'), unit: 'kWh' },
				{ label: this.translateService.instant('perf_analytic.consumed_total_usage_time'), unit: '' },
				{ label: this.translateService.instant('perf_analytic.generated_total_usage_time'), unit: '' },
				{ label: this.translateService.instant('perf_analytic.daily_billed_energy'), unit: 'kWh' },
				{ label: this.translateService.instant('perf_analytic.total_generated_energy'), unit: 'kWh' }
			];
			this.usageData = dataTemp.map((value, i) => {
				return { label: this.usageDataChart[i].label, value: value, unit: this.usageDataChart[i].unit }
			});
			this.usageData[1] = [
				this.usageData[1], this.usageData[2]
			];
			delete this.usageData[2];
			this.usageData = Object.values(this.usageData);
		});
	}

	updatePerformanceSummary(dates: any) {
		if (!Array.isArray(dates))
			dates = dates.dateRange;
		this.service.getPerformanceSummary(+this.id, dates[0], dates[1]).subscribe((data: any) => {
			this.performanceSummaryExport = [];
			for (let idx in data.days) {
				if (data.dips[idx] || data.swells[idx] || data.overcurrent[idx]) {
					this.performanceSummaryExport.push([
						data.days[idx],
						data.dips[idx] ? 1 : 0,
						data.swells[idx] ? 1 : 0,
						data.overcurrent[idx] ? 1 : 0
					]);
				}
			}

			this.performanceSummaryChartData = data.moreInfoPerDay;
			this.performanceSummaryNoDataFound = !(sum(data.dips) + sum(data.swells) + sum(data.overcurrent));
			if (this.performanceSummaryNoDataFound) return;
			this.performanceSummaryChart = {
				labels: data.days,
				datasets: [{
					type: 'bar',
					label: this.translateService.instant('perf_analytic.dips'),
					backgroundColor: '#42A5F5',
					data: data.dips
				}, {
					type: 'bar',
					label: this.translateService.instant('perf_analytic.swells'),
					backgroundColor: '#66BB6A',
					data: data.swells
				}, {
					type: 'bar',
					label: this.translateService.instant('perf_analytic.overcurrent'),
					backgroundColor: '#FFCA28',
					data: data.overcurrent
				}]
			};
		});
	}

	onPerformanceSummaryClick(event: { originalEvent: PointerEvent, element: any, dataset: any[] }) {
		const targetEvent = this.performanceSummaryChartData[event?.element?._index] || {};
		this.onPerformanceSummaryClickEvent.emit({ targetEvent, index: event?.element?._index, datasetIndex: event?.element?._datasetIndex });
	}

	exportUsageData() {
		let columnNames = [
			this.translateService.instant('g.date'),
			this.translateService.instant('perf_analytic.billed_kwhrs'),
			this.translateService.instant('perf_analytic.consumed_total_usage_time'),
			this.translateService.instant('perf_analytic.generated_total_usage_time'),
			this.translateService.instant('perf_analytic.total_generated_energy')
		];
		let fileName = this.translateService.instant('perf_analytic.usage_data');
		const dataArr = [];
		for (let data of this.usageDataExport) {
			dataArr.push([
				moment.utc(+data.day * 1000).format('l'),
				data.event_consumed_energy,
				data.consumed_energy_duration,
				data.generated_energy_duration,
				data.event_generated_energy,
			]);
		}
		this.commonUtil.exportToCsv(columnNames, dataArr, fileName);
	}

	exportPerformanceSummary() {
		let columnNames = [
			this.translateService.instant('g.time'),
			this.translateService.instant('perf_analytic.dips'),
			this.translateService.instant('perf_analytic.swells'),
			this.translateService.instant('perf_analytic.overcurrent')
		];
		let fileName = this.translateService.instant('perf_analytic.performance_summary');
		this.commonUtil.exportToCsv(columnNames, this.performanceSummaryExport, fileName);
	}
}
