<div class="h-100 page">
	<div class="header-page">
		<app-non-logged-in-user-header></app-non-logged-in-user-header>
	</div>
	<div class="p-4 w-100 body-page">
		<div class="bg-white border-radius py-2 px-4 h-100 overflow-auto">
			<div class="col-md-12 text-center my-4">
				<strong>{{'terms.t1' | translate}}</strong>
			</div>
			<p>{{'terms.t2' | translate}}</p>

			<br />
			<p><strong>{{'terms.t3' | translate}}:</strong></p>

			<p><strong>{{'terms.t4' | translate}}</strong>{{'terms.t5' | translate}}</p>

			<p><strong>{{'terms.t6' | translate}}</strong>{{'terms.t7' | translate}}</p>

			<p><strong>{{'terms.t8' | translate}}</strong>{{'terms.t9' | translate}}</p>

			<br />
			<p><strong>{{'terms.t10' | translate}}:</strong></p>

			<p>{{'terms.t11' | translate}}</p>

			<br />
			<p><strong>{{'terms.t12' | translate}}:</strong></p>

			<p>{{'terms.t13' | translate}}</p>

			<br />
			<p><strong>{{'terms.t14' | translate}}:</strong></p>

			<p>{{'terms.t15' | translate}}</p>

			<ol>
				<li type="a">{{'terms.t16' | translate}}</li>
				<li type="a">{{'terms.t17' | translate}}</li>
			</ol>

			<br />
			<p><strong>{{'terms.t18' | translate}}:</strong></p>

			<p>{{'terms.t19' | translate}}</p>

			<br />
			<p><strong>{{'terms.t20' | translate}}:</strong></p>

			<p>{{'terms.t21' | translate}}</p>

			<br />
			<p><strong>{{'terms.t22' | translate}}</strong></p>

			<p><i>{{'terms.t23' | translate}}</i>{{'terms.t24' | translate}}</p>

			<p><i>{{'terms.t25' | translate}}</i>{{'terms.t25b' | translate}}</p>

			<br />
			<p><strong>{{'terms.t26' | translate}}</strong></p>

			<p>{{'terms.t27' | translate}}</p>

			<br />
			<p><strong>{{'terms.t28' | translate}}:</strong></p>

			<p>{{'terms.t29' | translate}}</p>

			<br />
			<p><strong></strong></p>

			<p>{{'terms.t30' | translate}}</p>

			<p>{{'terms.t31' | translate}}</p>

			<p>{{'terms.t32' | translate}}</p>

			<p>{{'terms.t33' | translate}}</p>

			<p>{{'terms.t34' | translate}}</p>

			<br />
			<p><strong>{{'terms.t35' | translate}}:</strong></p>

			<p>{{'terms.t36' | translate}}</p>

			<p>{{'terms.t37' | translate}}</p>

			<p>{{'terms.t38' | translate}}</p>

			<p>{{'terms.t39' | translate}}</p>

			<div class="my-4 text-center">
				<button pButton pRipple [label]="'g.accept' |	translate" type="button" class="me-2"
					(click)="confirmUserAgreement()"></button>
				<button type="button" class="p-button-secondary" (click)="declineUserAgreement()" pButton pRipple
					[label]="'g.decline' | translate"></button>
			</div>
		</div>
	</div>
</div>