import { AuthComponent } from './auth/auth.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	TranslateModule,
	TranslateLoader,
	TranslateService,
} from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ValidationService } from './shared/services/validation.service';
import { TranslateFileLoader } from './TranslateFileLoader';
import { CommonService } from './shared/services/common.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { CommonDataService } from './shared/services/common-data.service';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './app-components/footer/footer.component';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ToastModule } from 'primeng/toast';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { NavigationService } from './shared/services/navigation.service';
import { ConfirmUserEmailComponent } from './auth/confirm-user-email/confirm-user-email.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BecomeUserComponent } from './auth/become-user/become-user.component';
import { AgGridModule } from 'ag-grid-angular';
import { GoogleAnalytics } from './shared/services/google-analytics.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LandingPageComponent } from './auth/landing-page/landing-page.component';
import { UploadFwFileComponent } from './business/admin/upload-fw-file/upload-fw-file.component';
import { FileUploadModule } from 'primeng/fileupload';
import { SmartRebatesSitesComponent } from './app/business/admin/smart-rebates-sites/smart-rebates-sites.component';
import { HttpClientXsrfModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}
@NgModule({
	declarations: [
		FooterComponent,
		AppComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthComponent,
		ConfirmUserEmailComponent,
		TermsAndConditionsComponent,
		BecomeUserComponent,
		LandingPageComponent,
		UploadFwFileComponent,
		SmartRebatesSitesComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		FileUploadModule,
		NgSelectModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			},
		}),
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-Token',
		}),
		BrowserAnimationsModule,
		CoreModule,
		ButtonModule,
		RippleModule,
		SharedModule,
		ToastModule,
		AgGridModule,
		BsDatepickerModule.forRoot()
	],
	providers: [
		TranslateService,
		ValidationService,
		NavigationService,
		CommonService,
		CommonDataService,
		GoogleAnalytics,
		BsDatepickerConfig
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		// Add an icon to the library for convenient access in other components
		library.addIconPacks(fas, far, fab);
	}
}
