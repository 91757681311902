import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements OnInit {

	successLanding:boolean = false;

	constructor(private router: Router, private location:Location ) { }

	goLogin(){
		this.router.navigate(['/login'])
	}

	ngOnInit(): void {
		this.successLanding =false;
		// console.log((this.location.getState() as {example: boolean}));
		if((this.location.getState() as {example: boolean}).example){
			this.successLanding = (this.location.getState() as {example: boolean}).example
		}
		if(this.successLanding === false){
			this.router.navigate(['login']);
		}
	}

}