<mgl-map *ngIf="latitude !== undefined && longitude !== undefined" [style]="'mapbox://styles/mapbox/light-v10'"
	[zoom]="zoom" [center]="center" (mapClick)="setCurrentPosition($event)">
	<mgl-control position="top-right" mglGeocoder (result)="onGeocoderResult($event)" *ngIf="clickEnabled">
	</mgl-control>
	<ng-container *ngFor="let dot of dots">
		<ng-container *ngIf="showPopup; else markerOnly">
			<mgl-marker #myMarker [lngLat]="[dot.longitude, dot.latitude]">
				<div class="ring-container">
					<div class="ringring" [ngClass]="{'blueringring': !dot.opened, 'greenringring': dot.opened}"></div>
					<div class="circle" [ngClass]="{'bluecircle': !dot.opened, 'greencircle': dot.opened}"></div>
				</div>
			</mgl-marker>
			<mgl-popup [marker]="myMarker" [closeButton]="false" [offset]="[3,-30]" (close)="dot.opened = false"
				(open)="dot.opened = true">
				<div [ngSwitch]="dotsType">
					<div *ngSwitchCase="'sites'" class="p-2">
						<a class="btn btn-link" [routerLink]="['/', dot.customer_id, dot.id]">
							<h4 class="font-weight-bold">{{dot.name}}</h4>
						</a>
						<div>{{dot.address}}</div>
						<hr>
						<div><b class="pr-1">{{dot.devices_count || 0}}</b> {{'site.iotemeter_devices' | translate}}
						</div>
					</div>
				</div>
			</mgl-popup>
		</ng-container>
		<ng-template #markerOnly>
			<mgl-marker #myMarker [lngLat]="[dot.longitude, dot.latitude]">
				<div class="ring-container">
					<div class="ringring" [ngClass]="{'blueringring': !dot.opened, 'greenringring': dot.opened}"></div>
					<div class="circle" [ngClass]="{'bluecircle': !dot.opened, 'greencircle': dot.opened}"></div>
				</div>
			</mgl-marker>
		</ng-template>
	</ng-container>
</mgl-map>