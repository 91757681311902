<app-sct-dialog [dialog]="dialog">
	<div dialog-content>
		<div *ngIf="showSharingOption">
			{{'tags.sharing_options' | translate}}:
			<ng-select [items]="tagSharingOptions" bindLabel="text" bindValue="id" placeholder="{{'g.select_one' | translate}}" name="shareOption" [(ngModel)]="tempTag.sharing_option" [clearable]="false"></ng-select>
		</div>
		<div *ngIf="!addToSite && isSCTuser" class="margin-top">
			<p-checkbox [(ngModel)]="keepInReplacement" name="keep_in_replacement"
				[label]="'tags.keep_tag_in_replacement' | translate" [binary]="true"></p-checkbox>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-primary" [disabled]="!tempTag.sharing_option" (click)="dialog.callback && dialog.callback(0)">{{'tags.add_the_tag' | translate}}</button>
		</div>
	</div>
</app-sct-dialog>


<ng-select
	[multiple]="isMultiple"
	[addTag]="checkAddTag"
	[items]="items"
	(add)="add($event)"
	(change)="change($event)"
	(remove)="removeTag($event)"
	[hideSelected]="hideSelected"
	[placeholder]="placeholder"
	bindLabel="tag_name"
	[clearable]="false"
	[(ngModel)]="tag"
	*ngIf="items.length; else tagsText"
	appendTo="{{isFromDialog ? 'body': ''}}"
>
	<ng-template ng-label-tmp let-tag="item" let-clear="clear">
		<span [ngClass]="{'replacement-tag': checkReplacementTag(tag)}">
			<span class="ng-value-icon left" (click)="clear(tag)" aria-hidden="true">×</span>
			<span class="ng-value-label">{{tag.tag_name}}</span>
		</span>
	</ng-template>
</ng-select>
<ng-template #tagsText>
	<div class="d-flex flex-row">
		<input type="text" class="form-control" [(ngModel)]="tagText" placeholder="{{'tags.create_a_tag' | translate}}">
		<button type="button" class="btn btn-primary ml-2" [disabled]="tagText.trim().length < 3" (click)="add(tagText)">{{'g.add' | translate}}</button>
	</div>
</ng-template>