export enum PermissionType {
	NONE,
	NO_ACCESS,
	WRITE,
	READ
}

export const SYSTEM_USER_ID = 0xFFFFFFFF;
export const NEW_DEVICES_SITE = 1;
export const SCT_INVENTORY_SITE_ID = 2;
export const SCT_CUSTOMER_ID = 1;
export const FLOAT_VALUE_LIMIT = 3.4E+38;
export const TESTING_TIMER_WAIT = 45000; //45s
export enum deviceTypes {
	TYPE1,
	TYPE2
}
