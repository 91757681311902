<div class="d-flex justify-content-between">
	<div>
		<h4>{{title}}</h4>
	</div>
	<div>
		<button class="btn margin-bottom btn-primary" (click)="showActionModal('add')">
			{{'attachments.add' | translate}}
		</button>
	</div>
</div>

<div *ngIf="showAttachments(); else noAttachments">
	<div class="table-responsive">
		<table class="table table-striped">
			<thead>
				<tr>
					<th>{{'g.file_name' | translate}}</th>
					<th>{{'g.time' | translate}}</th>
					<th>{{'login_register.user_name' | translate}}</th>
					<th>{{'attachments.note' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let id of attachments | keys" class="margin-bottom-sm">
					<td class="w-25"><a href="javascript:;" (click)="downloadAttachment(id)">{{attachments[id].file_name}}</a></td>
					<td>{{attachments[id].created_at | date:'MM/dd/yyyy hh:mm:ss'}}</td>
					<td>{{attachments[id].user_name}}</td>
					<td class="w-50">{{attachments[id].note}}</td>
					<td>
						<div class="d-flex">
							<button title="{{'attachments.edit_note' | translate}}" class="btn btn-primary mx-1" (click)="showActionModal('edit', id)"><i class="fa fa-pencil"></i></button>
							<button *ngIf="attachmentsService.hasDeleteAccess" title="{{'g.delete' | translate}}" class="btn btn-danger mx-1" (click)="showActionModal('delete', id)"><i class="fa fa-trash"></i></button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ng-template #noAttachments>
	<div class="text-center margin-bottom-lg text-muted">
		<h5>{{'attachments.no_attachments' | translate}}</h5>
	</div>
</ng-template>

<app-sct-dialog [dialog]="addAttachmentsDialog" title="{{'attachments.attachments' | translate}}" [hasOkButton]="false" [hasCloseButton]="false">
	<div dialog-content>
		<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
			<div *ngIf="showValidationErrors()" class="alert alert-danger" role="alert">
				<h6 class="alert-heading">{{'g.errors' | translate}}</h6>
				<ul>
					<li *ngIf="selectedFilesValidations.min_count">{{'attachments.files_min_count_err' | translate}}</li>
					<li *ngIf="selectedFilesValidations.max_count">{{'attachments.files_max_count_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFilesCount}})</li>
					<li *ngIf="selectedFilesValidations.extension">{{'attachments.extension_err' | translate}}: ({{attachmentsService.allowedUploadFilesExt.join(', ')}})</li>
					<li *ngIf="selectedFilesValidations.size">{{'attachments.size_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFileSize / attachmentsService.mbBytes}} MB)</li>
					<li *ngIf="selectedFilesValidations.note">{{'attachments.note_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxNoteCharacters}})</li>
				</ul>
			</div>
			<div class="row mb-2">
				<label class="col-md-4 col-form-label">
					{{'attachments.choose_files' | translate}}
				</label>
				<div class="col-md-6">
					<input #uploadAttachmentsBtn type="file" class="form-control" [accept]="attachmentsService.allowedUploadFilesExt.join(', ')" id="actual-btn" name="files" multiple (change)="selectFiles($event)">
				</div>
			</div>
			<div class="row mb-2">
				<label class="col-md-4 col-form-label">
					{{'attachments.note' | translate}}
				</label>
				<div class="col-md-6">
					<textarea required rows="5" [maxlength]="attachmentsService.maxNoteCharacters" class="form-control" [(ngModel)]="note" name="note" placeholder="{{'g.max_limit' | translate:{'max': attachmentsService.maxNoteCharacters} }}"></textarea>
				</div>
			</div>
		
			<div class="mt-5 d-flex flex-row-reverse">
				<button class="btn btn-light" (click)="addAttachmentsDialog.visible = false">{{'g.close' | translate}}</button>	
				<button class="btn btn-primary me-2" (click)="addAttachments()" [disabled]="!selectedFiles.length">{{'g.submit' | translate}}</button>
			</div>
		</form>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="deleteAttachmentsDialog" title="{{'g.delete' | translate}} {{'attachments.attachments' | translate}}" [hasOkButton]="false" [hasCloseButton]="false">
	<div dialog-content>
		<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
			<p>{{'attachments.delete_attachment' | translate}}</p>
		</form>
		<div class="mt-2 d-flex justify-content-end">
			<button class="btn btn-danger" (click)="deleteAttachment()">{{'g.delete' | translate}}</button>	
			<button class="btn btn-light ml-2" (click)="deleteAttachmentsDialog.visible = false">{{'g.close' | translate}}</button>	
		</div>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="editNoteDialog" title="{{'g.edit' | translate}} {{'attachments.attachments' | translate}}" [hasOkButton]="false" [hasCloseButton]="false">
	<div dialog-content>
		<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
			<div class="row mb-2" *ngIf="editedNoteAttachment">
				<label class="col-md-4 col-form-label">
					{{'attachments.note' | translate}}
				</label>
				<div class="col-md-6">
					<textarea required rows="5" [maxlength]="attachmentsService.maxNoteCharacters" class="form-control" [(ngModel)]="editedNoteAttachment.note" name="note"></textarea>
				</div>
			</div>
		</form>
		<div class="mt-2 d-flex justify-content-end">
			<button class="btn btn-primary" (click)="editAttachmentNote()">{{'g.edit' | translate}}</button>	
			<button class="btn btn-light ml-2" (click)="editNoteDialog.visible = false">{{'g.close' | translate}}</button>	
		</div>
	</div>
</app-sct-dialog>

<app-sct-dialog [dialog]="successAttachmentsDialog" title="{{'attachments.success_attachments' | translate}}" [hasOkButton]="false" [hasCloseButton]="false">
	<div dialog-content>
		<table class="table table-striped">
			<thead>
				<th>{{'g.file_name' | translate}}</th>
				<th>{{'g.status' | translate}}</th>
			</thead>
			<tbody>
				<tr *ngFor="let file of selectedFiles">
					<td>{{file.name}}</td>
					<td *ngIf="!successAttachments[file.name]">
						<div class="spinner-border spinner-border-sm text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</td>
					<td *ngIf="successAttachments[file.name]" class="text-success"><i class="fa fa-check"></i></td>
				</tr>
			</tbody>
		</table>
	</div>
</app-sct-dialog>