import { Component, OnInit, ViewChild, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import * as _ from 'lodash';
import {SelectionModel} from '@angular/cdk/collections';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {

	@Input() elements: any[] = [];
	@Input() name: string = 'data';
	@Input() pagination: boolean = true;
	@Input() withShadow: boolean = false;
	@Input() showExport: boolean = true;
	@Input() hasCheckboxColumn: boolean = false;
	@Input() showFirstLastButtons: boolean = true;
	@Input() pageSize: number = 20;
	@Input() pageSizeOptions: number[] = [10, 20, 30];
	@Input() columnNames: any[] = [];
	@Input() idColumnName: string = 'id';
	@Input() size: string = 'lg';

	displayedColumns: string[] = [];
	dataSource = new MatTableDataSource<any>([]);

	selection = new SelectionModel<any>(true, []);

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	@Output() actionEvent = new EventEmitter<{action: string, element: any}>();
	@Output() selectionChanged = new EventEmitter<any>();

	constructor(
		private commonService: CommonService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		if(this.hasCheckboxColumn)
			this.displayedColumns = ['select'].concat(_.map(this.columnNames, 'key'));
		else
			this.displayedColumns = _.map(this.columnNames, 'key');
	}

	ngOnChanges(changes: any) {
		if(changes.elements) {
			this.dataSource = new MatTableDataSource<any>(this.elements);
			if(this.pagination)
				this.dataSource.paginator = this.paginator;
		}

		if(this.columnNames && changes['columnNames']) {
			this.columnNames.forEach((col) => {
				col.name = this.translateService.instant(col.name);
			});
		}
	}

	emitAction(action: any, element: any) {
		this.actionEvent.emit({action, element});
	}

	exportCsv() {
		this.commonService.exportTableToCsv(this.columnNames, this.elements, this.name);
	}

	toggleSelection(master = false, row = null) {
		if(master) {
			this.masterToggle();
		} else {
			this.selection.toggle(row);
		}
		this.selectionChanged.emit(_.map(this.selection.selected, this.idColumnName));
	}

	isAllSelected() {
		if(!this.dataSource.paginator)
			return true;
		let numSelected = this.selection.selected.length;
		let numRows = this.dataSource.paginator.pageSize;
		if(numRows > this.dataSource.data.length) {
			numRows = this.dataSource.data.length;
		}
		return numSelected === numRows;
	}

	clearSelection() {
		this.selection.clear();
		this.selectionChanged.emit([]);
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ? this.selection.clear() : this.selectRows();
	}

	selectRows() {
		let pageSize = this.dataSource?.paginator?.pageSize || 0;
		let pageIndex = this.dataSource?.paginator?.pageIndex || 0;
		let startIndex = pageIndex*pageSize;
		for (let index = startIndex; index - startIndex  < pageSize; index++) {
			if(this.dataSource.data[index])
				this.selection.select(this.dataSource.data[index]);
		}
	}
}