import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SitesService } from '@app/business/sites/sites.service';
import { UsersService } from '@app/business/users/users.service';
import { SideMenuService } from '@app/core/services/side-menu/side-menu.service';
import { SCTDialog, SCTDialogAction } from '@app/shared/sct-dialog/sct-dialog.model';
import _ from 'lodash'
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import moment from 'moment';

@Component({
	selector: 'app-smart-rebates-sites',
	templateUrl: './smart-rebates-sites.component.html',
	styleUrls: ['./smart-rebates-sites.component.css']
})
export class SmartRebatesSitesComponent implements OnInit {
	downloadConsumptionsReportSubscription!: Subscription;

	data: any[] = [];
	selectedYear: number = new Date().getFullYear();
	selectedSiteId: number = 0;
	quarters = [
		{id: 0, value: 'All Year'},
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];
	selectedQuarter = this.quarters[0].id;
	years: number[] = [];
	selectedStartDate: any = 0;

	consumptionsReportDialog: SCTDialog = {
		header: 'site.consumptions_report',
		width: '450px',
		callback: (action: SCTDialogAction) => {
			this.consumptionsReportDialog.visible = false;
			if (action === SCTDialogAction.CANCEL) {
				this.selectedStartDate = 0;
				return;
			}

			this.downloadConsumptionsReport();
		}
	};

	constructor(
		private router: Router,
		private sitesService: SitesService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private translate: TranslateService,
		private notificationMessage: SctToastService,
	) { }

	ngOnInit() {
		this.sideMenuService.show(false);
		if (!(this.usersService.hasAccessFunction('official_reporting') || this.usersService.hasAccessFunction('site_management')))
			return this.router.navigate(['/unauthorized']);
		return this.getSmartRebatesSitesInfo();
	}

	getSmartRebatesSitesInfo() {
		this.sitesService.getSmartRebatesSitesInfo().subscribe((data: any) => {
			this.data = data;
		})
	}

	downloadConsumptionsReport() {
		const selectedStartDate = moment(this.selectedStartDate || 0).utc().startOf('day').unix();

		this.downloadConsumptionsReportSubscription = this.sitesService.downloadConsumptionsReport(this.selectedYear, this.selectedQuarter, this.selectedSiteId, selectedStartDate).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					this.notificationMessage.showMessage(this.translate.instant('orders.not_sr_site'));
					break;
				case 3:
					this.notificationMessage.showMessage(this.translate.instant('orders.no_devices_founded'));
					break;
				case 4:
					this.notificationMessage.showMessage(this.translate.instant('sr_reports.no_data'));
					break;
				default:
					this.downloadReport(res);
					break;
			}
		});
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	showConsumptionsReport(site: any) {
		this.selectedSiteId = site.id;
		const startDate = new Date(site.installation_date);
		this.getAllYearsFromDate(startDate);
		this.consumptionsReportDialog.visible = true;
	}

	getAllYearsFromDate(startDate: Date) {
		if (!startDate)
			return
		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];
		for (let year = startYear; year <= currentYear; year++) {
				years.push(year);
		}
		if (!years.length)
			years.push(currentYear);
		this.selectedYear = years[years.length -1];
		this.years = years;
	}

	ngOnDestroy() {
		if (this.downloadConsumptionsReportSubscription)
			this.downloadConsumptionsReportSubscription.unsubscribe();
	}
}
