import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SCTMenuItem } from '../dropdown/dropdown-model';
import { UsersService } from '@app/business/users/users.service';
import { ScrollerTabDirective } from '../directives/scrolling/tab-scroller.directive';
import _ from 'lodash';
import { PermissionType } from '@app/core/model/constants';

@Component({
	selector: 'sct-tab-menu',
	templateUrl: './sct-tab-menu.component.html',
	styleUrls: ['./sct-tab-menu.component.scss']
})
export class SCTTabMenuComponent implements OnInit, AfterViewInit {
	@Input() tabMenuItems: any = [];
	@Input() permissions?: any;
	@Input() verticalIcon?: boolean;
	@Input() iconSize: string = '18px';
	@Input() cssClass: string = '';
	@Input() cssClassForLink: string = '';
	@Input() sctTabsCssClass: string = '';
	@Input() cssClassForText: string = '';
	@Input() customActiveColor?: string;
	@Input() tabWidth: string = 'inherit';
	@Input() sctTabContainer: string = '';
	@Input() roundedTop: boolean = false;
	@Input() queryParams?: any;

	@Input() activeTab?: any;
	indexDefaultActiveTab = 0;


	@ViewChild('scrollerTab') scrollerTab!: ScrollerTabDirective;
	canScrollStart: boolean = false;
	canScrollEnd: boolean = false;
	hideArrow = false;
	pagesName: any[] = [];
	activePage: boolean | string | undefined = false;

	constructor(
		private translateService: TranslateService,
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
		private cdref: ChangeDetectorRef
	) { }

	ngAfterViewInit(): void {
		//this.scrollerTab.canScroll.subscribe((scroll: any) => {
		//	this.canScrollEnd = scroll.canScrollEnd;
		//	this.canScrollStart = scroll.canScrollStart;
		//	this.hideArrow = !this.canScrollEnd && !this.canScrollStart;
		//	this.cdref.detectChanges();
		//});
	}

	ngOnInit(): void {
		this.pagesName = _.map(this.tabMenuItems, 'id');
		if (this.activeTab && this.pagesName.includes(this.activeTab)) {
			this.activePage = this.activeTab;
		} else {
			this.activePage = (this.pagesName.includes(this.route.snapshot.firstChild?.data.pageName) ? this.route.snapshot.firstChild?.data.pageName : this.pagesName[0]);
			this.router.events.subscribe(() => {
				this.activePage = (this.pagesName.includes(this.route.snapshot.firstChild?.data.pageName) ? this.route.snapshot.firstChild?.data.pageName : this.pagesName[0]);
			});
		}

		this.tabMenuItems.forEach((item: any) => {
			if ((!item.showIf && !item.permission && !item.hasAccessFunction) ||
				(item.showIf && item.showIf() || item.permission && this.usersService.hasAccessPermission(this.permissions, item.permission, item.permissionAccessLevel || PermissionType.READ) ||
					item.hasAccessFunction && this.usersService.hasAccessFunction(item.hasAccessFunction))) {
				item.visible = true;
			} else {
				item.visible = false;
			}
			if (item.showIf) {
				item.visible = item.showIf();
			}
			if (item.label?.includes('|')) {
				item.label = this.translateService.instant(item.label.split('|')[0]);
			}
		});

	}
}
