import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { SideMenuService } from '@app/core/services/side-menu/side-menu.service';
import { SCTFormBuilderField, SCTFormBuilder, InputType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { NavigationService } from '@app/shared/services/navigation.service';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { UsersService } from '../users.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

	captchaEnabled: boolean = false;
	@Input() userId = 0;
	@Input() userLoggedIn = false;
	@Input() options = {
		hideTitle: false,
		forced: false
	};
	formFields: SCTFormBuilderField[] = [];
	form!: SCTFormBuilder;
	invalidFields: any = {};
	triesLimitReachedCaptcha = 0;
	@Input() isImportedInAnotherPage = false;
	@Output() submitChanges: EventEmitter<any> = new EventEmitter();

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		public notificationMessage: SctToastService,
		private navigation: NavigationService,
		private translateService: TranslateService,
		private cookieService: CookieService
	) {
		this.sideMenuService.show(false);
	}

	ngOnInit() {
		if (this.userLoggedIn) {
			this.prepareFormFields();
			return;
		}
		this.captchaEnabled = !this.userLoggedIn || !this.cookieService.get('userSession');
		this.prepareFormFields();
	}

	logContactUs(data: any) {
		if (this.userId)
			data.userId = this.userId;

		this.usersService.logContactUs(data).subscribe(
			(data: any) => {
				if (data == 6 && this.form.updateCaptcha) {
					++this.triesLimitReachedCaptcha;
					if (this.triesLimitReachedCaptcha < 3) {
						this.notificationMessage.showMessage('translate|g.invalid_captcha');
					}
					this.form.updateCaptcha();
				} else {
					this.notificationMessage.showMessage('translate|contact_us.thank_you_for_contacting_us', 'success');
					if (this.isImportedInAnotherPage)
						this.submitChanges.emit();
					else
						this.router.navigate(['/']);
				}
			}
		);
	}

	prepareFormFields() {
		this.formFields = [
			{
				name: 'subject',
				label: 'contact_us.subject',
				type: InputType.TEXT,
				isRequired: true,
			},
			{
				name: 'body',
				label: 'contact_us.body',
				type: InputType.TEXT_AREA,
				isRequired: true,
				placeholder: this.translateService.instant('contact_us.your_enquiry')
			}
		];

		this.form = {
			fields: this.formFields,
			callback: (data: any) => this.logContactUs(data),
			captchaEnabled: this.captchaEnabled,
			captchaExpired: () => {
				this.notificationMessage.showMessage('translate|login_register.verification_is_expired');
			},
			cancelButtonCss: 'btn-secondary'
		};
	}

	goBack() {
		if (this.options.forced)
			return this.router.navigate(['/']);
		return this.navigation.goBack('/');
	}
}