<ng-container *ngIf="dialog">
	<p-dialog [header]="(dialog.header || '') | translate" [resizable]="dialog.resizable || false"
		[position]="dialog.position || 'center'" [closable]="dialog.showCloseIcon || true"
		[dismissableMask]="dialog.dismissableMask || false" [modal]="dialog.isModal || true"
		[breakpoints]="dialog.breakpoints || breakpoints" [style]="{width: dialog.width ||  width}"
		[(visible)]="dialog.visible" [showHeader]="dialog.showHeader || true" appendTo="body"
		(onHide)="dialog.callback && dialog.callback(SCTDialogAction.CANCEL)"  [contentStyle]="{'overflow': dialog.visible ? (dialog.overFlow? dialog.overFlow : 'auto'): null}">
		<ng-content select="[dialog-content]"></ng-content>
		<ng-container *ngIf="!dialog.hideFooter && dialog.callback">
			<p-divider></p-divider>
			<div class="w-100 d-flex justify-content-end mt-3">
				<button pButton pRipple *ngIf="hasOkButton" label="{{okText | translate}}" (click)="dialog.callback(SCTDialogAction.SAVE)"
					class="{{okBtnColor}}" [disabled]="disableConfirm"></button>
				<ng-content select="[add-more-btns]"></ng-content>
				<button pButton pRipple type="button" *ngIf="hasCloseButton"
					(click)="dialog.callback(SCTDialogAction.CANCEL)" label="{{cancelText | translate}}"
					class="p-button-secondary ms-2"></button>
			</div>
		</ng-container>
	</p-dialog>
</ng-container>
