import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';
import moment from 'moment';

// @TODO: Discard `${schema}Temp` schemas when all fields' modules are ready (enterprise, permissions, ..etc)
import validationRules from '../../../../../iot-emeter-common/validations/validationRules.json';

@Injectable()
export class ValidationService {
	readonly validationRules: any = validationRules;
	ipAddressRegex = { type: 'regex', patt: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ };

	constructor(
		private translateService: TranslateService,
		private commonService: CommonService
	) { }

	private publicMailDomainsList = [
		'gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'hotmail.co.uk', 'hotmail.fr', 'msn.com', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'comcast.net',
		'yahoo.co.uk', 'yahoo.com.br', 'yahoo.co.in', 'live.com', 'rediffmail.com', 'free.fr', 'gmx.de', 'web.de', 'yandex.ru', 'ymail.com', 'libero.it',
		'outlook.com', 'uol.com.br', 'bol.com.br', 'mail.ru', 'cox.net', 'hotmail.it', 'sbcglobal.net', 'sfr.fr', 'live.fr', 'verizon.net', 'live.co.uk',
		'googlemail.com', 'yahoo.es', 'ig.com.br', 'live.nl', 'bigpond.com', 'terra.com.br', 'yahoo.it', 'neuf.fr', 'yahoo.de', 'alice.it', 'rocketmail.com',
		'att.net', 'laposte.net', 'facebook.com', 'bellsouth.net', 'yahoo.in', 'hotmail.es', 'charter.net', 'yahoo.ca', 'yahoo.com.au', 'rambler.ru',
		'hotmail.de', 'tiscali.it', 'shaw.ca', 'yahoo.co.jp', 'sky.com', 'earthlink.net', 'optonline.net', 'freenet.de', 't-online.de', 'aliceadsl.fr',
		'virgilio.it', 'home.nl', 'qq.com', 'telenet.be', 'me.com', 'yahoo.com.ar', 'tiscali.co.uk', 'yahoo.com.mx', 'voila.fr', 'gmx.net', 'mail.com',
		'planet.nl', 'tin.it', 'live.it', 'ntlworld.com', 'arcor.de', 'yahoo.co.id', 'frontiernet.net', 'hetnet.nl', 'live.com.au', 'yahoo.com.sg',
		'zonnet.nl', 'club-internet.fr', 'juno.com', 'optusnet.com.au', 'blueyonder.co.uk', 'bluewin.ch', 'skynet.be', 'sympatico.ca', 'windstream.net',
		'mac.com', 'centurytel.net', 'chello.nl', 'live.ca', 'aim.com', 'bigpond.net.au',
	];

	isPublicMail(email: string) {
		const mailDomain = email.split('@')[1];
		return this.publicMailDomainsList.includes(mailDomain);
	}

	async validateFields(data: any, name: string) {
		const invalidFields: any = {};
		if (this.validationRules[name]) {
			for (const field in data) {
				const rules = this.validationRules[name][field];
				if (!rules) {
					continue;
				}
				for (const rule of rules) {
					const res = this.dataValidator(rule, data[field]);
					if (res !== true) {
						if (!res)
							invalidFields[field] = {
								constraints: [rule.type],
								messages: [
									this.translateService.instant(
										'validation.' + rule.type
									),
								],
							};
						else
							invalidFields[field] = res;
					}
				}
			}
		}
		return invalidFields;
	}

	/**
	 * Run data validator
	 * @param rule
	 * @param value
	 * @returns
	 */
	dataValidator(rule: any, value: any) {
		let isValid = false;
		let validMin = true, validMax = true;

		switch (rule.type) {
			case 'netmask':
				if (!this.ipAddressRegex.patt.test(value)) {
					isValid = false;
					break;
				}
				const parts = value.split('.');
				let ones = '';
				for (const i of [0, 1, 2, 3]) {
					const part = parseInt(parts[i], 10);
					ones += this.decimal2binary(part, 8);
				}

				if (/^0/g.test(ones)) {
					isValid = false;
					break;
				}
				const rightTrimmed = ones.replace(/0+$/g, '');
				if (!rightTrimmed || rightTrimmed.includes('0')) {
					isValid = false;
					break;
				}
				isValid = true;
				break;
			case 'nonPublicMail':
				isValid = !this.isPublicMail(value);
				break;
			case 'password':
				isValid =
					(rule.allowEmpty && this.isEmpty(value)) ||
					(typeof value === 'string' && value.length > 4);
				break;
			case 'phone':
				isValid =
					(rule.allowEmpty && this.isEmpty(value)) ||
					/^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
						value,
					);
				break;
			case 'serialNumber':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (typeof (value) === "string") {
					isValid = /^((I|P)00(0[1-9]|10|11|12)[0-9][0-9]\d{5})$/.test(value);
				}
				break;
			case 'arrayOf':
				isValid = rule.allowEmpty && this.isEmpty(value);
				if (!isValid) {
					let validArray = true;
					switch (rule.subType) {
						case 'integers':
							for (const ele of value) {
								if (typeof ele != 'number') {
									validArray = false;
									break;
								}
							}
							break;
						case 'emails':
							if (value.length == 0) {
								validArray = false;
								break;
							}
							for (const ele of value) {
								const email = ele;
								if (typeof (email) === "string") {
									validArray = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()) && email.length <= 255;
								} else {
									validArray = false;
									break;
								}
							}
							break;
					}
					isValid = validArray;
				}
				if (rule.subType != 'emails') {
					if ((!rule.min || (rule.min && rule.min <= value.length)) && (!rule.max || (rule.max && rule.max >= value.length))) {
						isValid = true;
					} else {
						isValid = false;
					}
				}
				break;
			case 'hex':
				if (this.isEmpty(value)) {
					if (rule.allowEmpty)
						isValid = true;
					break;
				}
				const regex = /\b[0-9A-Fa-f]+\b/gi;
				const strLen = value.length;

				if (typeof (rule.min) !== "undefined" && value < rule.min)
					validMin = false;
				if (typeof (rule.max) !== "undefined" && value > rule.max)
					validMax = false;

				if (validMin && validMax && regex.test(value))
					isValid = true;
				break;
			case 'integer':
			case 'uint_16':
			case 'int_32':
				if (rule.type == 'uint_16') {
					rule.min = 0;
					rule.max = 65535;
				} else if (rule.type == 'int_32') {
					rule.min = -2147483648;
					rule.max = 2147483647;
				}
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (value == parseInt(value)) {
					value = parseInt(value);

					let validMin = true,
						validMax = true,
						validStep = true;

					if (typeof rule.min !== 'undefined' && value < rule.min)
						validMin = false;
					if (typeof rule.max !== 'undefined' && value > rule.max)
						validMax = false;
					if (
						typeof rule.step !== 'undefined' &&
						value % rule.step != 0
					)
						validStep = false;

					if (validMin && validMax && validStep) isValid = true;
					if (rule.step && value % rule.step !== 0) {
						isValid = false;
					}
				}
				break;
			case 'email':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (typeof value === 'string') {
					isValid =
						/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim()) &&
						value.length <= 255;
				}
				break;
			case 'string':
				if(value)
					value = value.trim();
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}

				if (typeof value === 'string') {
					let validMin = true,
						validMax = true;
					const strLen = value.length;

					if (typeof rule.min !== 'undefined' && strLen < rule.min)
						validMin = false;
					if (typeof rule.max !== 'undefined' && strLen > rule.max)
						validMax = false;

					if (validMin && validMax) isValid = true;
				}
				break;
			case 'float':
				if (this.isEmpty(value) && (value === null || value == undefined || value == '')) {
					isValid = true;
					break;
				}
				if (value == parseFloat(value)) {
					const min = rule.min || Number.MIN_SAFE_INTEGER;
					const max = rule.max || Number.MAX_SAFE_INTEGER;

					value = parseFloat(value);

					let validMin = true, validMax = true, validStep = true;

					if (value < min)
						validMin = false;
					if (value > max)
						validMax = false;
					if (typeof (rule.step) !== "undefined" && (value % rule.step != 0))
						validStep = false;

					if (typeof rule.min !== 'undefined' && value < rule.min)
						validMin = false;

					if (typeof rule.max !== 'undefined' && value > rule.max)
						validMax = false;

					if (validMin && validMax && validStep)
						isValid = true;
				}
				break;
			case 'boolean':
				isValid = (typeof value === 'boolean' || value == 'true' || value == 'false' || this.isInteger(value));
				break;
			case 'hexString':
				if (rule.allowEmpty && (value === null || value == undefined || value == '')) {
					isValid = true;
					break;
				}

				if (typeof (value) === "string") {
					value = value.trim().replace(/:/g, "");
					isValid = /^[a-fA-F0-9]{12}$/.test(value);
				}
				break;
			case 'inArray':
				if (rule.allowEmpty && (value === null || value === undefined || value == '')) {
					isValid = true;
					break;
				}
				switch (rule.subType) {
					case 'integer':
						if (value == parseInt(value)) {
							value = parseInt(value);
						}
						break;
				}
				if (rule.values.indexOf(value) > -1)
					isValid = true;
				break;
			case 'notInArray':
				switch (rule.subType) {
					case 'float':
						if (value == parseFloat(value)) {

							value = parseFloat(value);

							if (rule.values.indexOf(value) == -1)
								isValid = true;
						}
						break;
				}
				break;
			case 'phone':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				isValid = /^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
				break;

			case 'time':
			case 'daysMask':
				const days = [0, 1, 2, 3, 4, 5, 6];
				if (typeof (value) === "object") {
					let withinDays = true;
					value.forEach(function (ele: any) {
						if (withinDays && days.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if (withinDays)
						isValid = true;
				}
				break;
			case 'dateRange':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				const date = value && ((typeof value === 'string' || value && typeof value === 'object') && new Date(value)
					|| (typeof value === 'number' && moment(value, rule.numberIn || 'ms').toDate()));

				if (!moment.isDate(date)) {
					isValid = false
					break;
				}

				if (rule.min && !moment(date).subtract(1, 'days').isAfter(rule.min))
					validMin = false;
				if (rule.max && !moment(date).add(1, 'days').isBefore(rule.max))
					validMax = false;

				if (typeof rule.minYear === 'number') {
					if (rule.minYear < 0 && moment(date).isBefore(moment().subtract(rule.minYear * -1, 'years'))) {
						validMin = false;
					} else if (rule.minYear > 0 && moment(date).isBefore(moment().add(rule.minYear, 'years'))) {
						validMin = false;
					} else if (rule.minYear === 0 && moment(date).isAfter(moment())) {
						validMin = false;
					}
				}

				if (typeof rule.maxYear === 'number') {
					if (rule.maxYear > 0 && moment(date).isAfter(moment().add(rule.maxYear, 'years'))) {
						validMax = false;
					} else if (rule.maxYear < 0 && moment(date).isAfter(moment().subtract(rule.maxYear * -1, 'years'))) {
						validMax = false;
					} else if (rule.maxYear === 0 && moment(date).isAfter(moment())) {
						validMax = false;
					}
				}

				if (validMin && validMax)
					isValid = true;
				break;

			case 'ipAddress':
				const ipPatt = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (ipPatt.test(value))
					isValid = true;
				break;
			case 'arraySubset':
				if (typeof (value) === "object") {
					let withinDays = true;
					value.forEach(function (ele: any) {
						if (withinDays && rule.values.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if (withinDays)
						isValid = true;
				}
				break;

			case 'notAllowAllWhiteSpaces':
				if(value)
					value = value.trim();
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (typeof (value) === 'string') {
					const trimmedValue = value.trim();
					if (value.length !== trimmedValue.length || this.isEmpty(value)) {
						isValid = false;
					} else {
						isValid = true;
					}
				}
				break;
			case 'isDate':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				isValid = value && ((typeof value === 'string' || value && typeof value === 'object') && moment.isDate(new Date(value)) || (typeof value === 'number' && moment.isDate(moment(value, rule.numberIn || 'ms').toDate())))
				break;
			case 'object':
				if (rule.allowEmpty && this.isEmpty(value)) {
					isValid = true;
					break;
				}
				if (!rule.sub_fields)
					break;
				let valid = true;
				const invalidFields: any = {};
				for (const field in value) {
					const subField = rule.sub_fields.find((f: any) => f.name === field);
					if (!subField) continue;
					const subRules = subField.rules;
					for (const rule of subRules) {
						const res = this.dataValidator(rule, value[field]);
						if (res !== true) {
							valid = false;
							if (!res)
								invalidFields[field] = {
									constraints: [rule.type],
									messages: [
										this.translateService.instant(
											'validation.' + rule.type
										),
									],
								};
							else
								invalidFields[field] = res;
						}
					}
				};

				if (!valid)
					return invalidFields;
				isValid = true;
				break;
			case 'installation_date':
				const dateValue = new Date(value * 1000);
				isValid = (dateValue.getFullYear() - new Date().getFullYear() <= rule.max && dateValue.getFullYear() - new Date().getFullYear() >= rule.min);
				break;
			case 'regex':
				const pattern = new RegExp(rule.patt);
				isValid = pattern.test(value);
				break;
			case 'rtLogFreq':
				const rtLogFrequencyBin = this.decimal2binary(value, 8);
				const rt_log_frequency = this.binary2decimal(rtLogFrequencyBin.slice(1));

				if (value > 255 || rt_log_frequency > 127 || !rt_log_frequency)
					break;

				isValid = true;
				break;
		}
		return isValid;
	}

	decimal2binary(decimal: number, bitsMinLength = 1) {
		let result = (decimal >>> 0).toString(2);
		if (!result || result.length < bitsMinLength) {
			result = '0'.repeat(bitsMinLength - result.length) + result;
		}
		return result;
	}

	binary2decimal(bin: string) {
		return parseInt(bin, 2);
	}

	isEmpty(data: any) {
		if (typeof data == 'number' || typeof data == 'boolean') {
			return false;
		}
		if (typeof data == 'undefined' || data === null) {
			return true;
		}
		if (typeof data.length != 'undefined') {
			return data.length == 0;
		}
		let count = 0;
		for (const i in data) {
			if (data.hasOwnProperty(i)) {
				count++;
			}
		}
		return count == 0;
	}

	isPublicIP(ip: string) {
		if (!this.ipAddressRegex.patt.test(ip))
			return false;
		const parts = ip.split('.');
		const part0 = parseInt(parts[0], 10);
		const part1 = parseInt(parts[1], 10);
		return part0 != 10 && (part0 != 172 || part1 < 16 || part1 > 31) && (part0 != 192 || part1 != 168);
	}

	isValidNetmask(ip: string) {
		if (!this.ipAddressRegex.patt.test(ip)) {
			return false;
		}
		const parts = ip.split('.');
		let ones = '';
		for (const i of [0, 1, 2, 3]) {
			const part = parseInt(parts[i], 10);
			ones += this.decimal2binary(part, 8);
		}
		if (/^0/g.test(ones)) {
			return false;
		}
		const rightTrimmed = ones.replace(/0+$/g, '');
		if (!rightTrimmed || rightTrimmed.includes('0')) {
			return false;
		}
		return true;
	}

	async validateStaticIpSettings(data: any) {
		const invalidFields = await this.validateFields(data, 'ip_assignment');
		if (Object.keys(invalidFields).length)
			return invalidFields;

		const invalids = this.staticIpExtraValidator(this.commonService.ip2int(data.ip), this.commonService.ip2int(data.gateway), this.commonService.ip2int(data.netmask));
		for (const field of invalids) {
			invalidFields[field] = ['invalid'];
		}
		return invalidFields;
	}

	async isValidField(data: any, name: string) {
		const validationRes = await this.validateFields(data, name);
		return !Object.keys(validationRes).length;
	}

	private staticIpExtraValidator(ip: number, gateway: number, netmask: number) {
		let invalidFields = [];
		if (ip == 0 || gateway == 0 || netmask == 0) { //DHCP
			if (ip != 0) {
				invalidFields.push('ip');
			}
			if (gateway != 0) {
				invalidFields.push('gateway');
			}
			if (netmask != 0) {
				invalidFields.push('netmask');
			}
		} else { //Manual
			const ipStr = this.commonService.int2ip(ip);
			const gatewayStr = this.commonService.int2ip(gateway);
			const netmaskStr = this.commonService.int2ip(netmask);

			//Private IPs only
			if (this.isPublicIP(ipStr)) {
				invalidFields.push('ip');
			}
			if (this.isPublicIP(gatewayStr)) {
				invalidFields.push('gateway');
			}

			if (!invalidFields.includes('netmask')) {
				if (!this.isValidNetmask(netmaskStr)) {
					invalidFields.push('netmask');
				} else {
					const ipANDnetmask = ip & netmask;
					const netmaskANDgateway = netmask & gateway;
					if (ipANDnetmask != netmaskANDgateway) {
						invalidFields = invalidFields.filter(msg => msg != 'netmask')
						invalidFields.push('ip');
					}
				}
			}
		}
		return invalidFields;
	}

	isInteger(str: any) {
		let inp = str;
		if (typeof str == 'number') inp = str.toString();
		else if (typeof str != 'string') return false; // we only process strings!

		return /^-?\d+$/.test(inp);
	}
}
