<!-- Usage Data -->
<div class="mt-4">
	<p-card>
		<div class="px-4">
			<div class="row">
				<div class="col">
					<h4>
						{{'perf_analytic.usage_data' | translate}}
						<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}"
						(click)="exportUsageData()" *ngIf="!usageDataNoDataFound"></button>
					</h4>
				</div>
			</div>
			<div class="w-100">
				<sct-date-range [minDate]="minDate" [dateRange]="usageDataChartFilters" (onDatesChanged)="updateUsageData($event)"></sct-date-range>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-6 mb-3" *ngFor="let item of usageData">
						<div class="card">
							<div class="card-body main-bg">
								<div *ngIf="item?.length">
									<div class="row">
										<div class="col-6">
											<h4 class="card-title text-center">
												{{ item[0].value }} {{ item[0].unit }}
											</h4>
											<p class="card-text text-center"> {{ item[0].label }} </p>
										</div>
										<div class="col-6">
											<h4 class="card-title text-center">
												{{ item[1].value }} {{ item[1].unit }}
											</h4>
											<p class="card-text text-center"> {{ item[1].label }} </p>
										</div>
									</div>
								</div>
								<div *ngIf="!item?.length">
									<h4 class="card-title text-center">
										{{ item.value }} {{ item.unit }}
									</h4>
									<p class="card-text text-center"> {{ item.label }} </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-card>
</div>

<ng-content></ng-content>

<!-- Performance Summary -->
<div class="mt-4">
	<p-card>
		<div class="px-4">
			<div class="row">
				<div class="col">
					<h4>
						{{'perf_analytic.performance_summary' | translate}}
						<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}"
						(click)="exportPerformanceSummary()" *ngIf="!performanceSummaryNoDataFound"></button>
					</h4>
				</div>
			</div>
			<div class="w-100">
				<sct-date-range [minDate]="minDate" [dateRange]="performanceSummaryChartFilters" (onDatesChanged)="updatePerformanceSummary($event)"></sct-date-range>
			</div>
			<div class="row py-4">
				<p-chart type="bar" *ngIf="!performanceSummaryNoDataFound else noDataFound" [data]="performanceSummaryChart" (onDataSelect)="onPerformanceSummaryClick($event)">
				</p-chart>
			</div>
		</div>
	</p-card>
</div>

<ng-template #noDataFound>
	<p class="fw-bold">
		{{'g.no_data_available_for_the_selected_period' | translate}}
	</p>
</ng-template>