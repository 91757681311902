import { UsersService } from '@app/business/users/users.service';
import { SctToastService } from './../../shared/services/sct-toast.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-confirm-user-email',
	templateUrl: './confirm-user-email.component.html',
})
export class ConfirmUserEmailComponent implements OnInit {

	userId: number = 0;
	enc: string = '';
	confirmed: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private notificationMessage: SctToastService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(
			params => {
				this.userId = params['userId'] || 0;
				this.enc = params['enc'] || '';
				this.confirmUserEmail();
			}
		);
	}

	confirmUserEmail() {
		if (!this.userId || !this.enc)
			return;

		this.usersService.confirmUserEmail(this.userId, this.enc).subscribe((data: any) => {
			let url: any = ['/'];
			let extras: any = {};
			if (data.status == 1) {
				url = ['/reset-password', this.userId];
				extras.queryParams = { enc: data.token, isSetPass: 1 };
			}

			return this.router.navigate(url, extras).then(() => {
				if (data.status == 2 || data.status == 3 || data.status == 4) // already confirmed or deleted
					return this.notificationMessage.showMessage('translate|login_register.invalid_confirm_email_link', 'error', 60000);

				if (data.status == 1) {
					let thankYou = this.translateService.instant('g.thank_you'),
						confirmed = this.translateService.instant('login_register.email_has_been_confirmed');

					return this.notificationMessage.showMessage(confirmed, 'success', 60000, thankYou);
				}
			});
		});
	}
}