import { UsersService } from '@app/business/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { GatewayService } from '@app/core/services/gateway/gateway.service';
import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { SCTTable, ContentTypes, SCTRowEntity, SCTColumnEntity } from './table.model';
import { CommonService } from '../services/common.service';
import moment from 'moment';
import _ from 'lodash';


@Component({
	selector: 'sct-table',
	templateUrl: './sct-table.component.html',
	styleUrls: ['./sct-table.component.scss'],
})
export class SCTTableComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('dataTable') dataTable: any;
	@Input() table!: SCTTable;
	@Input() maxHeight: string = '500px';
	contentTypes = ContentTypes;
	selectedColumns!: { key: string, value: string }[];
	isLoaded: boolean = false;
	columnList: { key: string, value: string }[] = [];
	SelectionRows: any;
	filtersAppliedList: { colName: string, value: string }[] = [];
	originalRow: SCTRowEntity[] = [];
	rowStructure!: SCTRowEntity;
	filtersInput: any = {};
	data: any[] = [];
	altImage = '';
	tableColumns: any[] = [];
	onPageChangeData: any = {};
	scrollHeight = 100;
	originalSelectionColumns: any = [];


	constructor(private gateway: GatewayService, public common: CommonService, private translate: TranslateService, private usersService: UsersService) { }

	ngOnChanges(changes: any) {
		if (changes.table?.currentValue) {
			if (this.table) {
				this.table.body.structure.columns = this.table.body.structure.columns.filter((col, index) => !this.table.headers.list[index].hasNoAccess);
				this.table.headers.list = this.table.headers.list.filter((col) => !col.hasNoAccess);
				this.columnList = [];
				this.managePermissions();
				this.table.headers.list.forEach((col) => this.columnList.push({
					value: this.translate.instant(col.text || col.key || ''),
					key: col.text || col.key || '',
				}
				));
				this.selectedColumns = this.columnList;
				this.originalSelectionColumns = JSON.parse(JSON.stringify(this.columnList));
				this.rowStructure = this.table.body.structure;
				this.updateToggleShowColumn();
				this.init();
			}
		}
		if (changes.table?.previousValue && changes.table?.currentValue?.data) {
			this.managePermissions();
			this.updateTableData();
		}
	}

	ngOnInit(): void {
		if (this.table?.selection?.allowClearSelection) {
			this.table.selection.clear = () => this.SelectionRows = [];
		}
	}

	managePermissions() {
		if (this.table.permissions) {
			this.table.headers.list = this.table.headers.list.filter((col, index: number) => {
				if (!this.usersService.hasAccessPermission(this.table.permissions, col.permission || '')) {
					delete this.table.body.structure.columns[index];
					return;
				}
				return col;
			});
			this.table.body.structure.columns = this.table.body.structure.columns.filter((col) => col);
		}
	}

	init(): void {
		this.isLoaded = false;
		if (this.table?.requestsInfo) {
			this.gateway.get(this.table.requestsInfo.get?.url || '').subscribe(res => {
				if (res) {
					this.table.manipulateDataBeforeInit(res);
					this.manipulateData(res);
					this.isLoaded = true;
				}
			});
		} else if (this.table.data) {
			this.updateTableData();
		}
	}

	ngAfterViewInit(): void {
		this.filtersInput = this.dataTable?.filters;
	}

	getValue(event: any) {
		return event.target.value;
	}

	updateToggleShowColumn() {
		this.table.headers.list.forEach((item: any, index: number) => {
			const column = this.selectedColumns.find((col) => col.key === (item.text || item.key));
			if (item.hideColumn === !column) { return; }
			this.rowStructure.columns[index].hideColumn = !column;
			item.hideColumn = !column;
		});
	}

	selectionChange(event: any[]) {
		if (this.table.selection?.callback)
			this.table.selection?.callback(_.map(event, this.rowStructure.dataKey || 'rowId'));
	}

	private manipulateData(data: any[]) {
		if (!_.isArray(data)) {
			data = [];
		}
		data.forEach((item) => {
			const tempObject: any = {};
			const id = item[this.rowStructure.dataKey || 'id'];
			tempObject['rowId'] = id;
			this.rowStructure.columns.forEach((column: SCTColumnEntity) => {
				const path = column.data || [];
				path.push(column.key);
				let value = this.common.getDeepObject(path || [], item);
				if (typeof (value) === 'boolean') {
					value = value ? this.translate.instant('g.yes') : this.translate.instant('g.no');
				} else if (column.type === ContentTypes.LINK) {
					tempObject[`${column.key}_link`] = column.routerLink;
					if (column.routerLink?.includes(':')) {
						const data = column.routerLink.split(':');
						data.forEach((info) => {
							if (info) {
								if (info.split('/')[0]) {
									info = info.split('/')[0];
								}
								tempObject[`${column.key}_link`] = tempObject[`${column.key}_link`]?.replace(`:${info}`, item[info])
							}
						});
					}
				} else if (column.type === ContentTypes.IMAGE && !column.src) {
					column.src = value;
				} else if (column.type === ContentTypes.DROPDOWN) {
					tempObject.dropdownItems = column.dropdown?.items.map((i) => ({ ...i, id }));
				} else if (column.type === ContentTypes.DATE) {
					if (typeof value === 'string') {
						value = new Date(value);
					} else if (typeof value === 'number') {
						value = moment(value, column.dateAsNumberIn).toDate();
					}
				} else if (column.type === ContentTypes.TEXT) {
					if (typeof value === 'object') {
						value = JSON.stringify(value);
					}
				} else if (column.type === ContentTypes.CUSTOM_LINK) {
					const tempPath = column.data || [];
					let temp = this.common.getDeepObject([...tempPath, `${column.key}_labeled`], item);
					if (temp) {
						tempObject[`${column.key}_labeled`] = temp;
					} else {
						temp = this.common.getDeepObject([...tempPath, `${column.key}_link`], item);
						tempObject[`${column.key}_link`] = temp;
					}
				}
				if (column.customClass !== undefined) {
					const customClass = column.customClass(item);
					column.classes = [column.classes, customClass].join(' ');
				}
				tempObject[column.key] = value;
			});
			this.data.push(tempObject);
		});

		this.table.headers.list.forEach((header, index) => {
			this.tableColumns.push({
				header: this.translate.instant(header.key || '') || header.text,
				field: this.table.body.structure.columns[index].key,
			});
		})
		this.isLoaded = true;
	}

	getDateString(date: Date) {
		return date.toDateString();
	}

	getGroupNumber(number: number, roundFloat: number = 2) {
		const value = this.common.roundFloat(number, roundFloat);
		return value.toLocaleString(undefined, { useGrouping: true })
	}
	private updateTableData() {
		if (this.table.manipulateDataBeforeInit) {
			this.table.manipulateDataBeforeInit(this.table.data);
		}
		this.data = [];
		this.manipulateData(this.table.data);
	}

	isDate(date: any) {
		return moment(date).isValid();
	}

	resetTable() {
		this.selectedColumns = this.originalSelectionColumns;
		this.updateToggleShowColumn();
		this.dataTable.reset();
	}
}
