import { CommonDataService } from '@app/shared/services/common-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
// import { Chart } from 'chart.js';
import 'chartjs-plugin-zoom';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsersService } from './business/users/users.service';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { CustomHttpParams } from './shared/loader/custom-httpParam';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'frontend';
	versionKey: string = 'current-version';

	gitDataSub: Subscription = new Subscription();

	constructor(
		public translateService: TranslateService,
		private primengConfig: PrimeNGConfig,
		private httpClient: HttpClient,
		private usersService: UsersService,
		public commonDataService: CommonDataService,
		private router: Router
	) {
		translateService.addLangs(['en']);
		// this language will be used as a fallback when a translation isn't found in the current language
		translateService.setDefaultLang('en');

		//Chart.register(zoomPlugin);

	}
	ngOnInit(): void {
		this.primengConfig.ripple = true;

		// remove version from local storage on first load page
		localStorage.removeItem(this.versionKey);
		this.gitDataSub = this.httpClient.get('/getGitData', {
			observe: "body"
		}).subscribe((response: any) => {
			response = response?.response;
			if (!response)
				return;
			console.log(response);
			if (Object.keys(response).length > 0)
				console.log("%c" + JSON.stringify(response), "color: red; font-size: x-large");
		});
		if (this.usersService.isLoggedIn())
			this.usersService.getUserPermission(0, true).subscribe((res) => {
				this.usersService.setPermissionsOfUser(res);
			});

		this.router.events.pipe(
			filter(event => event instanceof NavigationStart)
		).subscribe(() => {
			let ver = localStorage.getItem(this.versionKey);
			this.httpClient.post('/user/updateCheck', { ver }, {
				observe: "body",
				params: new CustomHttpParams({ noUIBlock: true })
			}).subscribe((response: any) => {
				if (response.newVersion) {
					localStorage.setItem(this.versionKey, response.newVersion);
					if (ver)
						this.commonDataService.showRefreshMessage = true;
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.gitDataSub.unsubscribe();
	}
}
