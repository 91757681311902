declare var require: any;
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class TranslateFileLoader implements TranslateLoader {
	_currentLanguage: string | undefined;
	_currentData: any;
	constructor() { }

	/**
	 * Gets the translations from JSON file
	 * @param lang
	 * @returns {any}
	 */
	public getTranslation(lang: string): Observable<any> {
		// @Wael: check below
		// @todo: fix depricated function (create)
		return Observable.create((observer: any) => {
			if (this._currentLanguage == lang) {
				observer.next(this._currentData);
				observer.complete();
				return observer;
			}
			const data = require('../../../shared/translation/' + lang + '.json');
			this._currentData = data;
			this._currentLanguage = lang;
			observer.next(data);
			observer.complete();
		});
	}
}
