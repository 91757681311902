import { deviceTypes } from "@app/core/model/constants";
import moment, { DurationInputArg2 } from "moment";

export const getTodayDate = () => {
	return moment.utc(new Date()).startOf('day').toDate();
}

export const SCT_INVENTORY_SITE_ID = 2;

export const getLast3MonthsDate = () => {
	return moment.utc(new Date()).subtract(3, 'months').startOf('day').toDate();
}

export const getBeforeXPeriodDate = (amount: number, period: DurationInputArg2) => {
	return moment.utc(new Date()).subtract(amount, period).startOf('day').toDate();
}

export const generateRandomColor = () => {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

export const generateDatasetColor = (generatedColors: string[]) => {
	let color
	do {
		color = generateRandomColor();
	} while (generatedColors.includes(color));
	generatedColors.push(color);
	return color;
}

export const getFormattedDuration = (durationSec: number) => {
	durationSec = Number(durationSec);
	const durationMin = Math.floor(durationSec / 60);
	const DD = Math.floor(durationMin / (24 * 60));
	durationSec -= DD * (24 * 60);
	const h = Math.floor(durationSec / 3600);
	const m = Math.floor((durationSec % 3600) / 60);
	const s = Math.floor((durationSec % 3600) % 60);
	return (DD > 0 ? DD + 'D ' : '') + h + 'H ' + m + 'M ' + s + 'S';
};
export const getStartOfDay = (date: Date) => {
	return moment(date).set('seconds', 0).set('minutes', 0).set('hours', 0).toDate();
}

export const getMinDateBasedOnInstallationDate = (amount: number, installationDate: number, period: DurationInputArg2 = 'month') => {
	const date = getBeforeXPeriodDate(amount, period);
	if (moment(installationDate).isAfter(date))
		return getStartOfDay(moment(installationDate).toDate());

	return date;
}

export const calcVoltageReference = (value: number, type: 'divide' | 'multi') => {
	if (type === 'multi') {
		return value * Math.sqrt(3);
	} else if (type === 'divide') {
		return value / Math.sqrt(3);
	}
	return value;
}


export const toFixedWithoutRounding = (num: number, fixed: number): number => {
	return Math.floor(Math.pow(10, fixed) * num) / Math.pow(10, fixed);
}

export const getDeviceTypeBySerialNumber = (serialNumber: string) => {
	if (!serialNumber) return -1;
	if (serialNumber.indexOf('P') === 0)
		return deviceTypes.TYPE1;
	if (serialNumber.indexOf('I') === 0)
		return deviceTypes.TYPE2;
	if (serialNumber.indexOf('D10') === 0)
		return deviceTypes.TYPE1;
	if (serialNumber.indexOf('D20') === 0)
		return deviceTypes.TYPE2;
	return -1;
}
