import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './core/services/guard/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ConfirmUserEmailComponent } from './auth/confirm-user-email/confirm-user-email.component';
import { ChangePasswordComponent } from './business/users/change-password/change-password.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BecomeUserComponent } from './auth/become-user/become-user.component';
import { LoginNonConfirmedEmailComponent } from './business/users/login-non-confirmed-email/login-non-confirmed-email.component';
import { LandingPageComponent } from './auth/landing-page/landing-page.component';

const routes: Routes = [
	{
		path: 'non-confirmed-user-email/:userId', component: LoginNonConfirmedEmailComponent,
		canActivate: [AuthGuard],
		data: { noAuth: true }
	},
	{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
	{
		path: 'login', component: LoginComponent,
		canActivate: [AuthGuard],
		data: { noAuth: true }
	},
	{
		path: 'landing', component: LandingPageComponent,
		canActivate: [AuthGuard],
		data: { noAuth: true }
	},
	{ path: 'confirm-user-email', component: ConfirmUserEmailComponent },
	{
		path: 'reset-password/:userId',
		data: { pageName: "reset_password" },
		component: ChangePasswordComponent,
	},
	{
		path: 'change-expired-password',
		component: ChangePasswordComponent,
		data:
		{
			pageName: "force_change"
		}
	},
	{
		path: 'become-user/:token',
		component: BecomeUserComponent
	},
	{
		path: '',
		loadChildren: () =>
			import('./business/business.module').then((m) => m.BusinessModule),
	},
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
