import { SctToastService } from './../../shared/services/sct-toast.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@app/business/users/users.service';
import { SCTFormBuilderField, SCTFormBuilder, InputType } from 'sct-framework/form/projects/form/src/lib/form-builder.model';
import { CommonService } from '@app/shared/services/common.service';
import { NavigationService } from '@app/shared/services/navigation.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	user: { email: string | null, password: string | null } = { email: null, password: null };
	formFields: SCTFormBuilderField[] = [];
	form!: SCTFormBuilder;

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private usersService: UsersService,
		private commonUtil: CommonService,
		private navigation: NavigationService,
		public sctToastService: SctToastService,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(
			params => {
				if ('expired' in params)
					this.sctToastService.showMessage('translate|g.link_expired');
			}
		);
		this.prepareFormFields();
	}

	private prepareFormFields() {
		this.formFields = [
			{
				name: 'email',
				type: InputType.TEXT,
				sctFormOptions: {
					trimText: true
				},
				useGrid: {
					fieldGridWidth: 'col-12',
					takeLabelSpaceIfFound: true
				}
			},
			{
				name: 'password',
				type: InputType.PASSWORD,
				sctFormOptions: {
					passwordOptions: {
						toggleMask: true
					}
				},
				useGrid: {
					fieldGridWidth: 'col-12',
					takeLabelSpaceIfFound: true
				}
			},
			{
				type: InputType.LINK,
				routerLink: ['/forgot-password'],
				name: 'link',
				labelLink: this.translateService.instant('login_register.forgot_password'),
				hideLabel: true,
				values: 'link',
				actionCssClass: 'main-grey underline-on-hover small-text'
			},
		];

		this.form = {
			fields: this.formFields,
			actionsCssClass: 'd-contents col-md-12 offset-md-0  ps-sm-0 ps-md-2',
			submitButtonCss: 'col-12 col-md-12',
			captchaEnabled: false,
			callback: (data: any) => this.login(data),
		};
	}

	private login(data: { email: string, password: string, link?: string }) {
		if (!data.email || !data.password) {
			return;
		}
		delete data.link;
		this.usersService.login(data).subscribe((res: any) => {
			switch (res?.api_status) {
				case 2:
					if (res.response?.shouldShowCaptcha)
						if (!this.form.captchaEnabled)
							this.form.captchaEnabled = true;
						else
							this.form.updateCaptcha && this.form.updateCaptcha();
					if (res.response?.shouldBlockLogin) {
						return this.sctToastService.showMessage(this.translateService.instant(
							'login_register.login_blocked_try_after_x_minutes',
							{ x: res.response.timeRemaining }));
					}
					if (res.response?.shouldShowCaptcha) {
						return this.sctToastService.showMessage('translate|login_register.too_many_incorrect_attempts_provide_captcha');
					}
					return this.sctToastService.showMessage('translate|login_register.invalid_username_or_password');
				case 3:
					return this.router.navigate(['/non-confirmed-user-email', res.response.id]);
				case 4:
					return this.sctToastService.showMessage('translate|login_register.please_check_your_email', 'info')
				case 5:
					return this.sctToastService.showMessage('translate|login_register.prevent_to_login_err_msg', 'error');
				case 6:
					return this.sctToastService.showMessage('translate|users.account_deactivate_error_msg', 'error');
				default:
					return this.onSuccessLogin(res?.response || res);
			}
		});
	}

	private onSuccessLogin(loginResponse: any) {
		loginResponse.access_functions = this.commonUtil.revertPermissionValue(loginResponse.access_functions);
		loginResponse.permissions.permission = this.commonUtil.revertPermissionValue(loginResponse.permissions.permission);
		this.usersService.setPermissionsOfUser(loginResponse.permissions);
		this.usersService.setCurrentUser(loginResponse);
		if (loginResponse.force_password_change) {
			return this.router.navigate(['/change-expired-password']);
		}

		return this.navigation.goBack('/');
	}
}
