import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ColumnTypes, ColumnsConfig, TableConfig, TableData } from './custom-table-interface';
import moment from 'moment';
import { CommonService } from '../services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
	selector: 'sct-custom-table',
	templateUrl: './custom-table.component.html',
	styleUrls: ['./custom-table.component.scss'],
})
export class SCTCustomTable implements OnInit, OnChanges {
	@Input() columnsConfig: ColumnsConfig[] = [];
	@Input() data: TableData[] = [];
	@Input() config: TableConfig = {
		hasPagination: false,
		pageSize: 20,
		hasExport: false,
		hasSelectionColumn: false,
		fileName: 'Exported Data'
	};
	@Output() onDatesChanged = new EventEmitter<Object>();

	currentPage: number = 1;
	sort: any = { active: null, direction: '' };
	toShowData: any[] = [];
	sortResult: any[] = [];
	filteredData: TableData[] = [];
	columnsToSort: string[] = [];
	columnsToFilter: string[] = [];
	boolFields: string[] = [];
	timeFields: string[] = [];
	hasExportField: ColumnsConfig[] = [];
	stringOrNumberFields: string[] = [];
	filters: {[key: string]: string} = {};
	hasFilterTypes: ColumnTypes[] = ['number', 'string', 'link'];

	constructor(
		private commonService: CommonService,
	) {}

	ngOnInit(): void {
		this.sortResult = this.filteredData = this.data;

		this.prepareFieldLists();
		this.updatePageData();
	}

    ngOnChanges(changes: any) {
		if (changes.data) {
			this.sortResult = this.filteredData = changes.data.currentValue;
			this.updatePageData();
		}
	}

	prepareFieldLists() {
		for (let column of this.columnsConfig) {
			if (column.hasSort != false && !['icon' , 'dropdown'].includes(column.type))
				this.columnsToSort.push(column.key);

			if (column.hasFilter)
				this.columnsToFilter.push(column.key);

			if (column.hasExport != false && !['icon' , 'dropdown'].includes(column.type))
				this.hasExportField.push(column);

			if (column.type == 'boolean')
				this.boolFields.push(column.key);

			if (['string', 'number', 'timestamp', 'link'].includes(column.type))
				this.stringOrNumberFields.push(column.key);

			if (column.type == 'date')
				this.timeFields.push(column.key);
		}
	}

	format(data: any, columnConfig: ColumnsConfig) {
		if (!data && (columnConfig.type == 'timestamp' || columnConfig.type == 'date'))
			return '--/--/--';

		if (!data && data == 0 && columnConfig.type == 'number')
			return data;

		if (!data)
			return '-';

		if (columnConfig.type == 'timestamp')
			return moment(data).utc().format(columnConfig.dateFormat || 'MM/DD/YYYY H:mm');

		return data;
	}

	onPageChange(event: { pageIndex: number; }) {
		this.currentPage = event.pageIndex + 1;
		this.updatePageData();
	}

	filter(column: ColumnsConfig, event: any) {
		const value = String(event.target.value);

		if (value)
			this.filters[column.key] = value;
		else
			delete this.filters[column.key];

		if (!Object.keys(this.filters).length) {
			this.filteredData = this.data;
			return this.sortData();
		}

		this.filteredData = this.data;
		for(const filter in this.filters)
			this.filteredData = this.filteredData.filter(row => String(row[filter].value).toLowerCase().includes(this.filters[filter].toLowerCase()));

		this.sortData();
	}

	sortData(sort: any = {active: null, direction: null}) {
		const data = this.filteredData.slice();

		// no sort params and no last sort params
		if ((!sort.active || sort.direction === '') && (!this.sort.active || this.sort.direction == '')) {
			this.sortResult = data;
			this.updatePageData();
			return;
		}

		if (sort.active && sort.direction != '')
			this.sort = sort;

		this.sortResult = data.sort((a, b) => this.sortFunction(this.sort, a, b));
		this.updatePageData();
	}

	updatePageData() {
		const startIndex = (this.currentPage - 1) * (this.config.pageSize || 20);
		const endIndex = startIndex + (this.config.pageSize || 20);
		this.toShowData = this.sortResult.slice(startIndex, endIndex);
	}

	sortFunction(sort: any, a: any, b: any) {
		const { compare } = this.commonService;
		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (this.boolFields.includes(field))
			return compare(a[field].value ? 1 : 2, b[field].value ? 1 : 2, isAsc);

		if (this.timeFields.includes(field))
			return compare(this.formateDateForCompare(a[field].value), this.formateDateForCompare(b[field].value), isAsc);

		if (this.stringOrNumberFields.includes(field)) {
			const aValue = a[field].value.toLowerCase().trim();
			const bValue = b[field].value.toLowerCase().trim();

			return compare(aValue, bValue, isAsc);
		}
		return 0;
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date ? moment(date).utc().format('YYYY/MM/DD hh:mm:ss a'): '-';
	}

	generateCsvFile() {
		const csvRows = [];
		const columnNames = this.hasExportField.map(column => column.name);
		csvRows.push(columnNames);

		(this.data || []).forEach(row => {
			const rowArray: any[] = [];
			this.hasExportField.forEach(field => {
				rowArray.push(this.format(row[field.key].value, field));
			})
			csvRows.push(rowArray);
		});

		new AngularCsv(csvRows, this.config.fileName || 'Exported Data');
	}
}
