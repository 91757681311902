import { Component } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-button-renderer',
	template: `
	<a
		*ngIf="params?.value?.type == 'link' && params?.value?.link && params?.value?.text"
		[routerLink]="params?.value?.link"
	>
		{{params?.value?.text}}
	</a>
	<fa-icon
		*ngIf="params?.value?.type == 'icon' && params?.value?.icon"
		class="pointer-cursor"
		[icon]="params?.value?.icon || 'plus'"
		(click)="onButtonClicked()"
		[styles]="{color: params?.value?.color || 'black'}"
		size="lg"
	></fa-icon>
	<button
		*ngIf="params?.value?.type == 'button' && params?.value?.text"
		class="btn btn-primary"
		(click)="onButtonClicked()"
	>
		{{params?.value?.text}}
	</button>
	<p *ngIf="(params?.value?.type == 'icon' && !params?.value?.icon) || params?.value?.type == 'button' && !params?.value?.text">_</p>
  `,
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
	params?: {
		value?: {
			onClick?:  () => any,
			text?: string,
			type: 'icon' | 'button' | 'link',
			color?: string,
			icon: IconProp,
			link: string[]
		}
	};

	agInit(params: any): void {
		this.params = params;
	}

	onButtonClicked(): void {
		if (this.params?.value?.onClick) {
			this.params.value.onClick();
		}
	}

	refresh(): boolean {
		return false;
	}
}
