
export interface SCTDialog {
	header?: string;
	visible?: boolean;
	resizable?: boolean;
	isModal?: boolean;
	position?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
	dismissableMask?: boolean;
	showCloseIcon?: boolean;
	showHeader?: boolean;
	maximizable?: boolean;
	hideFooter?: boolean;
	breakpoints?: any;
	width?: string;
	data?: any;
	overFlow?: string;
	callback?: (action: SCTDialogAction) => void;
}

export enum SCTDialogAction {
	SAVE,
	CANCEL
}
