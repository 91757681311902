<div class="input-group" [id]="dateId">
	<input class="form-control date-picker"
	[ngClass]="{'text-danger': isInvalidDate}" 
	[minDate]="minDate" 
	[maxDate]="maxDate" 
	placeholder="mm/dd/yyyy"
	[(ngModel)]="date" (ngModelChange)="datesChanged()"
	bsDatepicker="dateId" #datepicker="bsDatepicker"
	[bsConfig]="bsConfig || {containerClass: 'theme-dark-blue', isAnimated: true}"
	(onShown)="onShown()"
	[daysDisabled]="disabledDays || []"
	[datesDisabled]="disabledDates || []"
	readonly
	/>
	<div class="input-group-append pointer" (click)="datepickerTs.toggle()">
		<span class="input-group-text h-100"><i class="fa fa-calendar"></i></span>
	</div>
</div>