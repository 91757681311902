import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-with-message',
  templateUrl: './loading-with-message.component.html',
  styleUrls: ['./loading-with-message.component.css']
})
export class LoadingWithMessageComponent implements OnInit {
	@Input() isLoading: boolean = false;
	@Input() message: string = '';

  constructor() { }
  ngOnInit(): void {
  }

}
