<div class="d-flex justify-content-end">
    <fa-icon class="export_icon" icon="upload" size="lg" (click)="generateCsvFile()"></fa-icon>
</div>
<div class="table_container">
	<table class="table table-striped"
		matSort (matSortChange)="sortData($event)"
		[matSortActive]="columnsToSort.join(' ,')"
		matSortDirection="asc"
		matSortDisableClear
	>
		<thead>
			<tr>
				<th *ngFor="let column of columnsConfig">
                    <!-- Sort -->
                    <div class="header-container" *ngIf="columnsToSort.includes(column.key); else noSort" [mat-sort-header]=" column.key">{{column.name}}</div>
                    <ng-template #noSort class="header-container">{{column.name}}</ng-template>

                    <!-- Filter -->
                    <input type="text" [name]="column.key" [id]="column.key"
                        *ngIf="hasFilterTypes.includes(column.type) && columnsToFilter.includes(column.key); else noFilter"
                        (input)="filter(column, $event)" class="filter_field"
                    >
                    <ng-template #noFilter><div class="filter_shadow"></div></ng-template>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let rowData of toShowData">
				<td *ngFor="let column of columnsConfig">
					<div [ngSwitch]="column.type" class="data-cell" [style.--color]="rowData[column.key]?.backGroundColor || 'transparent'">

                        <!-- Icon -->
                        <div *ngSwitchCase="'icon'">
							<fa-icon class="icon"
								[style.--color]="rowData[column.key]?.iconColor || '#007bff'"
								[icon]="rowData[column.key].icon" size="lg"
								(click)="rowData[column.key].action?.(rowData)"
							></fa-icon>
						</div>

                        <!-- Links -->
						<a *ngSwitchCase="'link'" [routerLink]="rowData[column.key].link || []" >{{format(rowData[column.key].value, column)}}</a>

                        <!-- DropDown -->
                        <div *ngSwitchCase="'dropdown'">
                            <div class="" *ngIf="rowData[column.key]?.options.length">
                                <button #btn type="button" class="btn dropdrop-btn" id="user" data-bs-toggle="dropdown" aria-expanded="false">
                                    <fa-icon icon="cog" size="lg"></fa-icon>
                                </button>

                                <ul class="dropdown-menu" aria-labelledby="enable_disable">
                                    <a *ngFor="let option of rowData[column.key]?.options" class="btn dropdown-item" (click)="option.action?.(rowData)">
                                        {{option.text}}
                                    </a>
                                </ul>
                            </div>
                        </div>

                        <!-- Another -->
						<div *ngSwitchDefault>{{format(rowData[column.key].value, column)}}</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<mat-paginator
    *ngIf="data.length"
    [length]="data.length"
    [pageSize]="config.pageSize"
    [pageSizeOptions]="config.pageSizeOptions || [config.pageSize || 20]"
    [showFirstLastButtons]="true"
    (page)="onPageChange($event)">
</mat-paginator>
