import { MenuItem } from 'primeng/api';
import { DropdownOption } from './../dropdown/dropdown-model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum ContentTypes {
	KEY,
	ICON,
	TEXT,
	IMAGE,
	ACTION,
	LINK,
	DROPDOWN,
	NUMBER,
	TIME,
	CUSTOM,
	CUSTOM_LINK,
	BOOLEAN,
	DATE
}

interface SelectionRows {
	mode?: 'none' | 'single' | 'multiple';
	type?: 'radio' | 'checkbox' | 'rowSelection';
	callback?: any;
	allowClearSelection?: boolean;
	clear?: () => void
}
export interface SCTTable {
	id: string;
	requestsInfo?: {
		get?: {
			url: string;
			callback?: any;
		};
	};
	draggable?: { callback?: any };
	paginator?: { rows?: number; rowsPerPageOptions?: number[]; };
	search?: {
		data: {
			colNmae: string;
			key: string;
		};
		callback: void;
	};
	headers: {
		list: headerList[];
	};
	body: SCTBodyTable;
	manipulateDataBeforeInit?: any;
	data?: any;
	striped?: boolean;
	sortable?: boolean;
	responsive?: boolean;
	resizableColumns?: boolean;
	selection?: SelectionRows;
	sortAllColumn?: boolean;
	toggleShowColumn?: boolean;
	permissions?: any;
	rowHover?: boolean;
	exportCSV?: boolean;
	exportFilename?: string;
	allowResetTable?: boolean;
}


export interface headerList {
	key?: string;
	sortable?: boolean;
	text?: string;
	hideColumn?: boolean;
	width?: string;
	permission?: string;
	hasNoAccess?: boolean;
}

export interface SCTBodyTable {
	structure: SCTRowEntity;
}

export interface SCTRowEntity {
	id?: string;
	columns: SCTColumnEntity[];
	callback?: any;
	nestedRows?: any;
	cssClass?: string;
	dataKey?: string;
}

export interface SCTColumnEntity {
	type: ContentTypes;
	data?: (string | number)[];
	key: string;
	src?: string;
	fontAwesome?: IconProp;
	classes?: string;
	customClass?: Function;
	alt?: string;
	callback?: any;
	value?: any;
	routerLink?: string;
	toolTip?: string;
	actionName?: string;
	action?: boolean;
	format?: string;
	hideColumn?: boolean;
	roundFloat?: number
	dropdown?: {
		option?: DropdownOption;
		items: MenuItem[];
		callback?: any;
	};
	showTimeOnUTC?: boolean;
	dateAsNumberIn?: string;
	limitToOneDay?: boolean;
	// applied only for time
	defaultValueToView?: string | number | boolean;
}
