import { Component, Input, OnInit } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
	selector: 'app-help-tooltip',
	templateUrl: './help-tooltip.component.html',
	styleUrls: ['./help-tooltip.component.scss'],
})
export class HelpTooltipComponent implements OnInit {
	@Input() text = '';
	@Input() iconSize: SizeProp = 'lg';

	constructor() { }

	ngOnInit(): void { }
}
