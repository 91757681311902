import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { Message } from 'primeng/api';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-login-non-confirmed-email',
	templateUrl: './login-non-confirmed-email.component.html',
	styleUrls: ['./login-non-confirmed-email.component.css']
})
export class LoginNonConfirmedEmailComponent implements OnInit {

	userId: number = 0;
	msg: Message = { severity: 'info', detail: this.translateService.instant('login_register.login_non_confirmed_email_txt'), closable: false };

	constructor(
		private notificationMessage: SctToastService,
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.userId = params['userId'] || 0;
			}
		);
		let user = this.usersService.getCurrentUser();
		if (user && user.id)
			this.goBack();
	}

	goBack() {
		this.router.navigate(['/']);
	}

	submitChanges() {
		this.usersService.logout();
	}
}
