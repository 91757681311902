import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	showBanner: boolean;
	currentYear = moment().year();
	constructor(
		private cookieService: CookieService,
	) {
		const is_cookie_policy_confirmed = this.cookieService.get('cookiePolicy');
		this.showBanner = !is_cookie_policy_confirmed;
	}

	ngOnInit(): void {
	}

	closeBanner() {
		const exprireDate = new Date();
		exprireDate.setFullYear(exprireDate.getFullYear() + 10);
		this.cookieService.set('cookiePolicy', '1', exprireDate);
		this.showBanner = false;
	}
}
