import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@app/business/users/users.service';

@Component({
	selector: 'app-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
	user: any;
	constructor(
		private router: Router,
		public usersService: UsersService
	) { }

	ngOnInit() {
		if (!this.usersService.isLoggedIn())
			return this.router.navigate(['/']);

		const user = this.usersService.getCurrentUser();
		if (user) {
			if (!user || user.terms_confirmed)
				return this.router.navigate(['/']);
		}
		return;
	}

	confirmUserAgreement() {
		this.usersService.confirmUserAgreement().subscribe((res) => {
			const user = this.usersService.getCurrentUser();
			this.usersService.setCurrentUser({ ...user, ...res });
			return this.router.navigate(['/']);
		});
	}

	declineUserAgreement() {
		this.usersService.logout();
	}
}
