import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'server-pagination',
	templateUrl: './server-pagination.component.html'
})
export class ServerPaginationComponent implements OnInit {

	@Input() idField!: string;
	@Input() limit!: number;

	firstId: number = 0;
	lastId: number = 0;
	showRight: boolean = false;
	showLeft: boolean = false;
	topFirstId: number = 0;

	@Output() paginate = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	init() {
		this.showRight = false;
		this.showLeft = false;
		this.lastId = 0;
		this.firstId = 0;
		this.topFirstId = 0;
	}

	doPagination(isBack = false) {
		this.paginate.emit(isBack);
	}

	updatePagination(elements: string | any[], firstTime = false) {
		let recordsLength = elements.length;

		if (recordsLength > 0) {
			this.firstId = elements[0][this.idField];
			this.lastId = elements[recordsLength - 1][this.idField];

			if (firstTime)
				this.topFirstId = this.firstId;

			if (recordsLength >= this.limit)
				this.showRight = true;
			else
				this.showRight = false;

			if (this.firstId != this.topFirstId)
				this.showLeft = true;
			else
				this.showLeft = false;
		} else {
			if (!firstTime)
				this.showLeft = true;
		}
	}
}
