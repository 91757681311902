<div class="{{sctTabsCssClass}} d-flex align-items-center sct-tab-component bg-white"
	[ngClass]="{'round-top':roundedTop}">
	<nav class="w-100 py-0 navbar navbar-expand-lg">
		<div class="navbar-collapse sct-tab">
			<ul class="navbar-nav me-auto  bd-highlight border-0 {{sctTabContainer}}">
				<ng-container *ngFor="let tab of tabMenuItems">
					<li class="nav-item d-flex align-items-center {{cssClass}} {{tab.cssClass}}" *ngIf="tab.visible">
						<a class="nav-link p-3 h-100 light-grey small border-0 tab-link {{cssClassForLink}} {{customActiveColor}}"
							(click)="activePage=tab.id"
							[ngClass]="{'active': activePage == tab.id, 'vertical-icon': verticalIcon}"
							[ngStyle]="{width: tabWidth}" [routerLink]="tab.routerLink" [queryParams]="tab.queryParams">
							<fa-icon [icon]="tab.icon" *ngIf="tab.icon" [ngStyle]="{'font-size': iconSize}"
								class="{{tab.iconCssClass}}"></fa-icon>
							<!--<i  fa fas-glass {{}}"></i>-->
							<img *ngIf="tab.src" [ngStyle]="{width: iconSize, height: iconSize}" [src]="tab.src">
							<span class="tab-title {{cssClassForText}}"> {{tab.label}}</span>
						</a>
					</li>
				</ng-container>
			</ul>
			<ng-content select="[after-tabs]"></ng-content>
		</div>
	</nav>
</div>