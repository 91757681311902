import { SctToastService } from '@app/shared/services/sct-toast.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DeviceManagementService } from '@app/business/admin/device-management/device-management.service';
// import { deviceTypes } from '@app/business/sites/analytics-settings/settings/service/settings.service';
import { UsersService } from '@app/business/users/users.service';
import { deviceTypes } from '@app/core/model/constants';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-upload-fw-file',
	templateUrl: './upload-fw-file.component.html',
	styleUrls: ['./upload-fw-file.component.css']
})
export class UploadFwFileComponent implements OnInit {
	form!: UntypedFormGroup;
	uploadedFile: any = [];
	macAddress!: string;
	deviceType!: string;
	updatingFW: boolean = false;
	deviceTypes = deviceTypes;
	fwList: any = [];

	constructor(
		private deviceManagementService: DeviceManagementService,
		private sctToastService: SctToastService,
		private usersService: UsersService,
		private router: Router,
		private translateService: TranslateService,
	) { }

	ngOnInit(): void {
		if (!this.usersService.hasAccessFunction('upload_manual_fw'))
			this.router.navigate(['/unauthorized']);
		this.getTestingFWFilesList();
		this.form = new UntypedFormGroup({
			file: new UntypedFormControl(null, [Validators.required]),
		});
	}

	onUpload(file: any) {
		this.form.get('file')?.setValue(file.currentFiles[0]);
	}

	onSubmit() {

		const formData = new FormData();
		const values = this.form.value;
		formData.append('file', values.file);
		this.updatingFW = true;
		this.deviceManagementService.uploadTestingFw(formData).subscribe((res: any) => {
			this.updatingFW = false;
			if (res === 2)
				this.sctToastService.showMessage('translate|g.fw_already_pushed_system');
			else if (!res)
				this.sctToastService.showMessage('globalErrMsg');
			else {
				this.addNewFwFile(values);
				this.sctToastService.showMessage('globalSuccessMsg');
			}
		})
	}

	getTestingFWFilesList() {
		this.deviceManagementService.getTestingFwVersionList(deviceTypes.TYPE1, { getFWListAllTypes: true }).subscribe((res: []) => {
			this.fwList = res;
		});
	}

	deleteFWFile(version: number, type: number) {
		this.deviceManagementService.deleteFWFile(version, type).subscribe((res: any) => {
			if (res == 1) {
				this.fwList = _.map(this.fwList, obj => obj.type == type ? { ...obj, fwVersion: _.without(obj.fwVersion, version) } : obj);
				return this.sctToastService.showMessage('globalSuccessMsg');
			}
		});
	}

	addNewFwFile(values: any) {
		let type = 0;
		if (values.file.name.startsWith(this.translateService.instant('device_management.powermeter_enc')))
			type = deviceTypes.TYPE2;

		const version = values.file.name.match(/\d+\.\d+/);
		this.fwList[type].fwVersion.push(+version);
	}

}
