import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../../../shared/loader/loader.service';
import { CustomHttpParams } from '../../../shared/loader/custom-httpParam';
import { NavigationService } from '../../../shared/services/navigation.service';
import { Location } from '@angular/common';
import { SctToastService } from '../../../shared/services/sct-toast.service';

@Injectable({
	providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {

	private requests: HttpRequest<any>[] = [];
	resStatus: any = {
		success: 1,
		failed: 2,
		logged_out: 3,
		not_authorized: 4,
		app_expired: 5,
		api_timeout: 6,
		invalid_captcha: 7,
		password_expired: 8,
		terms_agreement: 9,
		not_found: 10
	};
	resStatusFlipped: any = {};
	messages: any = {
		success: 'globalSuccessMsg',
		failed: 'globalErrMsg',
		not_authorized: 'globalAuthMsg',
		api_timeout: 'globalTimeoutMsg',
		not_found: 'globalErrMsg',
	};
	readonly REDIRECT_TO_UNAUTHORIZED_PAGE_STATUS = 1;
	readonly REDIRECT_TO_NOT_FOUND_PAGE_STATUS = 2;

	constructor(
		private loaderService: LoaderService,
		private router: Router,
		private location: Location,
		private navigation: NavigationService,
		//private navigation: NavigationService,
		private sctToastService: SctToastService
	) {
		const messages: any = {};

		for (var type in this.resStatus) {
			messages[this.resStatus[type]] = this.messages[type];
		}

		this.messages = messages;

		const resStatusFlipped: any = {};

		for (var type in this.resStatus) {
			resStatusFlipped[this.resStatus[type]] = type;
		}

		this.resStatusFlipped = resStatusFlipped;
	}

	pushRequest(req: HttpRequest<any>) {

		let noUiBlock = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.noUIBlock);

		if (!noUiBlock) {
			this.requests.push(req);
			this.loaderService.isLoading.next(true);
		}
	}

	removeRequest(req: HttpRequest<any>) {

		let noUiBlock = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.noUIBlock);

		if (!noUiBlock) {
			const i = this.requests.indexOf(req);
			if (i >= 0) {
				this.requests.splice(i, 1);
			}
			this.loaderService.isLoading.next(this.requests.length > 0);
		}
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let isExternalUrl = false;
		if(!(req.url.startsWith('https://') || req.url.startsWith('http://'))) {
			if (req.url[0] === '/')
				req = req.clone({ url: '/sctportal'+req.url });
			else
				req = req.clone({ url: '/sctportal/'+req.url });
		} else
			isExternalUrl = true;

		this.pushRequest(req);

		return new Observable(observer => {
			const subscription = next.handle(req).subscribe(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {

						this.removeRequest(req);

						let isValid = false;
						if(event.body && isExternalUrl) {
							observer.next(event.clone({body: event.body}));
						} else if (event.body && event.body.response_status) {
							switch (event.body.response_status) {
								case this.resStatus.success:
									isValid = true;
									if (event.body.response && event.body.response.msg)
										this.sctToastService.showMessage(event.body.response.msg, 'success');
									break;
								case this.resStatus.failed:
								case this.resStatus.api_timeout:
									this.sctToastService.showMessage((event.body.error && typeof event.body.error == 'string') ? event.body.error : this.messages[event.body.response_status]);
									break;
								case this.resStatus.not_authorized:
									if (event.body.error && event.body.error.message == this.REDIRECT_TO_UNAUTHORIZED_PAGE_STATUS) {
										this.router.navigate(['/unauthorized']);
									} else {
										this.sctToastService.showMessage((event.body.error && typeof event.body.error == 'string') ? event.body.error : this.messages[event.body.response_status]);
									}
									break;
								case this.resStatus.not_found:
									if (event.body.error && event.body.error.message == this.REDIRECT_TO_NOT_FOUND_PAGE_STATUS) {
										this.router.navigate(['/404']);
									} else {
										this.sctToastService.showMessage((event.body.error && typeof event.body.error == 'string') ? event.body.error : this.messages[event.body.response_status]);
									}
									break;
								case this.resStatus.invalid_captcha:
									this.sctToastService.showMessage('globalInvalidCaptcha');
									break;
								case this.resStatus.logged_out:
									this.navigation.setPreviousUrl(this.location.path());
									localStorage.setItem('userInfo', '{}');
									this.router.navigate(['/login']);
									break;
								case this.resStatus.password_expired:
									this.router.navigate(['/change-expired-password']);
									break;
								case this.resStatus.terms_agreement:
									this.router.navigate(['/terms-and-conditions']);
									break;
							}

							if (isValid) {
								if (event.body.api_status != 1) {
									observer.next(event.clone({ body: { response: event.body.response, api_status: event.body.api_status } }));
								} else {
									observer.next(event.clone({ body: event.body.response }));
								}
							}

							let acceptedStatus = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.acceptedStatus);
							if (acceptedStatus && acceptedStatus.includes(this.resStatusFlipped[event.body.response_status]))
								observer.next(event.clone({ body: event.body }));
						}
					}
				},
				httpError => {

					this.removeRequest(req);

					var toReturn = httpError;

					if (httpError instanceof HttpErrorResponse) {
						this.sctToastService.showMessage('globalErrMsg');
						toReturn = httpError.status;
					}

					observer.error(toReturn);
				},
				() => {
					this.removeRequest(req);
					observer.complete();
				}
			);

			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			}
		});
	}
}