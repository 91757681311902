import { PermissionType } from '@app/core/model/constants';
import { Directive, Input, ElementRef, Renderer2, OnChanges } from '@angular/core';
import { UsersService } from '@app/business/users/users.service';

@Directive({
	selector: '[check-permission]'
})
export class CheckPermissionDirective implements OnChanges {

	@Input('check-permission') fieldName!: string;
	@Input('params') params: any;

	constructor(
		private el: ElementRef,
		private userService: UsersService,
		private renderer: Renderer2
	) {
	}

	ngOnChanges(changes: any) {
		if (changes.params)
			this.checkUserPermissions();
	}

	checkUserPermissions() {
		let accessType = this.userService.checkFieldPermissions(this.fieldName, this.params.permissions);
		if (accessType == PermissionType.NO_ACCESS)
			this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
		else if (accessType == PermissionType.READ)
			this.renderer.setProperty(this.el.nativeElement, 'disabled', 'disabled');
		else //write
			this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
	}
}