import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormService } from 'sct-framework/form/projects/form/src/lib/form.service';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-sct-date-picker',
	templateUrl: './sct-date-picker.component.html',
	styleUrls: ['./sct-date-picker.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SctDatePickerComponent {
	@Input('dateId') dateId = Math.random().toString(36).substring(2, 9);
	@ViewChild('datepicker') datepickerTs!: any;
	@Input('date') date!: Date;
	@Input('minDate') minDate!: Date;
	@Input('maxDate') maxDate!: Date;
	@Input('isInvalidDate') isInvalidDate: Boolean = false;
	@Input('placeholder') placeholder!: string;
	@Input('bsConfig') bsConfig!: BsDaterangepickerConfig | any;
	@Input('disabledDays') disabledDays!: any | any;
	@Input('bsConfig') disabledDates!: any | any;

	@Output('ngModelChange') ngModelChange: EventEmitter<any> = new EventEmitter();

	constructor(
		private commonService: CommonService
	) { }

	onShown(): void {
		const fragment = document.createDocumentFragment();
		fragment.appendChild(document.getElementsByTagName('bs-datepicker-container')[0]!);
		document.getElementById(this.dateId)!.appendChild(fragment);
	}

	datesChanged() {
		this.date = this.commonService.getDateInLocalTime(this.date);
		this.ngModelChange.emit(this.date);
	}
}
