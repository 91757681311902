<div class="row w-100 h-100 justify-content-center align-items-center overflow-auto">
	<div class="col-12 col-md-10 col-lg-8 bg-white my-2">
		<div class="border-bottom p-3">
			<h2>{{'login_register.forgot_password' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<sct-form *ngIf="form" [invalidFields]="invalidFields" [data]="user" [name]="'forgotPassword'"
				[formBuilder]="form" [submitButtonText]="'g.submit'" [showCancelButton]="true" (formCancelled)="goBack()" [cancelButtonText]="'g.back'" (invlidateFieldsCallback)="sctToastService.showMessage('translate|g.invalid_input')">
			</sct-form>
		</div>
	</div>
</div>