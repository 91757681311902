import { OnInit } from '@angular/core';
import { SctToastService } from '@app/shared/services/sct-toast.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SCTDateRange, SCTDateRangeConfig } from './date-range-modal';
import moment from 'moment';

@Component({
	selector: 'sct-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.css'],
})
export class DateRangeComponent implements OnInit {
	@Input() config: SCTDateRangeConfig = {};
	@Input() minDate = new Date('1/1/1970');
	@Input() maxDate!: Date;
	@Input() minYear = 2020;
	@Input() maxYear = 2030;
	@Input() aloneSection = false

	@Output() onDatesChanged = new EventEmitter<Object>();
	@Input() dateRange!: SCTDateRange;
	invalidDateRange: boolean = false;
	@Input() type: 'DEFAULT' | 'UTC' = 'UTC';

	constructor(
		private sctToastService: SctToastService
	) { }


	ngOnInit(): void {
		if (!this.dateRange)
			this.manageEmptyDateRange();
	}

	datesChanged(date: SCTDateRange) {
		this.dateRange = date;

		this.invalidDateRange = false;
		const dateRange = this.getDate();
		if (dateRange[0] > dateRange[1]) {
			this.sctToastService.showMessage('translate|g.start_lg_end', 'error', 10);
			this.invalidDateRange = true;
		}
		this.onDatesChanged.emit({ invalidRange: this.invalidDateRange, dateRange });
	}

	manageEmptyDateRange() {
		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);
		const endDate = new Date();
		endDate.setHours(23, 59, 59, 999);
		this.dateRange = {
			start: startDate,
			end: endDate
		};
	}

	public getDate() {
		let fromDate, toDate;
		if (this.type === 'DEFAULT') {
			fromDate = moment(this.dateRange.start).startOf('day').unix();
			toDate = moment(this.dateRange.end).endOf('day').unix();
		} else {
			const zoneDiff = new Date().getTimezoneOffset() * -1;
			fromDate = Math.round(new Date(new Date(this.dateRange.start).getTime() + (zoneDiff * 60 * 1000)).getTime() / 1000);
			toDate = Math.round(new Date(new Date(this.dateRange.end).getTime() + (zoneDiff * 60 * 1000)).getTime() / 1000);
		}
		return [fromDate, toDate];
	}
}
