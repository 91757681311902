import { Observable } from 'rxjs';
import {
	HttpClient,
	HttpErrorResponse,
	HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GatewayService {
	// baseUrl = 'http://localhost:5000/';
	baseUrl = '';
	headerOtions = {
		observe: 'body',
	};

	constructor(private httpClient: HttpClient) { }

	public get(
		requestUrl: string,
		params?: any,
		requestOptions?: any
	): Observable<any> {
		return this.httpClient
			.get(requestUrl, {
				params,
				...this.headerOtions,
				...requestOptions,
			})
			.pipe(catchError((err) => this.handleError(err)));
	}

	public post(
		requestUrl: string,
		data: any,
		params?: HttpParams | null,
		requestOptions?: any
	): Observable<any> {
		return this.httpClient
			.post(requestUrl, data, {
				...this.headerOtions,
				...requestOptions,
				params,
			})
			.pipe(catchError((err) => this.handleError(err)));
	}

	public put(
		requestUrl: string,
		data: any,
		params?: HttpParams,
		requestOptions?: any
	): Observable<any> {
		return this.httpClient
			.put(requestUrl, data, {
				params,
				...this.headerOtions,
				...requestOptions,
			})
			.pipe(catchError((err) => this.handleError(err)));
	}

	public patch(
		requestUrl: string,
		data: any,
		params?: HttpParams,
		requestOptions?: any
	): Observable<any> {
		return this.httpClient
			.patch(requestUrl, data, {
				params,
				...this.headerOtions,
				...requestOptions,
			})
			.pipe(catchError((err) => this.handleError(err)));
	}

	public delete(
		requestUrl: string,
		params?: any,
		requestOptions?: any
	): Observable<any> {
		return this.httpClient
			.delete(requestUrl, {
				...this.headerOtions,
				...requestOptions,
				params,
			})
			.pipe(catchError((err) => this.handleError(err)));
	}

	private handleError(error: HttpErrorResponse) {
		return throwError(error);
	}
}
