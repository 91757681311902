import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AttachmentsService } from './attachments.service';
import { cloneDeep } from 'lodash';
import { SctToastService } from './../../shared/services/sct-toast.service';
import { SCTDialog } from '../sct-dialog/sct-dialog.model';

@Component({
	selector: 'app-attachments',
	templateUrl: './attachments.component.html',
})
export class AttachmentsComponent implements OnInit {

	@Input() source: string = '';
	@Input() sourceId: any;
	@Input() title: string = '';

	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: ElementRef = { nativeElement: { value: '' } };

	addAttachmentsDialog: SCTDialog = {
		header: 'attachments.attachments',
		width: '700px',
	};

	deleteAttachmentsDialog: SCTDialog = {
		header: 'attachments.delete_attachments',
		width: '700px',
	};

	editNoteDialog: SCTDialog = {
		header: 'attachments.edit_attachments',
		width: '700px',
	};

	successAttachmentsDialog: SCTDialog = {
		header: 'attachments.success_attachments',
		width: '700px',
	};

	attachments: any = {};
	deletedAttachmentId: any = null;
	editedNoteAttachment: any = {};

	note: string = '';
	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};

	constructor(
		public attachmentsService: AttachmentsService,
		private notificationMessage: SctToastService,
	) { }

	ngOnInit(): void {
		this.getAttachments();

		this.attachmentsService.successCounter.subscribe((counter: number) => {
			if (this.selectedFiles.length && counter == this.selectedFiles.length) {
				this.note = '';
				this.selectedFiles = [];
				this.successAttachments = {};
				this.successAttachmentsDialog.visible = false;
				return this.notificationMessage.showMessage('globalSuccessMsg');
			}
		});
	}

	showActionModal(action: string, attachmentId?: any) {
		switch (action) {
			case 'add':
				this.note = '';
				this.uploadAttachmentsBtn.nativeElement.value = "";
				this.selectedFiles = [];
				this.selectedFilesValidations = {};
				this.addAttachmentsDialog.visible = true;
				break;
			case 'edit':
				this.editedNoteAttachment = cloneDeep(this.attachments[attachmentId]);
				this.editNoteDialog.visible = true;
				break;
			case 'delete':
				this.deletedAttachmentId = attachmentId;
				this.deleteAttachmentsDialog.visible = true;
				break;
			default:
				break;
		}
	}

	selectFiles(events: any) {
		this.selectedFiles = Object.values(events.target.files);
	}

	addAttachments() {
		if (!this.selectedFiles.length)
			return this.selectedFilesValidations = { ...this.selectedFilesValidations, min_count: true };

		this.selectedFilesValidations = this.attachmentsService.validate({ files: this.selectedFiles, note: this.note });
		if (Object.keys(this.selectedFilesValidations).length)
			return

		this.addAttachmentsDialog.visible = false;
		this.successAttachmentsDialog.visible = true;

		let successCounter = 0;
		for (const file of this.selectedFiles) {
			const formData = new FormData();

			formData.append("file", file);
			formData.append('note', this.note);
			formData.append('source', this.source);
			formData.append('sourceId', this.sourceId);

			this.attachmentsService.addAttachments(formData).subscribe((attachment: any) => {
				this.attachments = cloneDeep({ ...this.attachments, [attachment.id]: attachment });
				this.successAttachments = cloneDeep({ ...this.successAttachments, [attachment.file_name]: true });
				this.attachmentsService.successCounterSubject.next(++successCounter);
			});
		}
	}

	getAttachments() {
		if (!this.sourceId || !this.source)
			return;

		this.attachmentsService.getAttachments(this.source, this.sourceId).subscribe((attachments: any) => {
			this.attachments = attachments;
		});
	}

	editAttachmentNote() {
		const { id, note } = this.editedNoteAttachment;
		this.attachmentsService.editAttachmentNote(this.source, this.sourceId, { id, note }).subscribe((data: any) => {
			this.attachments[id].note = note;
			this.editedNoteAttachment = {};
			this.editNoteDialog.visible = false;
			return this.notificationMessage.showMessage('globalSuccessMsg');
		});
	}

	deleteAttachment() {
		if (!this.deletedAttachmentId)
			return;

		this.attachmentsService.deleteAttachment(this.source, this.sourceId, this.deletedAttachmentId).subscribe((data: any) => {
			if (data == 'failed')
				return this.notificationMessage.showMessage('globalErrMsg');

			delete this.attachments[this.deletedAttachmentId];
			this.deletedAttachmentId = null;
			this.deleteAttachmentsDialog.visible = false;
			this.attachments = cloneDeep(this.attachments);
			return this.notificationMessage.showMessage('globalSuccessMsg');
		});
	}

	downloadAttachment(attachmentId: number) {
		if (!attachmentId)
			return;

		return this.attachmentsService.downloadAttachment(this.source, this.sourceId, this.attachments[attachmentId].file_name);
	}

	showAttachments() {
		return Object.keys(this.attachments).length;
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}
}
